<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import { Volume2 } from 'lucide-vue-next';
import { PropType, ref, watch } from 'vue';

const props = defineProps({
  audioUrl: { type: [String, null], required: true },
  isGenerating: { type: Boolean, required: false, default: false },
  size: { type: String as PropType<'small' | 'medium'>, required: false, default: 'medium' },
});

const audio = ref<HTMLAudioElement | null>(null);
const isPlaying = ref(false);

const emit = defineEmits(['missingAudio']);

const onAudioEnded = () => {
  isPlaying.value = false;
  audio.value?.removeEventListener('ended', onAudioEnded);
  audio.value = null;
};

const playAudio = async () => {
  if (isPlaying.value) {
    return;
  }

  if (props.isGenerating) {
    return;
  }

  if (!props.audioUrl) {
    emit('missingAudio');
    return;
  }

  // check if audio file exists
  try {
    const audioFileExists = await (await getApiClient()).vocabs.checkAudioExists(props.audioUrl);
    console.log('audioFileExists', audioFileExists);
    if (!audioFileExists) {
      emit('missingAudio');
      return;
    }
  } catch (error) {
    emit('missingAudio');
    return;
  }

  audio.value = new Audio(props.audioUrl);
  audio.value.play();
  isPlaying.value = true;

  audio.value.addEventListener('ended', onAudioEnded);
};

watch(
  () => props.audioUrl,
  () => {
    isPlaying.value = false;
    audio.value?.removeEventListener('ended', onAudioEnded);
    audio.value?.pause();
    audio.value = null;
  },
);

watch(
  () => props.isGenerating,
  // play audio once generated
  (newValue, oldValue) => {
    console.log('newValue', newValue);
    console.log('oldValue', oldValue);
    if (!newValue && oldValue) {
      playAudio();
    }
  },
);
</script>

<template>
  <div
    class="bg-black bg-opacity-[0.02] rounded-full flex items-center justify-center cursor-pointer"
    :class="{
      'disabled-animate-pulse-strong': isGenerating,
      'w-9 h-9': size === 'medium',
      'w-6 h-6': size === 'small',
    }"
    @click.stop="playAudio"
  >
    <Volume2 :size="20" :fill="isPlaying ? 'currentColor' : 'none'" />
  </div>
</template>

<style scoped>
.disabled-animate-pulse-strong {
  animation: disabled-pulse-strong 3s infinite;
}

@keyframes disabled-pulse-strong {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
