<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue';
import { useAlertStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import TextareaWithAudioInputNew from '@/components/inputs/TextareaWithAudioInputNew.vue';
import { Loader } from 'lucide-vue-next';
import ResponseContainer from './VocabModal/ResponseContainer.vue';
import Vocab from './VocabModal/Vocab.vue';
import Modal from '@/components/various/Modal.vue';
import SuccessAnimation from '@/components/SuccessAnimation.vue';
import {
  VocabEvaluation,
  VocabEvaluationExplainTermCreate,
  VocabEvaluationMakeSentenceCreate,
  VocabEvaluationUseSetPhraseCreate,
  VocabEvaluationMakeSpecificSentenceCreate,
  VocabItem,
} from '@/apiclient';
import cloneDeep from 'lodash/cloneDeep';
import { computed } from 'vue';
import { shuffle } from 'lodash';
import { MeaningAndMakingTestMode } from '@/helper/vocab';

const { t } = useI18n();

const props = defineProps({
  vocabItems: { type: Array<VocabItem>, required: true },
  overlayId: { type: String, required: true },
  mode: {
    type: Object as PropType<Partial<Record<MeaningAndMakingTestMode, string>>>,
    default: () => ({
      [MeaningAndMakingTestMode.EXPLAIN]: '',
      [MeaningAndMakingTestMode.MAKE_SENTENCE]: '',
      [MeaningAndMakingTestMode.FIND_TERM]: '',
    }),
  },
  nItems: { type: Number, required: true },
});

const makeSentencesPlaceholderVariants = [
  'Bilde einen Beispielsatz mit "{term}!"',
  'Verwende "{term}" in einem Beispielsatz',
  'Wie würdest du "{term}" in einem Satz verwenden?',
  'Kannst du einen Satz mit {term} bilden?',
  'Kannst du "{term}" in einem Beispielsatz verwenden?',
  'Fällt dir ein Beispielsatz mit "{term}" ein?',
  'Überlege dir einen Beispielsatz mit "{term}"',
];

const emit = defineEmits(['closed']);

// Stores and store references
const alertStore = useAlertStore();

// Refs for component data
const testSessionId = ref<string | null>(null);
const vocabs = ref<Array<VocabItem>>([]);
const vocabIndex = ref(0);

const show = ref(false);
const isSubmitting = ref(false);
const isSubmitted = ref(false);
const isCorrect = ref<boolean | null>(null);
const inputValue = ref('');
const feedback = ref('');
const placeholderText = ref('');
const currentMode = ref<MeaningAndMakingTestMode | null>(null);
const playSuccessAnimation = ref(false);
const findTermOptions = ref<string[]>([]);

const setPlaceholderText = () => {
  if (currentMode.value === MeaningAndMakingTestMode.EXPLAIN) {
    placeholderText.value = 'Erkläre den Begriff';
  } else if (currentMode.value === MeaningAndMakingTestMode.MAKE_SENTENCE) {
    placeholderText.value = makeSentencesPlaceholderVariants[
      Math.floor(Math.random() * makeSentencesPlaceholderVariants.length)
    ].replace('{term}', vocabs.value[vocabIndex.value].term);
  }
};

const setFindTermOptions = () => {
  if (currentMode.value !== MeaningAndMakingTestMode.FIND_TERM) {
    findTermOptions.value = [];
    return;
  }

  findTermOptions.value = [vocabs.value[vocabIndex.value].term];

  // add distractors
  const max3Distractors = shuffle(vocabs.value[vocabIndex.value].distractors).slice(0, 3);
  if (max3Distractors) {
    findTermOptions.value.push(...max3Distractors);
  }

  // add other terms as distractors if needed
  const needsNMoreTerms = 4 - findTermOptions.value.length;
  if (needsNMoreTerms > 0) {
    const otherVocabs = props.vocabItems.filter((vocab) => vocab.id !== vocabs.value[vocabIndex.value].id);
    const otherTerms = shuffle(otherVocabs.map((vocab) => vocab.term)).slice(0, needsNMoreTerms);
    findTermOptions.value.push(...otherTerms);
  }

  findTermOptions.value = shuffle(findTermOptions.value);
};

const getNewVocabs = async (nItems: number) => {
  console.log('getNewVocabs');

  const randomizedVocabs = cloneDeep(props.vocabItems)
    .sort(() => Math.random() - 0.5)
    .slice(0, nItems);

  console.log('randomizedVocabs', randomizedVocabs);
  vocabs.value = randomizedVocabs;
};

// Lifecycle hooks
onMounted(async () => {
  testSessionId.value = uuidv4();
  show.value = true;
  await getNewVocabs(props.nItems);
  currentMode.value = randomMode();
  setPlaceholderText();
  setFindTermOptions();
});

const randomMode = () => {
  const modes = props.mode;
  console.log('modes', modes);
  console.log('props.mode', props.mode);

  let weights = {
    [MeaningAndMakingTestMode.EXPLAIN]: MeaningAndMakingTestMode.EXPLAIN in props.mode ? 1 : 0,
    [MeaningAndMakingTestMode.MAKE_SENTENCE]: MeaningAndMakingTestMode.MAKE_SENTENCE in props.mode ? 2 : 0,
    [MeaningAndMakingTestMode.MAKE_SPECIFIC_SENTENCE]:
      MeaningAndMakingTestMode.MAKE_SPECIFIC_SENTENCE in props.mode ? 4 : 0,
    [MeaningAndMakingTestMode.USE_SET_PHRASE]: MeaningAndMakingTestMode.USE_SET_PHRASE in props.mode ? 4 : 0,
    [MeaningAndMakingTestMode.FIND_TERM]: MeaningAndMakingTestMode.FIND_TERM in props.mode ? 1 : 0,
  };

  const totalWeight = Object.values(weights).reduce((a, b) => a + b, 0);
  const rand = Math.random() * totalWeight;
  let cumulativeWeight = 0;
  for (const mode in weights) {
    cumulativeWeight += weights[mode];
    if (rand < cumulativeWeight) {
      return mode;
    }
  }
};

const directSubmit = async () => {
  await onSubmit();
};

const onSubmit = async () => {
  isSubmitting.value = true;

  const apiClient = await getApiClient();

  let response: VocabEvaluation | null = null;

  if (currentMode.value === MeaningAndMakingTestMode.EXPLAIN) {
    const postData: VocabEvaluationExplainTermCreate = {
      vocab_item_id: vocabs.value[vocabIndex.value].id,
      test_session_id: testSessionId.value,
      term: vocabs.value[vocabIndex.value].term,
      reference_explanation: vocabs.value[vocabIndex.value].explanation ?? '',
      user_explanation: inputValue.value,
    };

    response = await apiClient.evaluation.evaluateVocabExplainTerm(postData);
  } else if (currentMode.value === MeaningAndMakingTestMode.MAKE_SENTENCE) {
    const postData: VocabEvaluationMakeSentenceCreate = {
      vocab_item_id: vocabs.value[vocabIndex.value].id,
      test_session_id: testSessionId.value,
      term: vocabs.value[vocabIndex.value].term,
      user_sentence: inputValue.value,
    };

    response = await apiClient.evaluation.evaluateVocabMakeSentenceFromTerm(postData);
  } else if (currentMode.value === MeaningAndMakingTestMode.MAKE_SPECIFIC_SENTENCE) {
    const postData: VocabEvaluationMakeSpecificSentenceCreate = {
      vocab_item_id: vocabs.value[vocabIndex.value].id,
      test_session_id: testSessionId.value,
      term: vocabs.value[vocabIndex.value].term,
      user_sentence: inputValue.value,
      task: props.mode[MeaningAndMakingTestMode.MAKE_SPECIFIC_SENTENCE] ?? '',
      remark: vocabs.value[vocabIndex.value].remarks ?? '',
    };

    response = await apiClient.evaluation.evaluateMakeSpecificSentence(postData);
  } else if (currentMode.value === MeaningAndMakingTestMode.USE_SET_PHRASE) {
    const postData: VocabEvaluationUseSetPhraseCreate = {
      vocab_item_id: vocabs.value[vocabIndex.value].id,
      test_session_id: testSessionId.value,
      term: vocabs.value[vocabIndex.value].term,
      user_sentence: inputValue.value,
      remark: vocabs.value[vocabIndex.value].remarks ?? '',
    };

    response = await apiClient.evaluation.evaluateUseSetPhrase(postData);
  }

  console.log('response', response);

  isSubmitting.value = false;
  isSubmitted.value = true;

  if (response) {
    isCorrect.value = response.is_correct;
    feedback.value = response.feedback.replace('macht Sinn', 'ergibt Sinn');

    if (response.notification) {
      alertStore.xp(t(response.notification.message), t('message.receivedXP', response.notification.xp));
    }
  } else if (currentMode.value === MeaningAndMakingTestMode.FIND_TERM) {
    isCorrect.value = inputValue.value === vocabs.value[vocabIndex.value].term;
    feedback.value = isCorrect.value ? `${inputValue.value} ist richtig.` : `${inputValue.value} ist nicht richtig.`;
  }
};

const moveCurrentToEnd = () => {
  const currentVocab = vocabs.value[vocabIndex.value];
  vocabs.value.splice(vocabIndex.value, 1);
  vocabs.value.push(currentVocab);
};

const onNext = () => {
  const lastWasIncorrect = isCorrect.value === false;

  resetVocabState();

  if (lastWasIncorrect) {
    moveCurrentToEnd();
  } else {
    if (isFinished.value) {
      onClose();
    } else {
      vocabIndex.value++;
    }
  }

  setPlaceholderText();
  setFindTermOptions();
};

const isFinished = computed(() => {
  return isCorrect.value === true && vocabIndex.value === vocabs.value.length - 1;
});

const onClose = async () => {
  show.value = false;
  if (isFinished.value) {
    playSuccessAnimation.value = true;
    await new Promise((resolve) => setTimeout(resolve, 150));
  }
  emit('closed', isFinished.value);
};

const resetVocabState = () => {
  currentMode.value = randomMode();
  isSubmitting.value = false;
  isSubmitted.value = false;
  isCorrect.value = null;
  feedback.value = '';
  inputValue.value = '';
  placeholderText.value = '';
};

const heading = computed(() => {
  if (currentMode.value === MeaningAndMakingTestMode.EXPLAIN) {
    return t('message.whatDoesTheTermMean');
  } else if (currentMode.value === MeaningAndMakingTestMode.MAKE_SENTENCE) {
    return t('message.makeASentenceWithTheTerm');
  } else if (currentMode.value === MeaningAndMakingTestMode.MAKE_SPECIFIC_SENTENCE) {
    return props.mode[MeaningAndMakingTestMode.MAKE_SPECIFIC_SENTENCE];
  } else if (currentMode.value === MeaningAndMakingTestMode.USE_SET_PHRASE) {
    return props.mode[MeaningAndMakingTestMode.USE_SET_PHRASE];
  } else if (currentMode.value === MeaningAndMakingTestMode.FIND_TERM) {
    return t('message.findTheTerm');
  }
});

const ttsContext = computed(() => {
  return vocabs.value[vocabIndex.value].term;
});

const modalTitle = computed(() => {
  if (vocabs.value.length === 0) return t('message.vocab');
  return `${t('message.vocab')} ${vocabIndex.value + 1} / ${vocabs.value.length}`;
});
</script>

<template>
  <div>
    <Modal :show="show" :id="overlayId" :title="modalTitle" @close="onClose">
      <!-- progress bar -->
      <div :data-testid="`${overlayId}-progress-bar`" class="w-full h-[4px] bg-gray-light">
        <div
          class="progress-bar-inner h-full bg-black"
          :class="{
            'rounded-r-[2px]': vocabIndex < vocabs.length,
          }"
          :style="{ width: `${((vocabIndex + (isCorrect ? 1 : 0)) / vocabs.length) * 100}%` }"
        />
      </div>
      <!-- end of progress bar -->

      <!-- modal contents -->
      <div v-if="vocabs.length" class="flex flex-col px-6 py-12 gap-y-6">
        <Vocab :vocab="vocabs[vocabIndex]" :heading="heading" :mode="currentMode" />

        <div v-if="currentMode === MeaningAndMakingTestMode.FIND_TERM && !isSubmitting && !isSubmitted">
          <div class="flex flex-col mt-16 gap-y-2">
            <div
              v-for="option in findTermOptions"
              :key="option"
              class="rounded-2xl py-4 px-6 font-medium text-xl cursor-pointer border"
              :class="{
                'bg-orange-light border-orange border-opacity-20 text-orange': inputValue === option,
                'bg-gray-light border-gray-light': inputValue !== option,
              }"
              @click="
                async () => {
                  inputValue = option;
                  await directSubmit();
                }
              "
            >
              {{ option }}
            </div>
          </div>
        </div>

        <!-- loader -->
        <div
          v-if="isSubmitting"
          class="flex h-28 mt-16 rounded-2xl justify-center items-center border-orange bg-orange-light"
        >
          <Loader :size="56" class="animate-spin text-orange" />
        </div>
        <!-- end of loader -->

        <ResponseContainer
          v-if="isCorrect !== null"
          :type="isCorrect ? 'correct' : 'wrong'"
          :message="feedback"
          class="mt-16"
        />
      </div>
      <!-- end of modal contents -->

      <!-- footer input and submit -->
      <TextareaWithAudioInputNew
        v-model="inputValue"
        :placeholder="placeholderText"
        :disabled="false"
        :is-submitted="isSubmitted"
        :is-finished="isFinished"
        :tts-context="ttsContext"
        :allow-text-input="currentMode !== MeaningAndMakingTestMode.FIND_TERM"
        :allow-audio-input="currentMode !== MeaningAndMakingTestMode.FIND_TERM"
        :hide-submit-button="currentMode === MeaningAndMakingTestMode.FIND_TERM"
        @submit="onSubmit"
        @next="onNext"
        @close="onClose"
      />
      <!-- end of footer input and submit -->
    </Modal>
    <SuccessAnimation :show="playSuccessAnimation" @complete="playSuccessAnimation = false" />
  </div>
</template>

<style scoped>
.progress-bar-inner {
  transition: width 0.3s;
}
</style>
