<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import AudioRecorderSTT from './AudioRecorderSTT.vue';
import { v4 as uuidv4 } from 'uuid';
import { useMobileInteractionsStore } from '@/stores/mobileInteractions.store';
import { storeToRefs } from 'pinia';
import { updateMinTextareaHeight } from '@/helper';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 2,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  allowAudioInput: {
    type: Boolean,
    default: true,
  },
  forceDesktop: {
    type: Boolean,
    default: false,
  },
  asFooter: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  input: [event: Event];
  scrollBy: [distance: number];
  undoLastScroll: [];
  transcriptionInProgress: [inProgress: boolean];
}>();

const textarea = ref<HTMLTextAreaElement | null>(null);
const hasBeenFocusedBefore = ref(false);
const isMobile = computed(() => window.innerWidth <= 768);
const mobileAudioInputFooterMaxHeight = ref(150);
const uuid = ref('');
const transcriptionInProgress = ref(false);
const outerContainer = ref<HTMLDivElement | null>(null);

const mobileInteractionsStore = useMobileInteractionsStore();
const { activeTextareaId, showMobileAudioInput: storeAudioInput } = storeToRefs(mobileInteractionsStore);

const isActive = computed(
  () => activeTextareaId.value === uuid.value && (storeAudioInput.value || hasBeenFocusedBefore.value),
);
const showMobileAudioInput = computed(() => storeAudioInput.value && isActive.value);

// Handle textarea input
const onInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  updateMinTextareaHeight(target, props.rows);
  emit('update:modelValue', target.value);
  emit('input', event);
};

// Toggle between keyboard and audio input on mobile
const toggleInputMethod = () => {
  if (storeAudioInput.value) {
    hasBeenFocusedBefore.value = true;
    mobileInteractionsStore.hideAudioInput();
  } else {
    mobileInteractionsStore.showAudioInput(uuid.value);
  }
  if (!storeAudioInput.value) {
    textarea.value?.focus();
  }
};

const onTextareaFocus = (event: FocusEvent) => {
  if (!event.target) return;
  if (!hasBeenFocusedBefore.value && props.allowAudioInput) {
    event.target.blur();
    mobileInteractionsStore.showAudioInput(uuid.value);
    return;
  }
};

const onTextareaBlur = async (event: FocusEvent) => {
  if (event.relatedTarget?.closest('.mobile-textarea-container')) {
    return;
  }
  mobileInteractionsStore.hideAudioInput();
  hasBeenFocusedBefore.value = false;
  await nextTick();
};

const onTranscriptionInProgress = (inProgress: boolean) => {
  transcriptionInProgress.value = inProgress;
  emit('transcriptionInProgress', inProgress);
  if (!inProgress) return;
  mobileInteractionsStore.hideAudioInput();
};

// Handle speech-to-text result
const onTranscriptionResult = (text: string) => {
  console.log('onTranscriptionResult', text);
  emit('update:modelValue', text);
  if (!textarea.value) return;

  textarea.value.value = text;
  const event = new Event('input', {
    bubbles: true,
    cancelable: true,
  });
  Object.defineProperty(event, 'target', { value: textarea.value });
  emit('input', event);
};

// Handle footer height and scrolling
watch(showMobileAudioInput, (newVal) => {
  if (newVal) {
    emit('scrollBy', mobileAudioInputFooterMaxHeight.value);
  } else {
    emit('undoLastScroll');
  }
});

const isTransitioning = ref(false);
watch(storeAudioInput, () => {
  isTransitioning.value = true;
  setTimeout(() => {
    isTransitioning.value = false;
  }, 300);
});

const handleClickOutside = (event: MouseEvent) => {
  if (outerContainer.value && !outerContainer.value.contains(event.target as Node)) {
    onTextareaBlur(event);
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  uuid.value = uuidv4();
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

watch(
  () => textarea.value,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        updateMinTextareaHeight(newVal, props.rows);
      }, 100);
    }
  },
);
</script>

<template>
  <div :key="uuid" class="relative mobile-textarea-container" ref="outerContainer">
    <!-- Desktop version -->
    <div v-if="!isMobile || forceDesktop" class="relative z-10">
      <textarea
        :id="uuid"
        ref="textarea"
        :value="modelValue"
        :placeholder="placeholder"
        :rows="props.rows"
        :disabled="disabled || transcriptionInProgress"
        class="w-full pl-2 pr-12 py-2 resize-none rounded-lg text-base md:text-sm text-start"
        :class="{
          'border border-gray-200': !asFooter,
          'border-none focus:border-none focus:ring-0': asFooter,
          'bg-gray-100': transcriptionInProgress,
        }"
        @input="onInput"
      />
      <div
        v-show="transcriptionInProgress"
        class="absolute bottom-3 left-0 right-0 flex justify-center overflow-hidden items-center transition-all duration-300 ease-in-out"
      >
        <span
          class="animate-spin inline-block w-10 h-10 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </div>
      <div class="absolute right-0.5 bottom-1.5 flex-col flex items-center justify-center">
        <div class="relative">
          <div v-show="allowAudioInput" class="absolute right-0 top-0 h-8 w-8">
            <AudioRecorderSTT
              size="small"
              @newTranscription="onTranscriptionResult"
              :disabled="disabled || transcriptionInProgress"
              @transcriptionInProgress="onTranscriptionInProgress"
            />
          </div>
          <div v-show="allowAudioInput" class="w-full mx-auto">
            <div class="h-8 w-8 flex">
              <!-- this blocks the space for the mic icon + halo -->
            </div>
          </div>
          <div class="w-full mx-auto">
            <slot name="actions"> </slot>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile version -->
    <div v-else class="relative">
      <textarea
        :id="uuid"
        ref="textarea"
        :value="modelValue"
        :placeholder="storeAudioInput ? 'Mikrofon gedrückt halten' : placeholder"
        :rows="props.rows"
        :disabled="disabled || transcriptionInProgress"
        class="w-full px-2 py-2 resize-none rounded-lg text-base md:text-sm text-start border border-gray-200 transition-all duration-300"
        :class="{
          'ring-2 ring-blue-500': showMobileAudioInput,
          'focus:ring-2 focus:ring-blue-500': !showMobileAudioInput,
          'bg-gray-100': transcriptionInProgress,
        }"
        @input="onInput"
        @focus="onTextareaFocus"
        @blur="onTextareaBlur"
      />
      <div class="absolute right-0.5 bottom-1.5 flex-col flex items-center justify-center">
        <div class="w-full mx-auto">
          <slot name="actions"> </slot>
        </div>
      </div>
      <div
        v-show="transcriptionInProgress"
        class="absolute bottom-0 left-0 right-0 flex justify-center overflow-hidden items-center transition-all duration-300 ease-in-out"
      >
        <span
          class="animate-spin inline-block w-10 h-10 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </div>

      <!-- Mobile footer with audio input -->
      <div
        class="z-[80] fixed bottom-0 left-0 right-0 bg-gray-500/30 flex justify-center overflow-hidden items-center transition-all duration-300 ease-in-out"
        :style="{ height: showMobileAudioInput ? `${mobileAudioInputFooterMaxHeight}px` : '0' }"
      >
        <div>
          <AudioRecorderSTT
            @newTranscription="onTranscriptionResult"
            :disabled="disabled || transcriptionInProgress"
            @transcriptionInProgress="onTranscriptionInProgress"
          />
        </div>
      </div>

      <!-- Toggle button -->
      <button
        v-show="isActive && !isTransitioning"
        class="fixed z-[90] right-12 bg-blue-600 text-white rounded-full p-1 shadow-lg transition-all duration-300 flex items-center justify-center"
        :class="{
          'bottom-8': storeAudioInput,
          'bottom-3': !storeAudioInput,
        }"
        @click="toggleInputMethod"
      >
        <span translate="no" class="material-symbols-outlined no-translate select-none">
          {{ storeAudioInput ? 'keyboard' : 'mic' }}
        </span>
      </button>
    </div>
  </div>
</template>
