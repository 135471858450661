<script setup lang="ts">
import CreateListeningDialog from './didactics/listening_dialog/create/CreateListeningDialog.vue';
import { ref } from 'vue';
import { onMounted } from 'vue';
import DialogHistory from './didactics/listening_dialog/history/DialogHistory.vue';
import { getApiClient } from '@/apiclient/client';
import { Section } from '@/apiclient';
import SectionContentReorderModal from './courses/SectionContentReorderModal.vue';

const showModal = ref(false);
const dialog = ref([]);
const createListeningDialogModal = ref(null);
const section = ref<Section | null>(null);

onMounted(async () => {
  section.value = await (await getApiClient()).sections.getSection('a03ab91d-e0a1-4699-b873-8c8a916e9955');
});
</script>

<template>
  <button @click="showModal = true">Show Modal</button>
  <SectionContentReorderModal v-if="showModal" :section="section" @closed="showModal = false" />
</template>
