<script setup>
import { computed } from 'vue';
import { router } from '@/router';
import { useAlertStore } from '@/stores';
import { useCourseInteractionStore } from '@/stores';

const props = defineProps({
  courseInteraction: {
    type: Object, // TODO fix typing
    default: null,
  },
  // currentStep: {
  //   type: Number,
  //   default: null,
  // },
  // totalSteps: {
  //   type: Number,
  //   default: null,
  // },
  // timeLeft: {
  //   type: Number, // in minutes
  //   default: null,
  // },
  // xpReward: {
  //   type: Number,
  //   default: null,
  // },
});

const currentStep = computed(() => {
  return props.courseInteraction.progress.n_items_completed_in_current_section;
});

const totalSteps = computed(() => {
  return props.courseInteraction.progress.n_total_items_in_current_section;
});

const hasProgress = computed(() => {
  return currentStep.value !== null && totalSteps.value !== null;
});

const progressPercentage = computed(() => {
  if (!hasProgress.value) return 0;
  return (currentStep.value / totalSteps.value) * 100;
});

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();

const routeToCourse = async () => {
  if (!props.courseInteraction.course.id) {
    await router.push({ name: 'Courses' });
    return;
  }

  console.debug(
    'Resuming : ' +
      props.courseInteraction.progress.current_section_id +
      '@ page=' +
      props.courseInteraction.progress.current_page_index,
  );
  await router.push({
    name: 'course-section',
    params: {
      sectionId: props.courseInteraction.progress.current_section_id,
    },
    query: {
      page: props.courseInteraction.progress.current_page_index.toString(),
    },
  });
};

async function signalCourseStartedToParent() {
  if (props.course.id) {
    await router.push('/course/' + props.course.id);
  }
}
</script>

<template>
  <div
    @click.prevent="routeToCourse"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-xl p-6 cursor-pointer hover:shadow-lg transition-shadow"
  >
    <!-- Course Title and Level -->
    <div class="text-gray-800 dark:text-gray-200 mb-4">
      <p class="text-sm text-gray-500 dark:text-gray-400 truncate">
        {{ props.courseInteraction.course.title }}
      </p>
      <h3 class="text-xl font-medium">{{ props.courseInteraction.progress.current_section_title }}</h3>
    </div>

    <!-- Progress Section -->
    <div v-if="hasProgress" class="space-y-3">
      <!-- Step Counter -->
      <div class="flex justify-between text-sm">
        <span class="text-gray-600 dark:text-gray-300"
          >{{ $t('message.step') }} {{ currentStep }} / {{ totalSteps }}</span
        >
      </div>

      <!-- Progress Bar -->
      <div class="h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
        <div
          class="h-full bg-blue-600 dark:bg-blue-500 rounded-full transition-all duration-300"
          :style="{ width: `${progressPercentage}%` }"
        ></div>
      </div>

      <!-- Resume Button and Time -->
      <div class="flex items-center justify-between mt-4">
        <button
          class="inline-flex items-center gap-2 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-50"
          @click.stop="routeToCourse"
        >
          <span class="material-symbols-outlined">play_arrow</span>
          {{ $t('message.resume') }}
        </button>
        <span
          class="text-sm text-gray-600 dark:text-gray-400"
          v-if="props.courseInteraction.progress.mins_left_in_current_section"
        >
          {{ $t('message.minutesLeft', props.courseInteraction.progress.mins_left_in_current_section) }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
