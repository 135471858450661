<script setup lang="ts">
import TextEditor from '@/views/courses/TextEditor.vue';
import TextFinishedButton from '@/components/didactics/text/TextFinishedButton.vue';
import DOMPurify from 'dompurify';
import { onMounted, ref, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useCourseStore } from '@/stores';
import { onBeforeRouteLeave } from 'vue-router';

const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  boxReducedWidth: {
    type: Number,
    required: true,
  },
  germanContainerWidth: {
    type: Number,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  idSuffixForTesting: {
    type: [String, null],
    required: false,
    default: null,
  },
});

const courseStore = useCourseStore();
const alertStore = useAlertStore();

const unsavedChanges = ref(false);
const contentEditor = ref(null);
const isSavingChanges = ref(false);
watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) return;
      setTimeout(() => {
        saveText();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

onMounted(() => {
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveText();
      return undefined;
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveText();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const saveText = async () => {
  console.log('saving text');
  console.log('saving text:', props.isEditing, contentEditor.value, props.contentItem);
  if (!props.isEditing) return;
  if (!contentEditor.value) return;
  if (!props.contentItem) return;
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;

  const content = contentEditor.value.getHtmlContent();
  console.log('saving text: ' + content);

  try {
    await courseStore.updateTextItem(props.sectionId, props.contentItem.id, content);
    console.log('saved text');
    unsavedChanges.value = false;
    contentEditor.value.resetEmitState();
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to autosave text', 'Error', error);
    throw new Error('Failed to autosave text');
  } finally {
    isSavingChanges.value = false;
  }
};

const sanitize = (content: string) => {
  return DOMPurify.sanitize(content);
};

const getHtmlContent = () => {
  if (!contentEditor.value) return '';
  return contentEditor.value.getHtmlContent();
};

defineExpose({
  getHtmlContent,
});
</script>

<template>
  <div class="min-h-10">
    <TextEditor
      ref="contentEditor"
      :content="sanitize(<string>contentItem.text_item?.content ?? 'invalid text item')"
      :allowList="true"
      :allowTable="true"
      :allowEdit="<boolean>props.isEditing"
      @unsavedChanges="unsavedChanges = true"
      @changesCleared="unsavedChanges = false"
      :width="
        props.germanContainerWidth -
        (props.contentItem.text_item?.designation !== 'GENERAL' ? props.boxReducedWidth : 0) +
        'px'
      "
      :data-testid="`text-item-${props.idSuffixForTesting}`"
    />

    <!-- # text-item-{{ props.idSuffixForTesting }} -->

    <div class="absolute bottom-0.5 end-3">
      <TextFinishedButton
        v-if="!props.isEditing"
        :contentItemId="contentItem.id"
        :sectionIndex="<number>props.sectionIndex"
        :pageIndex="<number>props.pageIndex"
      />
    </div>
  </div>
</template>

<style scoped></style>
