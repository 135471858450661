export const VocabMode = {
  TRANSLATE: 'TRANSLATE',
  EXPLAIN: 'EXPLAIN',
};

export enum MeaningAndMakingTestMode {
  EXPLAIN = 'EXPLAIN',
  FIND_TERM = 'FIND_TERM',
  MAKE_SENTENCE = 'MAKE_SENTENCE',
  MAKE_SPECIFIC_SENTENCE = 'MAKE_SPECIFIC_SENTENCE',
  USE_SET_PHRASE = 'USE_SET_PHRASE',
};
