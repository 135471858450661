<script setup>
// TODO make elements own components

import ProfileImage from '@/components/ProfileImage.vue';
import { getApiClient } from '@/apiclient/client';
import { router } from '@/router';
import Badge from '@/components/Badge.vue';
import CaseInteractionListEmptyState from '@/components/cases/CaseInteractionListEmptyState.vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { computed } from 'vue';

const props = defineProps({
  caseInteractions: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showUniqueCasesOnly: {
    type: Boolean,
    default: false,
  },
  showActiveCasesOnly: {
    type: Boolean,
    default: false,
  },
});

const titlePerson = (case_) => {
  if (!!case_.patient) {
    return case_.patient;
  }
  return case_.third_persons[0];
};

function badges(caseObject) {
  let taskTitles = [];
  caseObject.tasks.forEach((task) => {
    taskTitles.push({
      short: task.title.length > 12 ? task.title.substring(0, 12) + '...' : task.title,
      long: task.title,
    });
  });
  return taskTitles;
}

const dropdownId = (index) => {
  return 'hs-table-dropdown-1-' + index;
};

async function deleteCaseInteraction(caseInteraction) {
  await router.isReady();
  console.log('deleteCaseInteraction', caseInteraction);
  await (await getApiClient()).caseInteractions.deleteCaseInteraction(caseInteraction.id);
  await router.go(router.currentRoute);
}

const showCaseInteraction = computed(() => {
  if (!props.showUniqueCasesOnly && !props.showActiveCasesOnly) {
    return props.caseInteractions.map(() => true);
  }

  if (props.showActiveCasesOnly && !props.showUniqueCasesOnly) {
    return isActive.value;
  }

  if (!props.showActiveCasesOnly && props.showUniqueCasesOnly) {
    return isFirstInteractionForCase.value;
  }

  // First unique of all active interactions
  return isFirstActiveInteractionForCase.value;
});

const isFirstInteractionForCase = computed(() => {
  const firstInteractionWithUniqueCase = new Set();

  // First pass: collect IDs of first interactions for each unique case
  props.caseInteractions.forEach((interaction) => {
    if (interaction.case && !firstInteractionWithUniqueCase.has(interaction.case.id)) {
      firstInteractionWithUniqueCase.add(interaction.case.id); // Store case.id instead of interaction.id
    }
  });

  // Second pass: return true only for first interaction of each case
  return props.caseInteractions.map((interaction, index) => {
    const isFirstForCase =
      interaction.case &&
      firstInteractionWithUniqueCase.has(interaction.case.id) &&
      !props.caseInteractions.slice(0, index).some((prev) => prev.case && prev.case.id === interaction.case.id);
    return isFirstForCase;
  });
});

const isFirstActiveInteractionForCase = computed(() => {
  const firstActiveInteractionWithUniqueCase = new Set();

  props.caseInteractions.forEach((interaction) => {
    if (interaction.case && !firstActiveInteractionWithUniqueCase.has(interaction.case.id)) {
      firstActiveInteractionWithUniqueCase.add(interaction.case.id);
    }
  });

  return props.caseInteractions.map((interaction, index) => {
    const isFirstActiveForCase =
      interaction.case &&
      interaction.solved_at === null &&
      firstActiveInteractionWithUniqueCase.has(interaction.case.id) &&
      !props.caseInteractions
        .slice(0, index)
        .some((prev) => prev.case && prev.case.id === interaction.case.id && prev.solved_at === null);
    return isFirstActiveForCase;
  });
});

const isActive = computed(() => {
  return props.caseInteractions.map((interaction) => {
    return interaction.solved_at === null;
  });
});
</script>

<template>
  <div class="block min-h-full h-full max-h-ful min-w-full">
    <div v-if="isLoading" class="w-full h-[60vH] flex justify-center items-center">
      <LoadingSpinnerLarge />
    </div>
    <CaseInteractionListEmptyState v-else-if="caseInteractions.length === 0" />
    <table
      v-else
      class="flex-col min-w-full min-h-full h-full max-h-full divide-y divide-gray-300 dark:divide-gray-700"
    >
      <thead class="bg-gray-light dark:bg-neutral-800 sticky top-0 -mt-1 z-20">
        <tr>
          <th scope="col" class="px-6 py-3 text-left">
            <a class="group inline-flex items-center gap-x-2" href="#">
              <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                Patient
              </span>
              <!-- <div
                class="flex justify-center items-center w-5 h-5 border border-gray-200 group-hover:bg-gray-200 text-gray-400 rounded dark:border-gray-700 dark:group-hover:bg-gray-700 dark:text-gray-400"
              >
                <svg
                  class="w-2.5 h-2.5"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.55921 0.287451C7.86808 -0.0958171 8.40096 -0.0958167 8.70982 0.287451L12.9295 5.52367C13.3857 6.08979 13.031 7 12.3542 7H3.91488C3.23806 7 2.88336 6.08979 3.33957 5.52367L7.55921 0.287451Z"
                    fill="currentColor"
                  />
                  <path
                    d="M8.70983 15.7125C8.40096 16.0958 7.86808 16.0958 7.55921 15.7125L3.33957 10.4763C2.88336 9.9102 3.23806 9 3.91488 9H12.3542C13.031 9 13.3857 9.9102 12.9295 10.4763L8.70983 15.7125Z"
                    fill="currentColor"
                  />
                </svg>
              </div> -->
            </a>
          </th>

          <th scope="col" class="px-6 py-3 text-left">
            <a class="group inline-flex items-center gap-x-2" href="#">
              <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                Meine Rolle
              </span>
            </a>
          </th>

          <th scope="col" class="px-6 py-3 text-left">
            <a class="group inline-flex items-center gap-x-2" href="#">
              <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                Meine Aufgaben
              </span>
            </a>
          </th>

          <!-- <th scope="col" class="px-6 py-3 text-left">
            <a class="group inline-flex items-center gap-x-2" href="#">
              <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                Schwierigkeit
              </span>
            </a>
          </th>

          <th scope="col" class="px-6 py-3 text-left">
            <a class="group inline-flex items-center gap-x-2" href="#">
              <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                Öffentlich
              </span>
            </a>
          </th> -->

          <th scope="col" class="px-6 py-3 text-left">
            <a class="group inline-flex items-center gap-x-2" href="#">
              <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                Gelöst
              </span>
            </a>
          </th>

          <th scope="col" class="px-6 py-3 text-right"></th>
        </tr>
      </thead>

      <tbody class="divide-y divide-gray-300 min-h-full h-full max-h-full flex-col dark:divide-gray-700 z-0">
        <transition-group name="pop-and-fade">
          <template v-for="(caseInteraction, index) in caseInteractions" :key="caseInteraction.id">
            <tr
              v-if="showCaseInteraction[index]"
              class="bg-white hover:bg-gray-light dark:bg-neutral-900 dark:hover:bg-neutral-800"
            >
              <td class="h-px w-px whitespace-nowrap">
                <router-link class="block relative z-10" :to="'/case-interactions/' + caseInteraction.id">
                  <div class="px-6 py-2">
                    <div class="flex-shrink-0 group block">
                      <div class="flex items-center">
                        <ProfileImage
                          :image="
                            titlePerson(caseInteraction.case).profile_image
                              ? titlePerson(caseInteraction.case).profile_image.image_urls.small
                              : ''
                          "
                          :firstName="titlePerson(caseInteraction.case).details.first_name"
                          :lastName="titlePerson(caseInteraction.case).details.last_name"
                        />
                        <div class="ml-3">
                          <h3 class="font-semibold text-gray-800 dark:text-white">
                            {{ titlePerson(caseInteraction.case).details.first_name }}
                            {{ titlePerson(caseInteraction.case).details.last_name }}
                          </h3>
                          <p class="text-sm font-medium text-gray-400">
                            {{ titlePerson(caseInteraction.case).details.sex[0] }},
                            {{ titlePerson(caseInteraction.case).details.age }} Jahre
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </td>
              <td class="h-px w-60 min-w-[12rem]">
                <a class="block relative z-10" href="#lol">
                  <div class="px-6 py-2">
                    <p class="text-sm text-gray-500">
                      {{ caseInteraction.case.student_details?.role }}
                    </p>
                  </div>
                </a>
              </td>
              <td class="h-px w-72 whitespace-nowrap min-w-[12rem]">
                <a class="block relative z-10" href="#">
                  <div class="px-6 py-2">
                    <span
                      class="flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium text-gray-800 dark:text-gray-200"
                    >
                      <span v-for="badge in badges(caseInteraction.case)" class="group overflow-hidden items-center">
                        <span class="hidden group-hover:block">
                          <Badge :text="badge.long" color="yellow" />
                        </span>
                        <span class="block group-hover:hidden">
                          <Badge :text="badge.short" color="yellow" />
                        </span>
                      </span>
                    </span>
                  </div>
                </a>
              </td>
              <!-- <td class="h-px w-px whitespace-nowrap">
                <a class="block relative z-10" href="#">
                  <div class="flex items-center gap-x-3">
                    <span class="text-xs text-gray-500">2/4</span>
                    <div class="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                      <div
                        class="flex flex-col justify-center overflow-hidden bg-gray-800 dark:bg-gray-200"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </a>
              </td>
              <td class="h-px w-px whitespace-nowrap">
                <a class="block relative z-10" href="#">
                  <span class="block h-full p-6" href="#">
                    <span
                      class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                    >
                      <svg
                        class="w-2.5 h-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>
                      Ja
                    </span>
                  </span>
                </a>
              </td> -->
              <td class="h-px w-[4rem] whitespace-nowrap">
                <a class="block relative z-10" href="#">
                  <span class="block h-full p-6" href="#">
                    <span
                      class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium"
                      :class="{
                        'bg-green-200 dark:bg-green-800 text-green-800 dark:text-green-200':
                          !!caseInteraction.solved_at,
                        'bg-neutral-200 dark:bg-neutral-800 text-neutra-200 dark:text-neutral-800':
                          !caseInteraction.solved_at,
                      }"
                    >
                      <!-- TODO: other icon if not solved -->
                      <div v-if="!!caseInteraction.solved_at" class="inline-flex items-center gap-x-1">
                        <svg
                          class="w-2.5 h-2.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                          />
                        </svg>
                        Ja
                      </div>
                      <div v-else class="">Nein</div>
                    </span>
                  </span>
                </a>
              </td>
              <td class="h-px w-px whitespace-nowrap">
                <div class="px-6 py-2">
                  <div class="px-6 py-1.5 flex justify-end">
                    <a href="#">
                      <div
                        class="group inline-flex items-center divide-x divide-gray-300 border border-gray-300 bg-white shadow-sm rounded-md transition-all dark:divide-gray-700 dark:bg-neutral-700 dark:border-gray-700"
                      >
                        <div class="hs-tooltip inline-block">
                          <router-link
                            class="hs-tooltip-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-l-md bg-white text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                            :to="'/case-interactions/' + caseInteraction.id"
                          >
                            <svg
                              class="w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                              />
                            </svg>
                            {{ $t('message.resume') }}
                          </router-link>
                        </div>
                        <div class="hs-dropdown relative inline-flex [--placement:bottom-right]">
                          <button
                            :id="dropdownId(index)"
                            type="button"
                            class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-r-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          >
                            <svg
                              class="w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                              />
                            </svg>
                          </button>
                          <div
                            class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-300 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"
                            :aria-labelledby="dropdownId(index)"
                          >
                            <div class="py-2 first:pt-0 last:pb-0">
                              <span
                                class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600"
                              >
                                Aktionen
                              </span>
                              <a
                                href="#"
                                class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-light focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                              >
                                <svg
                                  class="w-4 h-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                                  />
                                  <path
                                    d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                                  />
                                </svg>
                                Copy
                              </a>
                            </div>
                            <div class="py-2 first:pt-0 last:pb-0">
                              <a
                                href="#"
                                class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-light focus:ring-2 focus:ring-blue-600 dark:text-red-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                                @click.prevent="deleteCaseInteraction(caseInteraction)"
                              >
                                Löschen
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <tr class="h-6">
            <!-- scroll dummy -->
          </tr>
        </transition-group>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.pop-and-fade-leave-active {
  animation: pop-out 500ms ease-in forwards;
  position: relative;
  will-change: opacity;
  background-color: rgb(243 244 246); /* gray-100 */
  transform-origin: center;
}

.pop-and-fade-enter-active,
.pop-and-fade-enter-from {
  animation: pop-in 500ms ease-in forwards;
  will-change: opacity;
  background-color: rgb(243 244 246); /* gray-100 */
  transform-origin: center;
}

@keyframes pop-out {
  0% {
    opacity: 1;
    background-color: rgb(243 244 246);
  }
  50% {
    opacity: 0.8;
    background-color: rgb(243 244 246);
  }
  62.5% {
    opacity: 0.5;
    background-color: rgb(243 244 246);
  }
  100% {
    opacity: 0;
    background-color: rgb(243 244 246);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0.1;
    background-color: rgb(243 244 246);
  }
  100% {
    opacity: 1;
    background-color: rgb(243 244 246);
  }
}
</style>
