<script setup lang="ts">
// TODO: make proper list of course interactions, as for case interactions

import { ref, computed, reactive, onMounted } from 'vue';
import CaseInteractionList from '@/components/cases/CaseInteractionList.vue';
import { router } from '@/router';
import { useAuthStore, useCaseInteractionStore, useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { CourseInteraction } from '@/apiclient';
import CourseHighlightList from '@/components/CourseHighlightList.vue';
import BottomNav from '@/components/BottomNav.vue';
const caseStore = useCaseStore();
const authStore = useAuthStore();
const { currentCase } = storeToRefs(caseStore);
const bottomNav = ref(null);

const courseInteractions = ref([] as CourseInteraction[]);
const isLoading = ref(false);

const props = defineProps({
  inlineOverflowingX: {
    type: Boolean,
    default: false,
  },
  outerHeaderHeight: {
    type: Number,
    required: false,
  },
});

async function loadCourseInteractionsOfUser(user_id: string) {
  isLoading.value = true;
  const client = await getApiClient();
  courseInteractions.value = await client.users.listCourseInteractionsOfUser(authStore.userId);
  console.log('courseInteractions', courseInteractions.value);
  isLoading.value = false;
}

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await router.isReady();
  await loadCourseInteractionsOfUser(authStore.userId);
});

const courses = computed(() => {
  console.log('courseInteractions', JSON.stringify(courseInteractions.value));
  return courseInteractions.value.map((ci) => ci.course);
});

const computedStyleMain = computed(() => {
  if (props.inlineOverflowingX) {
    return '';
  }
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px - ${bottomNav?.value?.scrollHeight}px)`,
  };
});
</script>

<template>
  <div :style="computedStyleMain" :class="{ 'overflow-y-scroll': !props.inlineOverflowingX }">
    <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <div
        class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
    <div
      v-else
      class="w-full min-h-full h-full max-h-full"
      :class="{
        'inline-flex overflow-x-scroll': props.inlineOverflowingX,
        'overflow-y-scroll': !props.inlineOverflowingX,
      }"
    >
      <div
        class="pb-10 flex"
        :class="{
          'inline-flex min-w-fit w-fit overflow-x-visible': props.inlineOverflowingX,
          'w-full min-h-fit h-fit max-h-fit overflow-y-visible': !props.inlineOverflowingX,
        }"
      >
        <CourseHighlightList :courses="courses" class="w-full" :inlineOverflowingX="props.inlineOverflowingX" />
      </div>

      <!-- Bottom Navigation -->
      <BottomNav ref="bottomNav" />
    </div>
  </div>
</template>

<style></style>
