<script lang="ts" setup>
import { ref, onMounted } from 'vue';

import { useCourseStore, useCaseStore, useAuthStore, useAlertStore } from '@/stores';
import CourseHighlightList from '@/components/CourseHighlightList.vue';
import { Course } from '@/apiclient';
import { storeToRefs } from 'pinia';
import { formatDate } from '@/helper';
import { useI18n } from 'vue-i18n';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import { router } from '@/router';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { getApiClient } from '@/apiclient/client';
import { computed } from 'vue';
import BottomNav from '@/components/BottomNav.vue';

const { t } = useI18n();

const courseStore = useCourseStore();
const caseStore = useCaseStore();
const alertStore = useAlertStore();
const courses = ref([] as Course[]);
const numCasesUnsolved = ref(0);
const fetchInProgress = ref(false);
const confirmCreateNewCourseModal = ref(null);
const noCoursesVisibleAsNoOrgMember = ref(false);
const bottomNav = ref(null);
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: false,
  },
});

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  fetchInProgress.value = true;
  numCasesUnsolved.value = await caseStore.getNumCasesUnsolved();

  // check if user is manager of some org. If so, allow to create new course
  let orgsManagedByUser = await (await getApiClient()).users.listManagedOrganizationsOfUser(authStore.userId);

  // courses.value = await courseStore.listCourses();
  courseStore
    .listCourses()
    .then((result) => {
      courses.value = result;
      console.log('fetched courses: ', courses.value);

      courses.value.sort((a, b) => a.title.localeCompare(b.title));

      // add fake course to end
      // TODO only if user is manager
      if (orgsManagedByUser.length > 0) {
        console.log('user is manager of orgs: ', orgsManagedByUser);
        courses.value.push({
          id: null,
          user: user.value,
          created_at: formatDate(new Date().toDateString()),
          title: t('message.createNewCourse'),
          description: t('message.createNewCourseDescription'),
          chapters: [],
          is_public: true,
        });
      }
      console.log('final courses list: ', courses.value);
    })
    .catch((error) => {
      console.error('Error fetching courses:', error);
      alertStore.error('Fehler beim Laden der Kurse', 'Error', error);
    })
    .finally(() => {
      fetchInProgress.value = false;
    });
});

const onCreateNewCourse = async () => {
  if (!confirmCreateNewCourseModal.value) return;
  // ask whether to create a new course
  await confirmCreateNewCourseModal.value.promptUserConformation();
};

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px - ${bottomNav?.value?.scrollHeight}px)`,
  };
});

const createNewCourse = async () => {
  // create a new course an route there
  await courseStore.appendEmptyCourse();
  await router.push('/course/' + courseStore.currentCourse.id);
  alertStore.success('Neuer Kurs erstellt.');
};
</script>

<template>
  <div>
    <div v-if="fetchInProgress" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <LoadingSpinnerLarge />
    </div>
    <div class="w-full overflow-y-scroll" :style="computedStyleMain">
      <!--    <header class="sticky top-0 z-20 flex flex-wrap bg-gray-100 dark:bg-neutral-700">-->
      <!--      <div class="gap-2 py-2 min-w-fit px-4 sm:px-6 lg:px-8 mx-auto justify-center items-center">-->
      <!--        <button-->
      <!--          class="bg-blue-600 focus:ring-blue-600 min-w-fit py-3 px-4 justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800 hover:bg-blue-700"-->
      <!--        >-->
      <!--          <span class="py-0.5 ps-1.5 pe-2.5 inline-flex items-center gap-x-1.5 rounded-full">-->
      <!--            <router-link to="/wartebereich-zna" class="btn btn-primary">{{-->
      <!--              $t('message.toEmergencyAdmissionWaitingRoom')-->
      <!--            }}</router-link>-->
      <!--            <span-->
      <!--              class="py-0.5 px-1.5 rounded-full text-xs font-medium bg-blue-50 border border-blue-200 text-blue-600"-->
      <!--            >-->
      <!--              {{ numCasesUnsolved }}-->
      <!--            </span>-->
      <!--          </span>-->
      <!--        </button>-->
      <!--      </div>-->
      <!--    </header>-->
      <main class="flex min-h-fit h-fit max-h-fit overflow-y-visible pt-6 pb-10">
        <CourseHighlightList :courses="courses" class="w-full" @createNewCourse="onCreateNewCourse" />
      </main>

      <!-- Bottom Navigation -->
      <BottomNav ref="bottomNav" />
    </div>
    <UserConfirmationModal
      ref="confirmCreateNewCourseModal"
      prompt_message=""
      :approve_message="$t('message.createNewCourseDescription')"
      :discard_message="$t('message.back')"
      overlayId="conformCreateNewCourseModal"
      approve_color="bg-blue-500 hover:bg-blue-600"
      @approved="createNewCourse"
      @declined=""
    >
      Möchten Sie einen neuen Kurs für Ihre Studierenden anlegen?
      <!--    TODO i18n  {{ $t('message.confirmDeleteChapter') }}-->
    </UserConfirmationModal>
  </div>
</template>

<style></style>
