import { ref, watch, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useAlertStore } from '@/stores';

interface AutosaveOptions {
  saveFunction: () => Promise<void>;
  debounceMs?: number;
  beforeUnloadCheck?: boolean;
  routerGuard?: boolean;
  onError?: (error: any) => void;
}

export function useAutosave({
  saveFunction,
  debounceMs = 3000,
  beforeUnloadCheck = true,
  routerGuard = true,
  onError = (error) => {
    const alertStore = useAlertStore();
    alertStore.error('Autosave failed', 'Error', error as Error);
  },
}: AutosaveOptions) {
  const unsavedChanges = ref(false);
  const isSavingChanges = ref(false);

  // Handle autosave on changes
  watch(
    () => unsavedChanges.value,
    async (newValue) => {
      if (newValue && !isSavingChanges.value) {
        setTimeout(async () => {
          await executeSave();
        }, debounceMs);
      }
    },
  );

  // Save function wrapper with state management
  const executeSave = async () => {
    if (isSavingChanges.value || !unsavedChanges.value) return;

    try {
      isSavingChanges.value = true;
      await saveFunction();
      unsavedChanges.value = false;
    } catch (error) {
      onError(error);
      throw error;
    } finally {
      isSavingChanges.value = false;
    }
  };

  // Handle save before browser/tab close
  if (beforeUnloadCheck) {
    window.onbeforeunload = (e) => {
      if (unsavedChanges.value) {
        executeSave();
        return undefined;
      }
    };
  }

  // Handle save before route change
  if (routerGuard) {
    onBeforeRouteLeave(async (to, from, next) => {
      if (unsavedChanges.value) {
        try {
          await executeSave();
          next();
        } catch (error) {
          const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
          if (userWantsToLeave) {
            next();
          } else {
            next(false);
          }
        }
      } else {
        next();
      }
    });
  }

  // Cleanup
  onBeforeUnmount(async () => {
    if (unsavedChanges.value) {
      await executeSave();
    }
    if (beforeUnloadCheck) {
      window.onbeforeunload = null;
    }
  });

  return {
    unsavedChanges,
    isSavingChanges,
    saveChanges: executeSave,
  };
}
