import eng from './eng.json';
import deu from './deu.json';
import aeb from './aeb.json';
import rus from './rus.json';
import tgk from './tgk.json';
import pes from './pes.json';
import spa from './spa.json';
import fra from './fra.json';
import tur from './tur.json';
// language codes are iso 639-3

export const defaultLocale = 'eng';
export const fallbackLocale = { default: ['eng', 'deu'] };

export const languages = {
  eng: eng,
  deu: deu,
  aeb: aeb, // tunesian arabic
  rus: rus,
  tgk: tgk, // tajik
  pes: pes, // iranian persian
  spa: spa, // spanish (castilian)
  fra: fra, // french
  tur: tur, // turkish
};
