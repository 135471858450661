<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
const { t, locale } = useI18n();

const props = defineProps({
  sectionContentItemId: {
    type: String,
    required: true,
  },
  sectionContentItemIndex: {
    type: Number,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

const textarea = ref<HTMLTextAreaElement | null>(null);
const textareaHeightAdjustedToInitialFetch = ref(false);

onMounted(() => {
  if (!props.isEditing) {
    // add field is_selected
    localQuestion.value.answers.forEach((a) => {
      a.is_selected = false;
    });
    emit('questionSolved', false);
  }
  localQuestion.value.answers.forEach((a, idx) => {
    a.originalIndex = idx;
  });
  initCompleted.value = true;

  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveQuestion();
      return undefined;
    }
  };
});

const emit = defineEmits(['interactionStarted', 'questionSolved']);

const localQuestion = ref(props.question);
const unsavedChanges = ref(false);
const hasBeenCheckedCorrect = ref(false); // disallow multiple checks (at least until remounted...)
const alertStore = useAlertStore();
const authStore = useAuthStore();
const initCompleted = ref(false);
const courseInteractionStore = useCourseInteractionStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const isSavingChanges = ref(false);

onBeforeUnmount(() => {
  if (unsavedChanges.value) {
    saveQuestion();
  }
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveQuestion();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const shuffledAnswers = computed(() => {
  if (props.isEditing) return localQuestion.value.answers;
  let shuffled = [...localQuestion.value.answers]; // 1st order copy, 2nd order ref
  return shuffled.sort(() => Math.random() - 0.5);
});

const numCorrect = computed(() => {
  return localQuestion.value.answers.filter((a) => a.is_correct).length;
});

const numAnswers = computed(() => {
  return localQuestion.value.answers.length;
});

// Getter for checkbox value
const getAnswerValue = (index: number) => {
  return props.isEditing
    ? localQuestion.value.answers[index]?.is_correct ?? false
    : localQuestion.value.answers[index]?.is_selected ?? false;
};

// Setter for checkbox value
const setAnswerValue = (index: number, value: boolean) => {
  if (props.isEditing) {
    localQuestion.value.answers[index].is_correct = value;
    unsavedChanges.value = true;
  } else {
    localQuestion.value.answers[index].is_selected = value;
    emit('interactionStarted');
    // give audio feedback
    if (!value) return;
    if (!localQuestion.value.answers[index].is_correct) alertStore.playMajorFailureSound();
  }
};

const isSolved = computed(() => {
  if (!props.isEditing) {
    return localQuestion.value.answers.every((a) => a.is_correct === a.is_selected);
  }
  return false;
});

const isWrong = computed(() => {
  if (!props.isEditing) {
    return localQuestion.value.answers.some((a) => a.is_selected && !a.is_correct);
  }
  return false;
});

watch(
  () => isSolved.value,
  async (newValue) => {
    if (newValue) {
      if (!courseInteractionFetchCompleted.value) return;
      if (hasBeenCheckedCorrect.value) return; // disallow multiple checks (at least until remounted...)
      await courseInteractionStore
        .evaluateMcQuestion(props.sectionContentItemId, localQuestion.value.id, localQuestion.value.answers)
        .then(async (response) => {
          if (!response?.is_correct) return;
          console.log(response);
          response.notifications?.forEach((notification) => {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          });
          await authStore.fetchUserXp();
          hasBeenCheckedCorrect.value = true;
          emit('questionSolved', true);
        })
        .catch((error) => {
          alertStore.error('Failed to evaluate form', 'Error', error);
          console.error(error);
          throw new Error('Failed to evaluate form');
        });
    }
  },
);

const saveQuestion = async () => {
  if (!props.isEditing) return;
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  (await getApiClient()).courseSectionItems
    .updateMcQuestionInContentItemWithResource(props.sectionContentItemId, props.question.id, {
      question_text: localQuestion.value.question_text,
      question_media_item_id: localQuestion.value.question_media_item_id,
      answers: localQuestion.value.answers,
    })
    .then(() => {
      unsavedChanges.value = false;
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave question', 'Error', error);
      throw new Error('Failed to autosave question');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      setTimeout(() => {
        saveQuestion();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

watch(
  () => textarea.value,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        updateMinTextareaHeight(newVal, 1);
      }, 100);
    }
  },
);

watch(
  // this updates the textarea height when the question text is set initially
  () => localQuestion.value.question_text,
  (newVal) => {
    if (textareaHeightAdjustedToInitialFetch.value) return;
    if (!textarea.value) return;
    if (newVal) {
      setTimeout(() => {
        console.log('question text watcher: adjusting height');
        updateMinTextareaHeight(textarea.value, 1);
        textareaHeightAdjustedToInitialFetch.value = true;
      }, 100);
    }
  },
);
</script>

<template>
  <div
    class="border-gray-200 shadow-sm rounded-lg p-2 text-sm"
    :class="{
      'bg-white': !isSolved && !isWrong,
      'bg-teal-100 text-teal-600': isSolved,
      'bg-red-100 text-red-600': isWrong,
    }"
  >
    <div class="uppercase text-xs font-semibold">Frage</div>
    <textarea
      v-if="!!localQuestion.question_text || props.isEditing"
      ref="textarea"
      v-model="localQuestion.question_text"
      class="w-full px-2 py-0 my-2 resize-none text-sm rounded-lg text-start"
      :class="{
        'border-gray-200': props.isEditing,
        'border-gray-200 shadow-sm': !props.isEditing,
      }"
      :disabled="!props.isEditing"
      rows="1"
      @input="
        (event) => {
          const target = event.target as HTMLInputElement;
          updateMinTextareaHeight(target);
          if (props.isEditing) unsavedChanges = true;
        }
      "
    />

    <div v-if="!props.isEditing" class="uppercase text-xs font-semibold">
      Wähle {{ numCorrect }} aus {{ numAnswers }}
    </div>
    <div
      v-else
      @click.prevent="
        localQuestion.answers.push({
          answer_text: '',
          is_correct: false,
          originalIndex: localQuestion.answers.length,
        })
      "
      class="flex items-center group text-center uppercase text-xs font-semibold text-blue-600 hover:text-blue-700 cursor-pointer"
    >
      <span translate="no" class="pr-2 no-translate material-symbols-outlined text-xl">add_circle</span>
      <span class="group-hover:underline">Anwortmöglichkeit hinzufügen</span>
    </div>
    <div
      v-if="initCompleted"
      v-for="answer in shuffledAnswers"
      :key="answer.originalIndex"
      class="pl-7 flex-col flex items-center text-sm"
    >
      <div class="inline-flex items-center w-full">
        <div class="relative">
          <input
            :disabled="isSolved"
            :checked="getAnswerValue(<number>answer.originalIndex)"
            @change="setAnswerValue(<number>answer.originalIndex, $event.target.checked)"
            type="checkbox"
            class="shrink-0 border-gray-200 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
            :class="{
              'text-blue-600 focus:ring-blue-500 dark:checked:bg-blue-500 dark:checked:border-blue-500':
                !isSolved && !isWrong,
              'text-teal-600 focus:ring-teal-600': isSolved,
              'text-red-600 focus:ring-red-600': answer.is_selected && !answer.is_correct,
            }"
          />
          <span
            class="absolute select-none z-10 top-1 shrink-0 border-gray-200 rounded font-bold cursor-pointer h-4 w-4 bg-red-600 text-white flex items-center justify-center text-center"
            :class="{
              'opacity-100': answer.is_selected && !answer.is_correct,
              'opacity-0': !answer.is_selected || answer.is_correct,
            }"
            @click.prevent="setAnswerValue(<number>answer.originalIndex, !getAnswerValue(<number>answer.originalIndex))"
            :style="{ fontVariationSettings: '\'wght\' 600' }"
          >
            <span translate="no" class="notranslate select-none material-symbols-rounded text-xl"> close </span>
          </span>
        </div>
        <textarea
          v-model="answer.answer_text"
          class="w-full px-2 py-0 my-2 mx-4 resize-none rounded-lg text-sm text-start"
          :class="{
            'border-gray-200': props.isEditing,
            'border-gray-200 shadow-sm': !props.isEditing,
          }"
          :disabled="!props.isEditing"
          placeholder="Antwortmöglichkeit"
          rows="1"
          @input="
            (event) => {
              const target = event.target as HTMLInputElement;
              updateMinTextareaHeight(target);
              if (props.isEditing) unsavedChanges = true;
            }
          "
        />
        <span
          class="cursor-pointer flex items-center text-center text-red-600 hover:text-red-800"
          v-if="props.isEditing"
          @click="
            () => {
              localQuestion.answers.splice(answer.originalIndex, 1);
              unsavedChanges = true;
            }
          "
        >
          <span translate="no" class="material-symbols-outlined notranslate bg-white w-fit text-xl">delete</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
