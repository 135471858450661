<script setup lang="ts">
import { X } from 'lucide-vue-next';
import { computed, ref } from 'vue';

const props = defineProps({
  show: { type: Boolean, required: true },
  id: { type: String, required: true },
  title: { type: String, required: false },
  showCloseButton: { type: Boolean, default: true },
});

const emit = defineEmits(['close']);

const internalShow = ref(true);

const onClose = () => {
  internalShow.value = false;
  // wait for animation to finish before emitting close
  setTimeout(() => {
    emit('close');
  }, 500);
};

const showModal = computed(() => {
  return props.show && internalShow.value;
});
</script>

<template>
  <!-- backdrop -->
  <transition name="fade">
    <div
      v-show="showModal"
      @click="onClose"
      class="w-full h-full fixed top-0 start-0 z-[100] overflow-hidden bg-gray-900 bg-opacity-50"
    />
  </transition>
  <!-- end of backdrop -->

  <div class="fixed top-0 start-0 z-[100] w-full h-full flex justify-center items-center pointer-events-none">
    <transition name="fade-and-slide">
      <div
        v-if="showModal"
        :id="id"
        :data-testid="id"
        class="fixed top-0 z-[100] w-full h-full lg:max-w-3xl lg:h-[calc(100%-3.5rem)] mt-0 lg:mt-7 flex flex-col overflow-auto bg-white dark:bg-neutral-800 rounded-0 lg:rounded-xl border dark:border-gray-700 shadow-sm dark:shadow-neutral-700/[.7] pointer-events-auto"
      >
        <!-- header -->
        <div v-if="showCloseButton || title" class="h-[50px] w-full px-2 flex items-center justify-between">
          <button
            v-if="showCloseButton"
            :data-testid="`${id}-close-button`"
            type="button"
            class="cursor-pointer"
            @click="onClose"
          >
            <X color="black" :size="32" />
          </button>
          <div v-if="title">{{ title }}</div>
        </div>
        <!-- end of header -->

        <!-- content -->
        <slot></slot>
        <!-- end of content -->
      </div>
    </transition>
  </div>
</template>

<style scoped>
/* backdrop animation */
.fade-enter-active,
.fade-leave-active {
  transition: background-color 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply bg-opacity-0;
}
/* end of backdrop animation */

/* modal animation */
.fade-and-slide-enter-active,
.fade-and-slide-leave-active {
  transition: all 0.5s ease-out;
}

.fade-and-slide-enter-from,
.fade-and-slide-leave-to {
  @apply opacity-0 mt-0 lg:mt-14;
}
/* end of modal animation */
</style>
