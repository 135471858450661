<script setup>
import { useAlertStore, useAuthStore } from '@/stores';
import { ref, onMounted, computed, nextTick } from 'vue';
import { getApiClient } from '@/apiclient/client';
import ProgressButton from '@/components/ProgressButton.vue';
import 'vue-advanced-cropper/dist/style.css';
import Tooltip from '@/components/Tooltip.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import { roleTypes } from '@/helper';
import { HSOverlay } from 'preline';

import Interaction from './Interaction.vue';
import OtherSection from './OtherSection.vue';
import PersonsSection from './PersonsSection.vue';

const { fromDialogId, contentItemId, copyDialog, editDialog, viewOnly, outerHeaderHeight, overlayId } = defineProps({
  fromDialogId: {
    type: String,
    default: null,
  },
  contentItemId: {
    type: String,
    default: null,
  },
  copyDialog: {
    type: Boolean,
    default: false,
  },
  editDialog: {
    type: Boolean,
    default: false,
  },
  viewOnly: {
    type: Boolean,
    default: false,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  overlayId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['closed', 'created', 'updated']);

const authStore = useAuthStore();
const alertStore = useAlertStore();

const modal = ref(null);

let randomValues = {};
const isLoading = ref(true);
const isSubmitting = ref(false);
const isUploading = ref(false);
const isFetchingRandomValues = ref(false);
const yScrollContainer = ref();
const startingFromCompleteDialog = ref(false);
const setMedicalConditions = ref(false);
const willBeResetting = ref(false);

const cropper = ref(null); // Reference to the cropper component
const image = ref({ src: null, type: null });
const userConformationModalHandle = ref();
const waitModalHandle = ref();

const useStructuredForm = ref(false);
const confirmFreetext = ref(false);

// appearance of form and available options
const allowFreedhandInput = ref(true);
const allowFillByExampleDialog = ref(true);
const formSubtitle = ref('Erstelle einen neuen Dialog als Übung zum Hörverstehen für deinen Kurs');

import { DEBUG } from '@/helper/debugging';

const persons = ref([]);
const messages = ref([]);
const otherDialogDetails = ref({});
const privacyAndTermsAcceptedBefore = ref(false); // TODO check this !!
const privacyAndTermsAcceptedNow = ref(false);

const hashtagOverlayId = computed(() => `#${overlayId}`);

function scrollToBottom() {
  const container = yScrollContainer.value;
  console.log('scrollHeight:', container.scrollHeight);

  if (!container) {
    return;
  }

  container.scroll({
    top: 1000,
    behavior: 'smooth',
  });
}

async function updateDialog() {
  console.log('Updating dialog with id: ' + fromDialogId);
  console.log('persons: ', persons.value);
  (await getApiClient()).dialogs
    .updateDialog(contentItemId, fromDialogId, {
      details: otherDialogDetails.value,
      participants: persons.value,
      messages: messages.value,
    })
    .then(async () => {
      alertStore.success('Dialog erfolgreich aktualisiert');
      emit('updated');
      await new Promise((resolve) => setTimeout(resolve, 500));
      close();
      // resetForm();
    })
    .catch(async (error) => {
      try {
        let error_dict = JSON.stringify(error);
        let error_details = JSON.parse(error['body']['detail']);
        let error_msg = error_details['msg'];
        let error_code = error['status'];
        alertStore.error(error_msg + ' (Error ' + error_code + ')');
      } catch (e) {
        alertStore.error(error);
      }
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}

async function submitDialog() {
  console.log('startingFromCompleteDialog: ', startingFromCompleteDialog.value);
  if (!!startingFromCompleteDialog.value) {
    await updateDialog();
    return;
  }

  (await getApiClient()).dialogs
    .createDialog(contentItemId, {
      details: otherDialogDetails.value,
      participants: persons.value,
      messages: messages.value,
    })
    .then(async (newDialog) => {
      alertStore.success('Dialog erfolgreich erstellt!');
      console.debug('Finished submission. Created dialog with id: ' + newDialog.id);
      emit('created', newDialog.id);
      await new Promise((resolve) => setTimeout(resolve, 500));
      close();
      // resetForm();
    })
    .catch(async (error) => {
      try {
        let error_dict = JSON.stringify(error);
        let error_details = JSON.parse(error['body']['detail']);
        let error_msg = error_details['msg'];
        let error_code = error['status'];
        alertStore.error(error_msg + ' (Error ' + error_code + ')');
      } catch (e) {
        alertStore.error(error);
      }
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}

async function loadDialog() {
  if (!fromDialogId) {
    return;
  }
  console.log('Loading dialog from ID: ' + fromDialogId);
  const oldDialog = await (await getApiClient()).dialogs.getDialog(fromDialogId, contentItemId);
  console.log('Loaded dialog: ' + JSON.stringify(oldDialog));

  // Set dialog details
  otherDialogDetails.value = {
    heading: oldDialog.details.heading || '',
    description: oldDialog.details.description || '',
  };

  // Map participants to persons
  persons.value = oldDialog.participants.map((participant, index) => {
    console.log('loading participant: ', participant);
    const speaker = participant.speaker;
    return {
      create_id: index,
      profile_image: speaker.profile_image,
      voice: { id: speaker.voice.id }, // Map to VoiceExpose format
      details: {
        age: speaker.details.age,
        sex: speaker.details.sex,
        first_name: speaker.details.first_name,
        last_name: speaker.details.last_name,
        academic_title: speaker.details.academic_title,
        role: {
          value: participant.participant_type === 'PATIENT' ? 'PATIENT' : speaker.type,
          display_name:
            participant.participant_type === 'PATIENT'
              ? 'Patient:in'
              : roleTypes.find((r) => r.value === speaker.type)?.display_name || '',
        },
      },
    };
  });

  // Map messages
  messages.value = oldDialog.messages.map((message, index) => ({
    id: message.id,
    index: index,
    content: message.content || '',
    person_message_involvements: message.participant
      ? [
          {
            create_id: persons.value.findIndex(
              (p) =>
                p.details.first_name === message.participant.speaker.details.first_name &&
                p.details.last_name === message.participant.speaker.details.last_name,
            ),
            involvement_type: message.involvement_type || 'IN_THE_ROOM',
          },
        ]
      : null,
    type:
      message.type === 'SAY' || message.type === 'THIRD_PERSON'
        ? 'PERSON_SAY'
        : message.type === 'DESCRIPTION'
        ? 'DESC'
        : 'AUDIO_EFFECT',
    audio_url: message.audio_effect_url || '',
  }));

  startingFromCompleteDialog.value = true;
}

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${outerHeaderHeight}px)`,
  };
});

const basicValidationPassed = computed(() => {
  let valid = true;
  if (persons.value.length === 0) {
    valid = false;
  }
  if (messages.value.length === 0) {
    valid = false;
  }
  messages.value.forEach((message) => {
    if (
      message.type === 'PERSON_SAY' &&
      (message.person_message_involvements === null ||
        message.person_message_involvements.length !== 1 ||
        message.person_message_involvements[0].length === 0)
    ) {
      valid = false;
    }
  });
  return valid;
});

const resetForm = () => {
  otherDialogDetails.value = {
    title: '',
    description: '',
  };
  persons.value.length = 0;
  messages.value.length = 0;
};

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await loadDialog();
  isLoading.value = false;

  await new Promise((resolve) => setTimeout(resolve, 200));
  await HSOverlay.autoInit(); // we need to do this in order to make modals for components work which are mounted AFTER the app is initialized and AFTER the router re-initialized HSStaticMethods.autoInit()

  // Get the modal element and initialize it
  const modalElement = document.querySelector(hashtagOverlayId.value);
  modal.value = HSOverlay.getInstance(modalElement);

  // If no instance exists, create one
  if (!modal.value) {
    modal.value = new HSOverlay(modalElement);
  }

  await nextTick();

  modal.value.addEventListener('close.hs.overlay', () => {
    console.debug('closing dialog');
    emit('closed');
  });
});

const close = () => {
  HSOverlay.close(hashtagOverlayId.value);
};

const open = async () => {
  HSOverlay.open(hashtagOverlayId.value);
  privacyAndTermsAcceptedNow.value = false;
  await loadDialog();
};

defineExpose({
  open,
  close,
});
</script>

<template>
  <div
    :id="overlayId"
    class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[50] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    <div
      class="autofocus pointer-events-auto hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-6xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]"
    >
      <div
        class="max-h-full relative overflow-auto flex flex-col px-2 pb-8 pt-12 bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <!-- upper right corner "X" button -->
        <div class="absolute top-0 right-0">
          <button
            type="button"
            class="pointer-events-auto hs-dropup-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs md:text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            :data-hs-overlay="hashtagOverlayId"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <!-- end of "X" button -->

        <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
          <div
            class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
            role="status"
            aria-label="loading"
          />
        </div>
        <!-- Card Section -->
        <div
          ref="yScrollContainer"
          v-if="!isLoading"
          class="flex w-full min-w-full overflow-y-scroll pt-12"
          :style="computedStyleMain"
        >
          <!-- added this outer container to fix scrolling issue -->

          <div class="mx-auto flex h-fit min-h-fit max-h-fit overflow-visible">
            <!-- inner container with actual input field. Smaller than full page size -->

            <!-- Card -->
            <div class="flex-col h-fit min-h-fit max-h-fit dark:bg-neutral-900 overflow-visible">
              <div class="justify-between items-center space-x-4">
                <div class="mb-8 justify-left gap-y-1">
                  <h2 class="text-xl font-bold text-gray-800 dark:text-gray-200">
                    Neuer <span class="text-blue-600">Hör-Dialog</span>
                  </h2>
                  <div class="inline-flex items-center gap-x-1">
                    <p class="text-sm text-gray-600 dark:text-gray-400">
                      {{ formSubtitle }}
                    </p>
                  </div>
                </div>
                <div class="mb-8 justify-right" v-show="allowFillByExampleDialog">
                  <!-- <ProgressButton
                  text="Beispielszene"
                  :showProgress="isFetchingRandomValues"
                  :isLoading="true"
                  :onclick="onGenerateExample"
                  type="button"
                  color="white"
                /> -->
                </div>
              </div>

              <div class="flex-col overflow-visible h-fit min-h-fit">
                <div class="">
                  <OtherSection v-model="otherDialogDetails" />
                </div>

                <div class="">
                  <PersonsSection
                    v-model="persons"
                    @scrollToBottom="scrollToBottom"
                    :requireAtLeastOnePatient="false"
                    :startingFromCompleteDialog="startingFromCompleteDialog"
                  />
                </div>

                <div class="">
                  <Interaction v-model="messages" :persons="persons" />
                </div>

                <!-- Final Content -->
                <div ref="finalContent" style="display: none">
                  <div
                    class="p-4 h-48 bg-gray-50 mb-4 flex flex-col justify-center items-center border border-dashed border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
                  >
                    <!-- TODO: "Zum Dialog"-Button -->
                    <div
                      v-show="isSubmitting"
                      class="animate-spin ml-2 inline-block w-16 h-16 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                      role="status"
                      aria-label="loading"
                    />
                    <div v-show="!isSubmitting">
                      <span translate="no" class="material-symbols-outlined notranslate text-8xl"> cheer </span>
                    </div>
                    <h3 v-show="isSubmitting" class="text-gray-500 dark:text-neutral-500 pt-4">
                      ... Dialog wird erstellt ...
                    </h3>
                    <h3 v-show="!isSubmitting" class="text-gray-500 dark:text-neutral-500 pt-4">Fertig</h3>
                  </div>
                </div>
                <!-- End Final Content -->

                <!-- Button Group -->
                <div class="flex justify-between items-center gap-x-2">
                  <div
                    :disabled="isSubmitting || !basicValidationPassed"
                    class="inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    aria-haspopup="dialog"
                    aria-expanded="false"
                    aria-controls="hs-stacked-overlays-2"
                    :data-hs-overlay="`#${overlayId}_confirm`"
                    data-hs-overlay-options='{
                      "isClosePrev": false
                    }'
                  >
                    <ProgressButton
                      icon="ai_sparkle"
                      :text="startingFromCompleteDialog ? 'Änderungen speichern und vertonen' : 'Erstellen'"
                      :showProgress="isSubmitting"
                      :disabled="isSubmitting || !basicValidationPassed"
                    />
                  </div>
                </div>
                <!-- End Button Group -->
              </div>
              <!-- End Stepper -->
            </div>
            <!-- End Card -->
          </div>
        </div>
        <!-- End of scrolling stuff -->
      </div>
    </div>
  </div>
  <UserConfirmationModal
    ref="userConformationModalHandle"
    prompt_message="Dialog abschließen"
    approve_message="Bestätigen & Dialog erstellen"
    discard_message="Zurück"
    :overlayId="`${overlayId}_confirm`"
    header_icon="warning"
    @approved="submitDialog"
    @declined=""
    :closePrevModal="false"
    :disableApproveButton="!privacyAndTermsAcceptedNow"
  >
    <template v-slot:default>
      <div class="flex-col gap-y-2">
        <div class="mb-2">Bitte bestätige, dass beim Erstellen bzw. mit Veröffentlichen dieses Dialogs</div>
        <ul class="list-none">
          <li v-if="!privacyAndTermsAcceptedBefore" class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-teal-600 mr-2">check_circle</span>
            <span class="flex-1">
              du in unsere allgemeinen
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/privacy-and-terms"
                class="text-blue-600 hover:underline"
                >Datenschutz- und Nutzungsbestimmungen</a
              >
              (weiterhin bzw. in der ggf. aktuellen Version) einwilligst,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-teal-600 mr-2">check_circle</span>
            <span class="flex-1">
              du dir bewusst bist, dass die Inhalte mit Methoden der künstlichen Intelligenz erstellt werden,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-teal-600 mr-2">check_circle</span>
            <span class="flex-1">
              du dir bewusst bist, dass für die inhaltliche Richtigkeit der Inhalte keine Gewähr gegeben werden kann,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>kein</strong> urheberrechtlich geschütztes Material verwendet wurde bzw. anderen zugänglich
              gemacht wird, für das du nicht über die entsprechende Berechtigung verfügst,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>keine</strong> geschützten Patienten- oder Unternehmens- oder personenbezogenen oder
              personenbeziehbaren Daten verwendet wurden bzw. anderen zugänglich gemacht werden,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>keine</strong> diskriminierenden Inhalte verwendet wurden bzw. anderen zugänglich gemacht werden,
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>keine</strong> Persönlichkeits-, Urheberrechts- oder Eigentumsrechte anderer oder berufliche
              Schweige- oder andere Pflichten verletzt wurden oder werden und
            </span>
          </li>
          <li class="flex items-start mb-2">
            <span translate="no" class="material-symbols-outlined notranslate text-red-600 mr-2">block</span>
            <span class="flex-1">
              <strong>nicht</strong> in anderer Weise gegen lokale Gesetzt verstoßen wurde oder wird oder Beihilfe dazu
              geleistet wird.
            </span>
          </li>
        </ul>
        <div class="flex w-full border-t border-gray-200 pt-4 mt-4">
          <input
            type="checkbox"
            class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            id="hs-default-checkbox-terms"
            v-model="privacyAndTermsAcceptedNow"
          />
          <label
            for="hs-default-checkbox-terms"
            class="text-sm text-gray-800 font-semibold ms-3 dark:text-neutral-400 text-start"
          >
            Ich stimme den allgemeinen Nutzungs- und Datenschutz sowie den obigen weiterführenden Bestimmungen zu.
          </label>
        </div>
      </div>
    </template>
  </UserConfirmationModal>
</template>

<style></style>
