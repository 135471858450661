<script setup lang="ts">
import { Sparkles } from 'lucide-vue-next';
import { VocabItem } from '@/apiclient';
import { computed, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import AudioPlayerIcon from '@/components/various/AudioPlayerIcon.vue';
import { MeaningAndMakingTestMode } from '@/helper/vocab';

const { t } = useI18n();

const props = defineProps({
  vocab: { type: Object as PropType<VocabItem>, required: true },
  heading: { type: String, required: false },
  mode: { type: String as PropType<MeaningAndMakingTestMode>, required: false },
});

const showHelp = ref(false);
const immediatelyHideHelp = ref(false);

const toggleHelp = () => {
  showHelp.value = !showHelp.value;
};

watch(
  () => props.vocab.id,
  () => {
    showHelp.value = false;
    immediatelyHideHelp.value = true;
    setTimeout(() => {
      immediatelyHideHelp.value = false;
    }, 300);
  },
);

const obfuscatedExplanation = computed(() => {
  if (!props.vocab.explanation) return '';
  return props.vocab.explanation.replace(new RegExp(props.vocab.term, 'gi'), '*'.repeat(5));
});
</script>

<template>
  <div class="flex flex-col gap-y-6">
    <div v-if="heading" class="text-xl">
      {{ heading }}
    </div>

    <div class="flex items-center gap-x-5">
      <AudioPlayerIcon
        v-if="vocab.audio_url && mode !== MeaningAndMakingTestMode.FIND_TERM"
        :audio-url="vocab.audio_url"
      />

      <div class="text-[32px] max-h-[250px] overflow-y-auto">
        <span v-if="mode === MeaningAndMakingTestMode.FIND_TERM">
          {{ obfuscatedExplanation }}
        </span>
        <span v-else>
          {{ vocab.term }}
        </span>
      </div>
    </div>

    <hr class="h-[1px] bg-gray-medium border-0" />

    <div v-if="mode !== MeaningAndMakingTestMode.FIND_TERM" class="flex flex-row justify-between items-start gap-x-6">
      <transition name="fade">
        <span v-show="showHelp" v-if="!immediatelyHideHelp" class="text-sm">
          {{ vocab.explanation }}
        </span>
      </transition>

      <button @click="toggleHelp" class="flex items-center ml-auto">
        <Sparkles :size="20" class="mr-1" :fill="showHelp ? 'currentColor' : 'none'" />
        {{ $t('message.help') }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
