<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue';
import { useAlertStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import { X, Loader } from 'lucide-vue-next';
import { computed } from 'vue';
import { Section, SectionContentItem_Output } from '@/apiclient';
import { getApiClient } from '@/apiclient/client';
import SectionContentReorder from '@/components/courses/SectionContentReorder.vue';
const { t } = useI18n();

const props = defineProps({
  section: {
    type: Object as PropType<Section>,
    required: true,
  },
});

const emit = defineEmits(['closed', 'updated']);

// Stores and store references
const alertStore = useAlertStore();

const show = ref(false);
const isSubmitting = ref(false);

// Lifecycle hooks
onMounted(async () => {
  show.value = true;
});

const submit = async (changedItems: SectionContentItem_Output[]) => {
  isSubmitting.value = true;

  const apiClient = await getApiClient();
  console.log('received changedItems', changedItems);

  try {
    // set to temporary indices
    await Promise.all(
      changedItems.map((item) =>
        apiClient.courseSectionItems.updateSectionContentItemMetadata(item.id, {
          section_id: props.section.id,
          index: -1337 + item.index,
          page_index: -1337 + item.page_index,
        }),
      ),
    );

    // set to new inidices
    await Promise.all(
      changedItems.map((item) =>
        apiClient.courseSectionItems.updateSectionContentItemMetadata(item.id, {
          section_id: props.section.id,
          index: item.index,
          page_index: item.page_index,
        }),
      ),
    );

    alertStore.success('Item order updated.');
  } catch (error) {
    console.error('Error updating section content item metadata (order / position)', error);
    alertStore.error(
      'Error updating item order / position',
      'Error',
      error instanceof Error ? error : new Error(String(error)),
    );
    emit('updated');
    throw error;
  } finally {
    isSubmitting.value = false;
    emit('updated');
  }
};

const onClose = () => {
  show.value = false;

  // wait for animation to finish before unmounting
  setTimeout(() => {
    emit('closed');
  }, 500);
};
</script>

<template>
  <!-- backdrop -->
  <transition name="fade">
    <div
      v-show="show"
      @click="onClose"
      class="w-full h-full fixed top-0 z-[100] overflow-hidden bg-gray-900 bg-opacity-50"
    />
  </transition>
  <!-- end of backdrop -->

  <div class="fixed top-0 z-[100] w-full h-full flex justify-center items-center pointer-events-none">
    <transition name="fade-and-slide">
      <div
        v-if="show"
        ref="modalWindow"
        class="fixed top-0 z-[100] w-full h-full lg:max-w-3xl lg:h-[calc(100%-3.5rem)] mt-0 lg:mt-7 flex flex-col overflow-auto bg-white dark:bg-neutral-800 rounded-0 lg:rounded-xl border dark:border-gray-700 shadow-sm dark:shadow-neutral-700/[.7] pointer-events-auto"
      >
        <!-- "X" button -->
        <div class="h-[50px] w-full px-2 flex items-center justify-between">
          <button data-testid="make-sentences-top-close-button" type="button" class="cursor-pointer" @click="onClose">
            <X color="black" :size="32" />
          </button>
        </div>
        <!-- end of "X" button -->

        <!-- loader -->
        <div
          v-if="isSubmitting"
          class="flex h-28 mt-16 rounded-2xl justify-center items-center border-[#FF8000] bg-[#FDF6EF]"
        >
          <Loader :size="56" class="animate-spin" color="#FF8000" />
        </div>
        <!-- end of loader -->

        <SectionContentReorder v-else :section="props.section" @updated-items="submit" />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.progress-bar-inner {
  transition: width 0.3s;
}

/* backdrop animation */
.fade-enter-active,
.fade-leave-active {
  transition: background-color 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply bg-opacity-0;
}
/* end of backdrop animation */

/* modal animation */
.fade-and-slide-enter-active,
.fade-and-slide-leave-active {
  transition: all 0.5s ease-out;
}

.fade-and-slide-enter-from,
.fade-and-slide-leave-to {
  @apply opacity-0 mt-0 lg:mt-14;
}
/* end of modal animation */
</style>
