<script lang="ts" setup>
import { X } from 'lucide-vue-next';
import { VocabItem } from '@/apiclient';
import { useI18n } from 'vue-i18n';
import { updateMinTextareaHeight } from '@/helper';
import { watch } from 'vue';

const { t } = useI18n();

const editingItem = defineModel<VocabItem>('editingItem');
const emit = defineEmits(['unsavedChanges']);

const usageExampleTextareas = new Map<number, HTMLTextAreaElement>();

const addUsageExample = () => {
  if (editingItem.value) {
    editingItem.value.usage_examples = [...(editingItem.value.usage_examples || []), ''];
  }
};

const removeUsageExample = (index: number) => {
  if (editingItem.value?.usage_examples) {
    editingItem.value.usage_examples = editingItem.value.usage_examples.filter((_, i) => i !== index);
  }
  emit('unsavedChanges');
};

watch(
  () => editingItem.value?.id,
  () => {
    // Update all usage example textarea heights
    usageExampleTextareas.forEach((textarea) => {
      updateMinTextareaHeight(textarea);
    });
  },
);
</script>

<template>
  <div :key="editingItem?.id">
    <div
      v-if="editingItem"
      v-for="(example, index) in editingItem.usage_examples"
      :key="index"
      class="flex gap-x-2 items-center"
    >
      <textarea
        :ref="
          (el) => {
            if (el) usageExampleTextareas.set(index, el as HTMLTextAreaElement);
            else usageExampleTextareas.delete(index);
          }
        "
        v-model="editingItem.usage_examples![index]"
        class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
        :placeholder="t('placeholder.usageExample')"
        rows="1"
        @input="
          (e: Event) => {
            const target = e.target as HTMLInputElement;
            updateMinTextareaHeight(target);
            emit('unsavedChanges');
          }
        "
      />
      <button @click="removeUsageExample(index)" class="text-red-500">
        <X :size="16" />
      </button>
    </div>
    <button @click="addUsageExample" class="text-blue-500 self-start mt-2">+ {{ t('button.addUsageExample') }}</button>
  </div>
</template>
