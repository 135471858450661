<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import TextEditor from '@/views/courses/TextEditor.vue';
import { getApiClient } from '@/apiclient/client';
import RemoveButton from '@/components/RemoveButton.vue';
import { useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['toggleFullWidth', 'removeMediaItem']);
const originalDescription = ref('');
const descriptionEditor = ref(null);
const delayingUpscale = ref(false);
const delayingDownscale = ref(false);
const container = ref(null);
const courseInteractionStore = useCourseInteractionStore();
const itemInteractionState = ref({});
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const fetchCompleted = ref(false);

const props = defineProps({
  contentItemId: {
    type: [String, null],
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: false,
    default: null,
  },
  showDescription: {
    type: Boolean,
    required: false,
    default: true,
  },
  maxOnClick: {
    type: Boolean,
    required: false,
    default: true,
  },
  isMaximized: {
    type: Boolean,
    required: false,
    default: false,
  },
  allowEdit: {
    type: Boolean,
    required: false,
    default: false,
  },
  normalizeHeight: {
    type: Boolean,
    required: false,
    default: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
});

onMounted(async () => {
  originalDescription.value = props.description;
  document.addEventListener('click', handleClickOutside);

  if (!props.allowEdit) {
    await fetchInteractionState();
    await markComplete(); // auto-complete once (potentially) viewed
  }
});

watch(
  () => fetchCompleted.value,
  async () => {
    await nextTick();
    await markComplete();
  },
);

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event) => {
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('toggleFullWidth');
  }
};

const toggleFullWidth = async () => {
  console.log('toggleFullWidth', props.maxOnClick, props.isMaximized, props.allowEdit);
  if (!props.maxOnClick) return;
  if (props.isMaximized && props.allowEdit) return; // dont toggle if editing
  emit('toggleFullWidth');
  return true;
};

async function stopPropagation(event) {
  // to prohibit propagation to parent cpt which directly untoggles the fullscreen
  await event.stopPropagation();
}

const allowEdit = computed(() => {
  return props.isMaximized && props.allowEdit;
});

watch(
  () => allowEdit.value,
  async (newVal) => {
    if (newVal) {
      console.log('editing description');
      await nextTick(() => {
        console.log('allowEdit nextTick');
        descriptionEditor.value.focus();
      });
    } else {
      if (!props.contentItemId) return; // not part of content item, cannot update TODO beautify this...
      console.log('editing ended');
      let newDescription = descriptionEditor.value.getRawTextContent();
      if (newDescription !== props.description) {
        console.log('description changed');
        await (
          await getApiClient()
        ).courseSectionItems.updateSectionMediaItemDescription(props.contentItemId, newDescription);
      }
      originalDescription.value = props.description;
    }
  },
);

watch(
  () => props.isMaximized,
  async (newVal) => {
    if (newVal) delayingUpscale.value = true;
    else delayingDownscale.value = true;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    delayingUpscale.value = false;
    delayingDownscale.value = false;
  },
);

const markComplete = async () => {
  if (!props.contentItemId) return;
  if (props.sectionIndex == null) return;
  if (props.pageIndex == null) return;
  courseInteractionStore
    .setSectionContentItemCompletedIfNotAlready(props.contentItemId, props.sectionIndex, props.pageIndex)
    .then(async (response) => {});
};

const fetchInteractionState = async () => {
  if (!props.contentItemId) return;
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(props.contentItemId);
  // create local copy - we want to defer updates to wait for animations etc
  if (!!interactionState && interactionState !== {}) {
    fetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
  }
};
</script>

<template>
  <!--      -->
  <div
    ref="container"
    @click="stopPropagation"
    class="group relative flex-col flex min-w-full dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
  >
    <div
      class="flex-col flex z-0 bg-red-100 relative overflow-auto w-full before:absolute before:inset-x-0 transition-all duration-1000 ease-in-out"
      @click.prevent="toggleFullWidth"
      :class="{
        'bg-gray-100': props.type === 'AUDIO',
        'h-56 rounded-lg': !props.isMaximized || (props.type === 'AUDIO' && props.normalizeHeight),
        'h-10 rounded-lg': props.type === 'AUDIO' && !props.normalizeHeight,
        'h-[600px] rounded-t-lg': props.isMaximized && delayingUpscale && props.type !== 'AUDIO',
        'h-auto rounded-t-lg': props.isMaximized && !delayingUpscale && props.type !== 'AUDIO',
      }"
    >
      <!-- before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1] -->
      <span class="w-[300px]"></span>
      <!-- why do we need this when edit on? -->
      <img
        v-if="props.type === 'IMAGE'"
        class="transition-transform duration-500 ease-in-out"
        :class="{
          'absolute top-0 start-0 object-cover w-full h-full': !props.isMaximized,
          'relative h-auto min-w-full': props.isMaximized,
        }"
        :src="props.url"
        alt="Media"
      />
      <video
        v-if="props.type === 'VIDEO'"
        class="w-full transition-transform duration-500 ease-in-out"
        :class="{
          'absolute top-0 start-0 object-cover h-full': !props.isMaximized,
          'relative h-auto': props.isMaximized,
        }"
        controlsList="nodownload"
        oncontextmenu="return false;"
        controls
      >
        <source :src="props.url" />
      </video>
      <audio
        v-if="props.type === 'AUDIO'"
        class="w-full absolute start-0 transition-transform duration-500 ease-in-out object-cover"
        :class="{
          'top-4': props.normalizeHeight,
          '-top-2': !props.normalizeHeight,
        }"
        controlsList="nodownload"
        oncontextmenu="return false;"
        controls
      >
        <source :src="props.url" />
      </audio>
      <RemoveButton v-if="props.allowEdit" @removeItem="emit('removeMediaItem')" />
    </div>

    <div
      v-show="
        props.showDescription &&
        ((props.description && props.description !== 'Klicken, um Beschreibung hinzuzufügen') || props.allowEdit)
      "
      class="rounded-b-lg"
      :class="{
        'absolute bottom-0 inset-x-0 z-10 bg-gray-900/[.4]  pt-2': !props.isMaximized || props.type === 'AUDIO',
        'flex h-fit bg-gray-900': props.isMaximized && props.type !== 'AUDIO',
        'pointer-events-none': !props.isMaximized,
        'z-10': allowEdit,
      }"
      @click.prevent="console.log('description clicked')"
    >
      <div class="flex flex-col h-full p-2 sm:p-4 pt-1 sm:pt-1">
        <div class="flex-row">
          <p class="mb-2 text-white/[.8]">
            <TextEditor
              ref="descriptionEditor"
              :content="props.description"
              :allowFormatting="false"
              :allowEdit="allowEdit"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
