<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import TextEditor from '@/views/courses/TextEditor.vue';
import { onBeforeUnmount, onMounted, ref, watch, nextTick } from 'vue';
import { debounce } from 'lodash';
import ChapterTableOfContents from '@/views/courses/ChapterTableOfContents.vue';
import Tooltip from '@/components/Tooltip.vue';
import Badge from '@/components/Badge.vue';
import { useAlertStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { formatDate, mapJobStatus, mapTitle } from '@/helper';
import { computed } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import { useI18n } from 'vue-i18n';
import MercuuBrandLogo from '@/components/MercuuBrandLogo.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import confetti from 'canvas-confetti';
import { router } from '@/router';
import { sanitizeContent } from '@/helper/stringArithmetics';
const { t } = useI18n();

const emit = defineEmits([
  'scrollTo',
  'toNextSection',
  'toPreviousSection',
  'toNextPage',
  'toPreviousPage',
  'toNextChapter',
  'onToggleIsEditing',
  'onAddPage',
  'onDeleteEmptySection',
]);
const titleEditor = ref(null);
const subtitleEditor = ref(null);
const learningObjectives = ref(null);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const leaveInvalidPageTimeout = ref(null);
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

const unsavedChanges = ref(false);
const isSavingChanges = ref(false);

const props = defineProps({
  allowEditing: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  chapter: {
    type: Object,
    required: true,
  },
  section: {
    type: Object,
    required: true,
  },
  numVisibleSections: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  numPages: {
    type: Number,
    required: true,
  },
  isDeleted: {
    type: Boolean,
    required: true,
  },
  fullWidth: {
    type: Number,
    required: true,
  },
});

const headerContainer = ref<HTMLElement | null>(null);
const fullHeight = ref(0);
const minHeight = ref(48);
const initialHeightMeasured = ref(false);
const isExpanded = ref(true);
const isFullExpanded = ref(true);
const HYSTERESIS = 10; // 10px buffer zone

const invalidPage = computed(() => {
  return props.pageIndex > props.numPages - 1 && props.numPages > 0;
});

const adjustHeight = async () => {
  await nextTick();
  if (headerContainer.value) {
    const height = headerContainer.value.offsetHeight;
    fullHeight.value = height + 16; // add 16px for the padding
    initialHeightMeasured.value = true;
  }
};

watch(
  () => [props.section?.learning_objectives, props.section?.title],
  async () => {
    await nextTick();
    adjustHeight();
  },
  { deep: true },
);

onMounted(async () => {
  await nextTick();
  setTimeout(adjustHeight, 100);
  setTimeout(adjustHeight, 500);
  window.addEventListener('resize', debounce(adjustHeight, 200));
  if (!props.section) {
    throw new Error('Section not found');
  }
  console.log('# learning objectives: ' + props.section.learning_objectives?.length);
  setLearningObjectives(props.section.learning_objectives);
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      console.log('onbeforeunload triggered saveTitleAndLearningObjectives');
      saveTitleAndLearningObjectives();
      return undefined;
    }
  };
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustHeight);
  if (unsavedChanges.value) {
    console.log('onBeforeUnmount triggered saveTitleAndLearningObjectives');
    saveTitleAndLearningObjectives();
  }
  if (props.allowEditing && !isPublished.value && !props.isDeleted) {
    alertStore.info(
      'Bitte beachten Sie, dass die Kurseinheit (Section) ' +
        (props.chapter?.index !== undefined ? props.chapter.index + 1 : '(undefined)') +
        '.' +
        (props.section?.index !== undefined ? props.section.index + 1 : '(undefined)') +
        ' noch nicht veröffentlicht ist. ' +
        'Für Ihre Schüler:innen ist sie daher noch nicht sichtbar!',
      'Kurseinheit (Section) ist verborgen',
    );
  }
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      console.log('onBeforeRouteLeave triggered saveTitleAndLearningObjectives');
      await saveTitleAndLearningObjectives();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const isPublished = computed(() => {
  return props.section?.published_at != null;
});

const isSectionComplete = computed(() => {
  let isComplete = true;
  for (let i = 0; i < props.numPages; i++) {
    if (!courseInteractionStore.pageCompletionStatus?.[props.section.id]?.[i]) {
      isComplete = false;
      break;
    }
  }
  return isComplete;
});

const isSectionStarted = computed(() => {
  return courseInteractionFetchCompleted.value && courseInteractionStore.pageCompletionStatus?.[props.section.id]?.[0];
});

const completionArray = courseInteractionStore.pageCompletionStatus?.[props.section.id] || {};
const finishedCount = Object.values(completionArray).filter((finished: boolean) => finished).length;
const isSectionMoreThanHalfComplete = computed(() => {
  return finishedCount > props.numPages / 2;
});

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  console.log('setLearningObjectives', sectionLearningObjectives);
  if (!sectionLearningObjectives || !Array.isArray(sectionLearningObjectives)) {
    console.warn('Invalid learning objectives data');
    return;
  }
  if (!learningObjectives.value) {
    console.warn('null ref in learningObjectives');
    return;
  }
  if (!props.section) {
    return;
  }
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  try {
    learningObjectives.value.setLearningObjectives(sectionLearningObjectives.slice(), false);
  } catch (error) {
    console.error('Failed to set learning objectives:', error);
  }
}

const publishSection = (publish: boolean) => {
  console.log('publishing: ' + publish);
  if (!props.section) {
    return;
  }
  courseStore
    .publishSection(props.section.id, publish)
    .then(() => {
      alertStore.success(
        publish
          ? 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteine sind jetzt für die Kursteilnehmer:innen sichtbar.'
          : 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt vor den Kursteilnehmer:innen verborgen.',
      );
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Veröffentlichen/ Verbergen: ' + error);
      throw Error('Error publishing section', error);
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.allowEditing || props.isDeleted) return;
      setTimeout(() => {
        console.log('watcher triggered saveTitleAndLearningObjectives');
        saveTitleAndLearningObjectives();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

const saveTitleAndLearningObjectives = async () => {
  console.log('saveTitleAndLearningObjectives');
  console.log('allowEditing', props.allowEditing);
  console.log('sectionId', props.section?.id);
  console.log('isDeleted', props.isDeleted);
  console.log('unsavedChanges', unsavedChanges.value);
  console.log('isSavingChanges', isSavingChanges.value);
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  if (!unsavedChanges.value || !props.allowEditing || !props.section?.id || props.isDeleted) return;
  if (!titleEditor.value?.getRawTextContent) {
    console.warn('Title editor not properly initialized');
    return;
  }
  const rawTitle = titleEditor.value.getRawTextContent();
  if (rawTitle === null || rawTitle === undefined) {
    console.warn('Title content is null or undefined');
    return;
  }
  if (!learningObjectives.value?.getLearningObjectives) {
    console.warn('Learning objectives not properly initialized');
    return;
  }
  console.log('saveTitleAndLearningObjectives - deleted? ' + props.isDeleted);
  console.log('learning objectives are: ' + JSON.stringify(learningObjectives.value.getLearningObjectives()));
  console.log('title is: ' + rawTitle);
  courseStore
    .updateSection(props.section.id, {
      title: sanitizeContent(rawTitle),
      learningObjectives: learningObjectives.value.getLearningObjectives(),
      publishedAt: props.section.published_at,
    })
    .then(() => {
      unsavedChanges.value = false;
      // alertStore.success('Titel und Lernziele gespeichert', 'Gespeichert');
      titleEditor.value?.resetEmitState();
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave title', 'Error', error);
      throw new Error('Failed to autosave title');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

const onDeleteSectionIfEmpty = async () => {
  if (props.numPages > 0) {
    alertStore.error(
      'Löschen nicht möglich, da noch Lernbausteine vorhanden sind. Bitte löschen Sie zuerst alle Lernbausteine.',
      'Unzulässig',
    );
    return;
  }
  emit('onDeleteEmptySection', props.section.id);
};

const screenIsMdOrLarger = computed(() => {
  return window.innerWidth > 768;
});

const isPageComplete = computed(() => {
  return !!courseInteractionStore.pageCompletionStatus?.[props.section.id]?.[props.pageIndex];
});

const isChapterComplete = computed(() => {
  return courseInteractionStore.chapterIsComplete(props.chapter.id);
});

const isCourseComplete = computed(() => {
  return courseInteractionStore.currentCourseIsComplete;
});

const showExpandedView = computed(() => {
  return props.isInView && props.allowToggleEditing;
});

const finishCourse = async () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 1500));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 2000));
  router.push('/courses');
  await new Promise((resolve) => setTimeout(resolve, 500));
  router.go(0);
};

watch(invalidPage, (newValue) => {
  if (newValue) {
    leaveInvalidPageTimeout.value = setTimeout(() => {
      emit('toPreviousPage');
    }, 1000);
  } else if (leaveInvalidPageTimeout.value) {
    clearTimeout(leaveInvalidPageTimeout.value);
    leaveInvalidPageTimeout.value = null;
  }
});

defineExpose({
  titleEditor,
  subtitleEditor,
  fullHeight,
  minHeight,
});
</script>

<template>
  <div
    ref="headerContainer"
    v-if="!isDeleted"
    class="w-full relative mx-auto overflow-visible transition-all duration-200 ease-out"
    :style="{
      height: 'auto',
    }"
  >
    <div
      class="mx-auto transition-[color] duration-300 flex space-y-5 md:space-y-8 h-fit dark:bg-neutral-900 dark:border-gray-700 shadow-sm rounded-xl border border-gray-200 bg-white pt-6 lg:pt-10 pb-4 lg:pb-10 px-4 sm:px-6 lg:px-8"
      :style="{ width: props.fullWidth + 'px' }"
    >
      <div class="w-full h-fit flex space-y-3 justify-center">
        <div class="w-full flex h-fit space-y-3 justify-center">
          <h2 class="w-full flex-col h-fit flex text-4xl text-center font-bold md:text-5xl dark:text-white">
            <div
              class="inline-flex w-full relative justify-between items-center text-base pb-6"
              v-show="props.allowToggleEditing && true"
            >
              <!-- Delete section -->
              <div>
                <button
                  v-show="props.allowEditing"
                  class="h-10 w-10 flex items-center text-center justify-center bg-red-600 hover:bg-red-700 shadow-md text-white rounded-[4px] text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium"
                  :class="{ 'opacity-50': numPages > 0 }"
                  @click.prevent="onDeleteSectionIfEmpty"
                >
                  <span translate="no" class="material-symbols-outlined notranslate text-3xl">delete</span>
                </button>
              </div>

              <!-- Publish -->
              <div v-show="props.allowEditing" class="inline-flex justify-end items-center">
                <div class="font-normal text-gray-500 dark:text-gray-200">
                  Status
                  <Tooltip
                    :placement-top="false"
                    message="Klicken zum Veröffentlichen des Kursabschnitts.
                            Veröffentlichte Abschnitte inkl. aller Lernbausteine sind für alle Nutzer:innen sichtbar, die
                            Zugriff auf den entsprechenden Kurs haben - für alle anderen natürlich nicht!"
                  />
                </div>
                <div class="group cursor-pointer" @click.prevent="publishSection(!isPublished)">
                  <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="!isPublished">
                    <Badge text="Unpublished draft" color="gray" :showDot="true" fontSize="text-sm" />
                  </div>
                  <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="!isPublished">
                    <Badge text="Publish draft" color="blue" :showDot="false" fontSize="text-sm" icon="publish" />
                  </div>
                  <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="isPublished">
                    <Badge text="Section is live" color="green" :showDot="true" :pulse="true" fontSize="text-sm" />
                  </div>
                  <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="isPublished">
                    <Badge
                      text="Hide section"
                      color="blue"
                      :showDot="false"
                      :pulse="true"
                      icon="hide_source"
                      fontSize="text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>

            <span
              v-show="props.isInView"
              class="w-full justify-between items-center inline-flex text-gray-300 font-bold text-3xl"
            >
              <span
                v-if="!!props.section && props.section.index > 0"
                @click="emit('toPreviousSection')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
              <span class="inline-flex" v-if="!!props.section && !!props.chapter">
                <span class="">{{ props.chapter?.index + 1 }}.{{ props.section?.index + 1 }}</span>
              </span>
              <span
                v-if="!!props.section && props.section.index < props.numVisibleSections - 1"
                @click="emit('toNextSection')"
                class="h-8 w-8 flex items-center justify-center text-center border rounded-lg cursor-pointer"
                :class="{
                  'bg-white border-gray-300 text-gray-300 hover:text-gray-500 hover:border-gray-500 hover:bg-gray-50':
                    !isSectionComplete || props.allowEditing,
                  'ring-pulse text-white bg-blue-600 hover:bg-blue-700': isSectionComplete && !props.allowEditing,
                }"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
            </span>
            <TextEditor
              v-if="!!props.section && props.section?.title !== null"
              v-show="true"
              ref="titleEditor"
              :content="props.section.title || ''"
              :allowFormatting="false"
              @unsavedChanges="unsavedChanges = true"
              @changesCleared="unsavedChanges = false"
              :allow-edit="props.allowEditing"
              editor-classes="pb-1"
              data-testid="section-title-editor"
            />

            <!-- Creator and interaction status-->
            <div
              class="mx-auto text-base w-full py-4 justify-between items-center transition-all duration-500 ease-in-out"
            >
              <div class="w-full sm:items-center gap-x-5 sm:gap-x-3">
                <div class="grow">
                  <div class="flex justify-between items-center gap-x-2">
                    <!-- Button Group -->
                    <div class="inline-flex items-center cursor-default">
                      <div
                        class="group py-1.5 px-0.5 md:px-2.5 inline-flex items-center gap-x-0.5 md:gap-x-2 text-xs md:text-sm font-medium rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white"
                        :disabled="!courseInteractionFetchCompleted"
                        :class="{
                          'border-gray-200 text-gray-200': !isSectionComplete && !isSectionStarted,
                          'border-gray-800 text-gray-800 dark:border-gray-700 bg-white dark:bg-neutral-900':
                            !isSectionComplete && isSectionStarted,
                          'text-teal-500 bg-teal-200/10 border-gray-200 border-2 dark:border-teal-700 dark:bg-teal-900 ':
                            isSectionComplete,
                        }"
                      >
                        <span
                          v-show="!isSectionComplete && !isSectionStarted"
                          class="text-gray-200 material-symbols-outlined"
                        >
                          circle
                        </span>
                        <span
                          v-show="!isSectionComplete && isSectionStarted"
                          translate="no"
                          class="material-symbols-outlined notranslate"
                        >
                          {{ isSectionMoreThanHalfComplete ? 'clock_loader_60' : 'clock_loader_40' }}
                        </span>
                        <span v-show="isSectionComplete" translate="no" class="material-symbols-outlined notranslate">
                          task_alt
                        </span>
                        <span class="block">
                          {{
                            isSectionStarted
                              ? isSectionComplete
                                ? $t('message.completed')
                                : $t('message.inProgress')
                              : $t('message.notStarted')
                          }}
                        </span>
                      </div>
                    </div>
                    <!-- End Button Group -->

                    <div class="flex justify-between items-center gap-x-2">
                      <div class="flex flex-col items-start">
                        <!-- Tooltip -->
                        <div class="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                          <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                            <span>
                              <span class="text-xs text-gray-400"> {{ $t('message.by') }} &nbsp; </span>
                              <span v-if="!!section.user" class="font-semibold text-gray-800 dark:text-gray-200">
                                {{ section.user.academic_title ? mapTitle(section.user.academic_title) + ' ' : ''
                                }}{{ section.user.first_name }}
                                {{ section.user.last_name }}
                              </span>
                            </span>
                            <div class="text-xs text-gray-400" v-if="!!section.user">
                              {{ section.user.job_status ? mapJobStatus(section.user.job_status) : '' }}
                            </div>
                          </div>
                        </div>
                        <!-- End Tooltip -->
                        <ul class="text-xs text-gray-400">
                          <li
                            class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600"
                          >
                            {{ formatDate(section.created_at) }}
                          </li>
                        </ul>
                      </div>
                      <div class="flex-shrink-0">
                        <ProfileImage
                          v-if="!!section.user"
                          :image="section.user.userProfileImageSmall"
                          initials=""
                          size="4rem"
                          :showIngameLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Creator and interaction status -->

            <p
              class="w-full pt-8 text-xs md:text-sm text-justify text-gray-800 dark:text-gray-200 font-semibold mb-3"
              v-show="true"
              data-testid="somewhere-to-click-without-consequences-title-page"
            >
              Lernziele
            </p>
            <div class="w-full space-y-3 text-gray-500 font-normal text-left" v-show="true">
              <LearningObjectives
                ref="learningObjectives"
                :maxNumber="5"
                :editable="props.allowEditing"
                @requestCompute="emit('onExtractLearningObjectives')"
                @change="unsavedChanges = true"
              />
            </div>

            <span
              v-if="isChapterComplete"
              class="w-full flex justify-center items-center inline-flex text-base text-gray-300"
            >
              <span
                v-if="props.chapter.index + 1 < courseStore?.currentCourse?.chapters?.length"
                @click="emit('toNextChapter')"
                class="h-8 mx-auto px-3 py-2 my-4 inline-flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 hover:bg-blue-700"
              >
                🏁&nbsp;&nbsp; Zum nächsten Kapitel &nbsp;🥳
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span
                v-else-if="isCourseComplete && props.chapter.index + 1 === courseStore?.currentCourse?.chapters?.length"
                @click="finishCourse"
                class="h-8 mx-auto px-3 py-2 my-4 inline-flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 hover:bg-blue-700"
              >
                🏁&nbsp;&nbsp; Kurs abschließen &nbsp;🥳&nbsp;🥳&nbsp;🥳
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
            </span>

            <span
              class="justify-between items-center inline-flex text-gray-300"
              :class="{
                'w-full pt-8 text-2xl md:text-3xl font-bold': true,
                'w-fit gap-x-2 pt-0 text-lg font-normal': false,
              }"
            >
              <span
                v-if="props.pageIndex > 0"
                @click="emit('toPreviousPage')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="w-[36px]"></span>

              <span
                class="inline-flex items-center"
                :class="{
                  'py-0': true,
                  'py-2': false,
                }"
              >
                <span v-if="!!invalidPage" class="inline-flex">
                  <span
                    class="animate-spin inline-block w-8 h-8 border-e[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full mr-6"
                  />
                </span>
              </span>

              <span
                v-if="props.pageIndex < props.numPages - 1"
                data-testid="next-page-button"
                @click="emit('toNextPage')"
                class="h-8 w-8 flex items-center justify-center text-center border rounded-lg cursor-pointer"
                :class="{
                  'text-gray-300 hover:text-gray-500 hover:bg-gray-50 border-gray-300 hover:border-gray-500':
                    !isPageComplete || props.allowEditing,
                  'ring-pulse text-white bg-blue-600 hover:bg-blue-700': isPageComplete && !props.allowEditing,
                }"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes ring-pulse {
  0% {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0);
  }
  70% {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.7);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.2);
  }
}

.ring-pulse {
  animation: ring-pulse 2s infinite;
}

.ring-pulse:hover {
  animation: none;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 1);
}
</style>
