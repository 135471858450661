<!--

NOTE: this was formerly "TwoColumnLayout.vue"

Layout of main part of main page:
- Three columns, individually scrollable
- Lab (left) and examination reports (right) can be expanded or collapsed
- Large screen: all expanded by default
- Table: only middle column = chat expanded. Only either (lab) or right (exm) can be expanded, the other
-   automatically collapses if done so.
- Mobile: only one of the three columns can be expanded at a time, the other two collapse automatically

Remaining TODO s:
- behaviour (e.g. max num of visible columns) should depend on screen size desktop (3) / tablet (2) / mobile (1)
-->

<script setup lang="ts">
import CaseInteractionHeader from '@/components/case_interaction/CaseInteractionHeader.vue';
// import CaseVocabModal from '@/components/case_interaction/CaseVocabModal.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import InteractionInput from '@/components/case_interaction/InteractionInput.vue';
import ActionColumn from '@/components/interaction_columns/ActionColumn.vue';
import ChatHistory from '@/components/case_interaction/ChatHistory.vue';
import InteractionColumn from '@/components/case_interaction/InteractionColumn.vue';
import { useAuthStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import MockDesktop from '@/views/clinical-information-system/MockDesktop.vue';
import PatientView from '@/views/clinical-information-system/PatientView.vue';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { HSOverlay } from 'preline';
import { computed, nextTick, onMounted, onBeforeUnmount, ref, onBeforeMount, watch } from 'vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const interactionColumn = ref<InstanceType<typeof InteractionColumn> | null>(null);

const emit = defineEmits(['startReport', 'trySolve']);

import { defineProps } from 'vue';

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  originChapterId: {
    type: String,
    required: false,
    default: '',
  }, // if case started from any section of a chapter: used to route back to the origin chapter after evaluation
  isLoadingInteractionHistory: {
    type: Boolean,
    required: true,
  },
  showingMainCaseView: {
    type: Boolean,
    required: true,
  },
});

const {
  chatIsStreaming: patientChatIsStreaming,
  labIsStreaming,
  examinationIsStreaming,
  descIsStreaming,
} = storeToRefs(patientInteractionStore);
const { anyChatIsStreaming: anyThirdPersonChatIsStreaming } = storeToRefs(thirdPersonInteractionStore);
const { somthingIsStreaming } = storeToRefs(caseInteractionStore);
const { currentSubtitlesEnabled } = storeToRefs(authStore);

const mockDesktopHandle = ref(null);

const inputText = ref('');
const lastInputAt = ref('');
const showSubtitles = ref(currentSubtitlesEnabled);
const selectedPerson = ref(null);
const playingAudio = ref(false);
const caseInteractionHeader = ref(null);
const caseInteractionFooter = ref(null);
const showCompanionChat = ref(false);

const interactionHeaderHeight = ref(0);
const interactionFooterHeight = ref(0);

const recheckingHeight = ref(0);

const onStartReport = () => emit('startReport');
const onTrySolve = () => emit('trySolve');
const id = 'TwoColumnLayout';

async function showHistory(show) {
  console.log('showHistory: ', show);
  try {
    if (show) {
      console.log('opening history offcanvas');
      await HSOverlay.open('#hs-overlay-chat-history');
      console.log('opened history offcanvas');
      return;
    }
    console.log('closing history offcanvas');
    await HSOverlay.close('#hs-overlay-chat-history');
    console.log('closed history offcanvas');
  } catch (e) {
    // ignore, do nothing if id not found = not mounted
  }
}

async function showActionColumn(show) {
  console.log('showActionColumn: ', show);
  try {
    if (show) {
      console.log('opening action offcanvas');
      await HSOverlay.open('#hs-overlay-action-column');
      console.log('opened action offcanvas');
      return;
    }
    console.log('closing action offcanvas');
    await HSOverlay.close('#hs-overlay-action-column');
    console.log('closed action offcanvas');
  } catch (e) {
    // ignore, do nothing if id not found = not mounted
  }
}

function onHandleChatSubmit(payload) {
  lastInputAt.value = new Date().toISOString();
  inputText.value = payload;
}

const inputPlaceholderMessage = computed(() => {
  if (selectedPerson.value && !!selectedPerson.value.placeholderMessage) {
    return selectedPerson.value.placeholderMessage;
  }
  return 'Etwas sagen...';
});

function onPersonSelected(person) {
  console.log('Two column layout received person: ', person);
  selectedPerson.value = person;
}

function onPlayingAudio(playing) {
  console.log('Two column layout received playing audio: ', playing);
  playingAudio.value = playing;
}

const someActionIsStreaming = computed(() => {
  return labIsStreaming.value || examinationIsStreaming.value || descIsStreaming.value;
});

const translateVocabHandle = ref(null);
const explainVocabHandle = ref(null);

async function onTranslateVocab() {
  console.debug('vocab translation test started');

  const test = await translateVocabHandle.value.pauseForMetaChat();
  console.debug('vocab translation test closed with answer ' + test);
}

async function onExplainVocab() {
  console.debug('vocab/ expert term definition test started');

  const test = await explainVocabHandle.value.pauseForMetaChat();
  console.debug('test closed with answer ' + test);
}

async function openMockDesktop() {
  await mockDesktopHandle.value.pauseForModal();
}

async function closeMockDesktop() {
  await mockDesktopHandle.value.close();
}

const interactionColumnHeight = computed(() => {
  return `calc(var(--fallback-viewport-height, 100vh)
  - ${props.outerHeaderHeight}px
  - ${interactionHeaderHeight.value}px
  - ${interactionFooterHeight.value}px)`;
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

const adjustHeights = async () => {
  await nextTick();
  console.log('adjustHeights: caseInteractionFooter', caseInteractionFooter.value);
  if (caseInteractionHeader.value) {
    interactionHeaderHeight.value = caseInteractionHeader.value.offsetHeight;
  }
  if (caseInteractionFooter.value) {
    interactionFooterHeight.value = caseInteractionFooter.value.offsetHeight;
  }
};

const debouncedAdjustHeights = debounce(adjustHeights, 200);

watch(caseInteractionFooter.value, () => {
  debouncedAdjustHeights();
});

watch(caseInteractionHeader.value, () => {
  debouncedAdjustHeights();
});

onBeforeMount(() => {
  console.log('onBeforeMount TwoColumnLayout');
});

onMounted(async () => {
  console.log('onMounted TwoColumnLayout');
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  window.addEventListener('resize', debouncedAdjustHeights);
  await adjustHeights();

  if (props.showingMainCaseView) {
    await unlockAudioContext();
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeights);
});

watch(
  () => props.showingMainCaseView,
  async () => {
    if (!props.showingMainCaseView) return;
    await nextTick();
    await adjustHeights();
  },
);

watch(
  () => props.isLoadingInteractionHistory,
  () => {
    console.log('TwoColumnLayout: isLoadingInteractionHistory changed to: ', props.isLoadingInteractionHistory);
    adjustHeights();
  },
);

const unlockAudioContext = async () => {
  console.log('Unlocking audio context in TwoColumnLayout...');
  if (interactionColumn.value) {
    return await interactionColumn.value.unlockAudioContext();
  }
  return false;
};

const lastMessageIfAny = computed(() => {
  if (caseInteractionStore.selectedPersonsLastChatMessage) {
    return ', ' + caseInteractionStore.selectedPersonsLastChatMessage.content.processed_model_output;
  }
  return '';
});

const currentTtsContext = computed(() => {
  return caseInteractionStore.selectedPersonsName + ', ' + caseInteractionStore.studentRole + lastMessageIfAny.value;
});

defineExpose({
  unlockAudioContext,
});
</script>

<template>
  <!-- case interaction header with stepper_items -->
  <div class="flex flex-col w-full overflow-hidden" :style="computedStyleMain">
    <!-- note: h-full not working here. h-screen does, but is too large due to outer header => calc -->
    <header ref="caseInteractionHeader" class="flex-none" data-testid="case-interaction-header">
      <CaseInteractionHeader
        @onTranslateVocab="onTranslateVocab"
        @onExplainVocab="onExplainVocab"
        :originChapterId="props.originChapterId"
      />
    </header>

    <!-- main content -->
    <main class="flex-grow overflow-auto">
      <div class="grid overflow-hidden grid-cols-3 md:grid-cols-4 lg:gap-8 gap-2 shadow-gray-200 h-full w-full">
        <div class="col-start-1 hidden sm:block max-h-full overflow-hidden">
          <ActionColumn
            id="actionAsColumn"
            @startReport="onStartReport"
            @trySolve="onTrySolve"
            :showDesc="true"
            :showLab="false"
            :showExaminations="false"
          />
        </div>
        <div class="col-start-1 md:col-start-2 col-span-3 md:col-span-3">
          <InteractionColumn
            ref="interactionColumn"
            :inputText="inputText"
            :lastInputAt="lastInputAt"
            :showSubtitles="showSubtitles"
            :totalHeight="interactionColumnHeight"
            @personSelected="onPersonSelected"
            @companionChatClosed="showCompanionChat = false"
            :showCompanionChat="showCompanionChat"
          />
        </div>
      </div>
    </main>
    <!-- end of main content -->

    <!-- footer -->
    <footer
      ref="caseInteractionFooter"
      class="flex-none pl-0 sm:pl-2 bg-white lg:gap-8 gap-2 flex items-center"
      data-testid="case-interaction-footer"
    >
      <div class="hidden sm:w-1/4 sm:flex flex-col">
        <div class="hidden sm:flex flex-shrink rounded-lg">
          <!--        <ButtonWithTextareaTooltip-->
          <!--          :id="id + 'LabOrder'"-->
          <!--          :disabled="someActionIsStreaming"-->
          <!--          :isLoading="labIsStreaming"-->
          <!--          placeholderMessage="Laborwerte anfordern"-->
          <!--          @onSubmit="onHandleLabSubmit"-->
          <!--          label="Labor"-->
          <!--          icon="lab_research"-->
          <!--        />-->
          <!--        <ButtonWithTextareaTooltip-->
          <!--          :id="id + 'ExmOrder'"-->
          <!--          :disabled="someActionIsStreaming"-->
          <!--          :isLoading="examinationIsStreaming"-->
          <!--          placeholderMessage="Untersuchung anfordern"-->
          <!--          @onSubmit="onHandleExaminationSubmit"-->
          <!--          label="Diagnostik"-->
          <!--          labelAbbreviation="Dx"-->
          <!--          icon="radiology"-->
          <!--        />-->

          <ButtonToggle
            :id="id + 'KIS'"
            label="KIS-PC"
            labelAbbreviation="KIS"
            icon="desktop_windows"
            @onToggle="openMockDesktop"
          />
          <!--          <ButtonWithActionButtonTooltip-->
          <!--            :id="id + 'Action'"-->
          <!--            :disabled="someActionIsStreaming"-->
          <!--            :isLoading="descIsStreaming"-->
          <!--            @startReport="onStartReport"-->
          <!--            @trySolve="onTrySolve"-->
          <!--            @onSubmit=""-->
          <!--            label="Aktion"-->
          <!--            icon="pill"-->
          <!--          />-->
          <!-- <ButtonWithQuizButtonTooltip
            :id="id + 'Quiz'"
            :disabled="someActionIsStreaming"
            :isLoading="descIsStreaming"
            @onTranslateVocab="onTranslateVocab"
            @onExplainVocab="onExplainVocab"
            @onSubmit=""
            label="Vokabel-trainer"
            labelAbbreviation="Vokabel"
            icon="quiz"
          /> -->
        </div>
      </div>
      <div class="w-full z-[90] sm:w-3/4 relative flex h-fit flex-col">
        <InteractionInput
          :placeholderMessage="inputPlaceholderMessage"
          :disabled="somthingIsStreaming"
          @onSubmit="onHandleChatSubmit"
          @onShowHistory="showHistory"
          @onShowActionColumn="showActionColumn"
          @onToggleMockDesktop="openMockDesktop"
          @onShowCompanionChat="showCompanionChat = $event"
          :isShowingCompanionChat="showCompanionChat"
          :allowAudioInput="true"
          :unlockAudioContext="interactionColumn?.unlockAudioContext"
          :ttsContext="currentTtsContext"
        />
      </div>
    </footer>
  </div>
  <!-- end of footer -->

  <!-- chat history offcanvas -->
  <div
    id="hs-overlay-chat-history"
    class="hs-overlay hs-overlay-open:flex flex-col-reverse overflow-y-auto grow hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform h-full max-w-md w-full z-[80] bg-gray-50 border-e dark:bg-neutral-800 dark:border-neutral-700 [--body-scroll:true] [--overlay-backdrop:false]"
    tabindex="-1"
  >
    <div class="translate-z-0">
      <div class="p-4 flex-col bg-gray-50">
        <div class="text-gray-800 dark:text-neutral-400 flex-col">
          <div class="relative flex flex-col">
            <ChatHistory :selectedPerson="selectedPerson" @playingAudio="onPlayingAudio" />
          </div>
        </div>
      </div>
      <div
        class="flex grow min-h-[180px] justify-between items-center py-3 px-4 border-b dark:border-neutral-700 bg-gray-50 sticky bottom-0"
      >
        <h3 class="font-bold text-gray-800 dark:text-white"></h3>
        <button
          type="button"
          class="flex justify-center items-center h-7 w-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
          data-hs-overlay="#hs-overlay-chat-history"
        >
          <span class="sr-only">Close modal</span>
        </button>
      </div>
    </div>
  </div>
  <!-- end of chat history offcanvas -->

  <!-- action column offcanvas -->
  <div
    id="hs-overlay-action-column"
    class="hs-overlay flex-col-reverse overflow-y-auto grow hs-overlay-open:translate-x-0 hidden -translate-x-full fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs sm:max-w-0 w-full z-[80] bg-gray-50 border-e dark:bg-neutral-800 dark:border-neutral-700 [--body-scroll:true] [--overlay-backdrop:false]"
    tabindex="-1"
  >
    <div class="translate-z-0">
      <div class="p-4 flex-col bg-gray-50">
        <div class="text-gray-800 dark:text-neutral-400 flex-col">
          <div class="relative flex flex-col">
            <ActionColumn
              id="actionAsOffcanvas"
              @startReport="onStartReport"
              @trySolve="onTrySolve"
              :showDesc="true"
              :showLab="false"
              :showExaminations="false"
            />
          </div>
        </div>
      </div>
      <div
        class="flex grow min-h-[180px] justify-between items-center py-3 px-4 border-b dark:border-neutral-700 bg-gray-50 sticky bottom-0"
      >
        <h3 class="font-bold text-gray-800 dark:text-white"></h3>
        <button
          type="button"
          class="flex justify-center items-center h-7 w-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
          data-hs-overlay="#hs-overlay-action-column"
        >
          <span class="sr-only">Close modal</span>
        </button>
      </div>
    </div>
  </div>
  <!-- end of action column offcanvas -->

  <MockDesktop ref="mockDesktopHandle" overlayId="mockDesktop">
    <PatientView @logout="closeMockDesktop" @trySolve="onTrySolve" />
  </MockDesktop>

  <!-- Do not remove - will use this later -->
  <!--  <MetaChatModal-->
  <!--      v-if="!!oralExamInteractionStore.currentExaminer"-->
  <!--      ref="oralExamHandle"-->
  <!--      heading="Mündliches Prüfungsgespräch"-->
  <!--      overlayId="oral-modal"-->
  <!--  >-->
  <!--    <OralExamChatColumn />-->
  <!--  </MetaChatModal>-->

  <!-- <CaseVocabModal
    ref="translateVocabHandle"
    overlayId="translate-vocab-modal"
    :showSolutions="false"
    :mode="VocabMode.TRANSLATE"
  />
  <CaseVocabModal
    ref="explainVocabHandle"
    overlayId="explain-vocab-modal"
    :showSolutions="false"
    :mode="VocabMode.EXPLAIN"
    :nVocabs="5"
  /> -->
</template>

<style></style>
