export const displayGrammaticalCategory = (category: string) => {
  if (category === 'NOUN') {
    return 'Substantiv';
  }
  if (category === 'VERB') {
    return 'Verb';
  }
  if (category === 'ADJECTIVE') {
    return 'Adjektiv';
  }
  if (category === 'ADVERB') {
    return 'Adverb';
  }
  if (category === 'PRONOUN') {
    return 'Pronomen';
  }
  if (category === 'PREPOSITION') {
    return 'Präposition';
  }
  if (category === 'CONJUNCTION') {
    return 'Konjunktion';
  }
  if (category === 'INTERJECTION') {
    return 'Interjektion';
  }
  if (category === 'UNKNOWN') {
    return 'Nicht klassifiziert';
  }
  return category;
};
