<script setup lang="ts">
import ClozeEditor from '@/components/didactics/cloze/ClozeEditor.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { watch } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { updateMinTextareaHeight } from '@/helper';
import TextEditor from '@/views/courses/TextEditor.vue';
import DOMPurify from 'dompurify';
import ProgressButton from '@/components/ProgressButton.vue';
import McQuestion from '@/components/didactics/resource_with_questions/McQuestion.vue';
import OpenQuestion from '@/components/didactics/resource_with_questions/OpenQuestion.vue';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import ExerciseMediaUploadCard from '@/components/didactics/pair_of_terms/ExerciseMediaUploadCard.vue';
import { onBeforeRouteLeave } from 'vue-router';
import CreateListeningDialog from '@/components/didactics/listening_dialog/create/CreateListeningDialog.vue';
import DialogHistory from '@/components/didactics/listening_dialog/history/DialogHistory.vue';
import { ResourceType } from '@/helper/typing';

const { t } = useI18n();

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['scrollBy', 'undoLastScroll']);

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const courseStore = useCourseStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

const localResource = ref(props.contentItem.resource_with_questions);
const dialog = ref(null);

const isCollectionWithoutResource = ref(false);
const unsavedChanges = ref(false);
const interactionStarted = ref(false);
const interactionFetchCompleted = ref(false);
const keepQuestionsMounted = ref(true);
const itemInteractionState = ref({});
const isSavingChanges = ref(false);
const questionIsSolved = reactive({});
const dialogIsFetched = ref(false);
const hasDialog = ref(false);
const createListeningDialogModal = ref(null);
const dialogUpdateTimestamp = ref(new Date().toISOString());
const showMediaFullWidth = ref(false);
onMounted(async () => {
  await nextTick();
  if (props.isEditing) interactionFetchCompleted.value = true; // no need to wait for interaction fetch
  await fetchInteractionState();
  mcQuestions.value = props.contentItem.resource_with_questions?.mc_questions ?? [];
  openQuestions.value = props.contentItem.resource_with_questions?.open_questions ?? [];
  isCollectionWithoutResource.value =
    props.contentItem.resource_with_questions?.resource_type === ResourceType.NO_RESOURCE;
  hasDialog.value = !!props.contentItem.resource_with_questions?.resource_dialog_id;

  // Simplified beforeunload handler just for browser/tab closes
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveResource();
      return undefined;
    }
  };

  if (props.contentItem.resource_with_questions?.resource_dialog_id) {
    dialog.value = await (
      await getApiClient()
    ).dialogs.getDialog(props.contentItem.resource_with_questions.resource_dialog_id, props.contentItem.id);
    dialogIsFetched.value = true;
  }
});

watch(
  () => dialogUpdateTimestamp.value,
  async () => {
    if (dialog.value) {
      dialogIsFetched.value = false;
      dialog.value = await (
        await getApiClient()
      ).dialogs.getDialog(props.contentItem.resource_with_questions.resource_dialog_id, props.contentItem.id);
      dialogIsFetched.value = true;
    }
  },
);

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveResource();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

onBeforeUnmount(async () => {
  // onBeforeUnmount is not triggered on page reloads/hard refreshes
  // For that case, we need to use window.onbeforeunload
  console.log('onBeforeUnmount - unsavedChanges: ', unsavedChanges.value);
  if (unsavedChanges.value) {
    await saveResource();
  }
});

const allQuestionsSolved = computed(() => {
  return Object.values(questionIsSolved).every((value) => value === true);
});

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(props.contentItem.id);
  // create local copy - we want to defer updates to wait for animations etc
  if (!!interactionState && interactionState !== {}) {
    interactionFetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
  }
};

const saveResource = async () => {
  console.log('saveResource');
  console.log('media item id', localResource.value.resource_media_item_id);
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  if (!props.isEditing) return;
  // TODO: why do we not do this via the course store??? => CAVE potential inconsistency with local storage!
  await (
    await getApiClient()
  ).courseSectionItems
    .updateResourceWithQuestionsItem(props.contentItem.id, {
      title: localResource.value.title,
      task_instructions: localResource.value.task_instructions,
      resource_text: localResource.value.resource_text,
      show_resource_text: localResource.value.show_resource_text,
      resource_media_item_id: localResource.value.resource_media_item_id,
      resource_dialog_id: localResource.value.resource_dialog_id,
      resource_type: localResource.value.resource_type,
    })
    .then(() => {
      unsavedChanges.value = false;
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave resource', 'Error', error);
      throw new Error('Failed to autosave resource');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      setTimeout(() => {
        saveResource();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

const startInteraction = async () => {
  if (interactionStarted.value) return;
  if (!courseInteractionFetchCompleted.value) return;
  courseInteractionStore.startSectionContentItemIfNotAlready(props.contentItem.id).then((response) => {
    if (!response) return; // nothing has happened as the item was already started
    itemInteractionState.value = response.section_content_item_interaction;
    interactionStarted.value = true;
  });
};

watch(
  () => allQuestionsSolved.value,
  (newValue) => {
    console.log('allQuestionsSolved', newValue);
    if (newValue) {
      courseInteractionStore
        .setSectionContentItemCompletedIfNotAlready(props.contentItem.id, props.sectionIndex, props.pageIndex, t, true)
        .then(async (response) => {
          console.log(response);
          if (!response) return;
          response.notifications?.forEach((notification) => {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          });
          itemInteractionState.value = response.section_content_item_interaction;
          await authStore.fetchUserXp();
        });
    }
  },
);

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    if (!props.isEditing) {
      await nextTick();
      await fetchInteractionState();
    }
  },
  { immediate: true },
);

// const hideSolutions = () => {
//   Object.keys(nodeIsSolved).forEach((nodeId) => {
//     nodeIsSolved[nodeId] = false;
//   });
//   if (!clozeEditor.value) return;
//   clozeEditor.value.resetAllAttempts();
// };

const reopenExercise = async () => {
  // hideSolutions();
  keepQuestionsMounted.value = false;
  courseInteractionStore.reopenSectionContentItem(props.contentItem.id).then((response) => {
    console.log(response);
    if (!response) return; // nothing has happened as the item was already started
    itemInteractionState.value = response;
  });
  await nextTick();
  keepQuestionsMounted.value = true;
};

const sanitizedHtml = (html: string) => {
  return DOMPurify.sanitize(html);
};

const mcQuestions = ref([]);
const openQuestions = ref([]);

const questions = computed(() => {
  let mcWithType = mcQuestions.value.map((question) => ({
    ...question,
    type: 'mc', // Adding type as "mc" for multiple-choice questions
  }));

  let openWithType = openQuestions.value.map((question) => ({
    ...question,
    type: 'open', // Adding type as "open" for open-ended questions
  }));

  let q = [...mcWithType, ...openWithType];
  return q.sort((a, b) => a.index - b.index);
});

const removeQuestion = async (id: string) => {
  // check if in McQuestions
  let index = mcQuestions.value.findIndex((q) => q.id === id);
  if (index !== -1) {
    (await getApiClient()).courseSectionItems
      .deleteMcQuestionFromItemWithResource(props.contentItem.id, id)
      .then(() => {
        mcQuestions.value.splice(index, 1);
      })
      .catch((error) => {
        console.error(error);
        alertStore.error('Failed to remove MC question', 'Error', error);
        throw new Error('Failed to remove MC question');
      });
    return;
  }
  // else delete from open questions
  (await getApiClient()).courseSectionItems
    .deleteOpenQuestionFromItemWithResource(props.contentItem.id, id)
    .then(() => {
      openQuestions.value = openQuestions.value.filter((q) => q.id !== id);
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to remove open question', 'Error', error);
      throw new Error('Failed to remove open question');
    });
};

const addMcQuestion = async () => {
  (await getApiClient()).courseSectionItems
    .addMcQuestionToContentItemWithResource(props.contentItem.id, {})
    .then((newMcQuestion) => {
      mcQuestions.value.push({
        id: newMcQuestion.id,
        question_text: !!newMcQuestion.question_text ? newMcQuestion.question_text : '',
        answers: !!newMcQuestion.answers ? newMcQuestion.answers : [],
        index: mcQuestions.value.length + openQuestions.value.length,
      });
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to add MC question', 'Error', error);
      throw new Error('Failed to add MC question');
    });
};

const addOpenQuestion = async () => {
  (await getApiClient()).courseSectionItems
    .addOpenQuestionToContentItemWithResource(props.contentItem.id, {})
    .then((newMcQuestion) => {
      openQuestions.value.push({
        id: newMcQuestion.id,
        question_text: !!newMcQuestion.question_text ? newMcQuestion.question_text : '',
        sample_answer: !!newMcQuestion.sample_answer ? newMcQuestion.sample_answer : '',
        index: mcQuestions.value.length + openQuestions.value.length,
      });
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to add open question', 'Error', error);
      throw new Error('Failed to add open question');
    });
};
</script>

<template>
  <div class="min-w-full pt-0.5 px-4 pb-1 min-h-48 relative text-gray-800 dark:text-gray-200">
    <div
      v-if="!!localResource"
      :class="{
        'opacity-25':
          !props.isEditing &&
          (!interactionFetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <!-- title -->
      <h3 v-if="props.isEditing || localResource.title !== ''" class="text text-center px-6">
        <textarea
          v-if="props.isEditing"
          v-model="localResource.title"
          class="w-full px-2 py-0 mb-2 resize-none border-gray-200 rounded-lg text-center"
          placeholder="Titel (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
        <span v-else class="text-gray-800 dark:text-neutral-200 font-semibold">
          {{ localResource.title }}
        </span>
      </h3>

      <!-- task instructions -->
      <h3 v-if="props.isEditing || localResource.task_instructions !== ''" class="pt-2 pb-1 px-4 w-full text-center">
        <span v-if="!props.isEditing" class="text-xs md:text-sm font-semibold">{{
          localResource.task_instructions
        }}</span>
        <textarea
          v-if="props.isEditing"
          v-model="localResource.task_instructions"
          class="w-full text-xs md:text-sm px-2 py-0 mb-2 resize-none border-gray-200 rounded-lg text-center"
          placeholder="Erläuterungen zur Aufgabenstellung (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
      </h3>

      <!-- reading or listening resource -->
      <div
        v-if="localResource.resource_type !== ResourceType.NO_RESOURCE"
        class="text-justify text-xs md:text-sm"
        :class="{
          'pb-2': props.isEditing,
          'pb-4': !props.isEditing,
        }"
      >
        <!-- Toggle listening vs reading comprehension-->
        <div class="flex items-center w-full justify-center pb-4" v-if="props.isEditing">
          <label
            for="hs-basic-with-description-reading-listening-comprehension-toggl"
            class="text-sm text-lime-500 me-3 dark:text-neutral-400 inline-flex items-center gap-x-2"
          >
            <span class="flex flex-col">
              <span class="text-center">Leseverständnis</span>
              <span class="text-center text-xs">Einen Text bereitstellen</span>
            </span>
            <span translate="no" class="material-symbols-outlined no-translate"> visibility </span>
          </label>
          <input
            type="checkbox"
            id="hs-basic-with-description-reading-listening-comprehension-toggl"
            class="relative w-[3.25rem] h-7 p-px bg-lime-100 border border-lime-400 checked:border-purple-400 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-lime-600 focus:checked:ring-purple-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-purple-600 checked:border-purple-600 focus:checked:border-purple-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-purple-500 dark:checked:border-purple-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-whitebe before:bg-lime-400 checked:before:bg-purple-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-purple-200"
            :checked="localResource.resource_type === ResourceType.LISTENING"
            @change="
              (e) => {
                localResource.resource_type = e.target.checked ? ResourceType.LISTENING : ResourceType.READING;
                unsavedChanges = true;
              }
            "
          />
          <label
            for="hs-basic-with-description-reading-listening-comprehension-toggl"
            class="text-sm text-purple-500 ms-3 dark:text-neutral-400 inline-flex items-center gap-x-2"
          >
            <span translate="no" class="material-symbols-outlined no-translate"> hearing </span>
            <span class="flex flex-col">
              <span class="text-center">Hörverstehen</span>
              <span class="text-center text-xs">Audio-Dialog anlegen</span>
              <span class="text-center text-xs font-bold">EXPERIMENTAL FEATURE</span>
            </span>
          </label>
        </div>

        <TextEditor
          v-if="
            ((props.isEditing && !isCollectionWithoutResource) ||
              (localResource.show_resource_text && localResource.resource_text !== '')) &&
            localResource.resource_type === ResourceType.READING
          "
          :content="sanitizedHtml(localResource.resource_text)"
          :allowEdit="props.isEditing"
          placeholder-message="Lese-Inhalt (für Schüler:innen zum Lesen und als Grundlage für Fragen)"
          :editor-classes="{ '': !props.isEditing, 'min-h-32 py-8': props.isEditing }"
          @change="unsavedChanges = true"
          @unsavedChanges="
            (newHtml) => {
              localResource.resource_text = newHtml;
              unsavedChanges = true;
            }
          "
        />
        <div v-show="props.isEditing && localResource.resource_type === ResourceType.READING" class="flex items-center">
          <input
            :checked="!localResource.show_resource_text"
            @change="
              () => {
                localResource.show_resource_text = !localResource.show_resource_text;
                unsavedChanges = true;
              }
            "
            type="checkbox"
            class="shrink-0 mr-2 border-gray-200 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
          />
          <span class="text-xs md:text-sm">Text für Schüler:innen verbergen</span>
        </div>
        <div
          v-show="props.isEditing && localResource.resource_type === ResourceType.LISTENING"
          class="flex items-center justify-center w-full"
        >
          <input
            :checked="!localResource.show_dialog_closed_captions"
            @change="
              () => {
                localResource.show_dialog_closed_captions = !localResource.show_dialog_closed_captions;
                unsavedChanges = true;
              }
            "
            type="checkbox"
            class="shrink-0 mr-2 border-gray-200 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
          />
          <span class="text-xs md:text-sm">Dialog-Untertitelung für Schüler:innen verbergen</span>
        </div>

        <CreateListeningDialog
          ref="createListeningDialogModal"
          :contentItemId="props.contentItem.id"
          :overlayId="`create-listening-dialog-modal-${props.contentItem.id}`"
          @created="
            (dialogId: string) => {
              localResource.resource_dialog_id = dialogId;
              dialogUpdateTimestamp = new Date().toISOString();
              unsavedChanges = true;
            }
          "
          @updated="
            () => {
              dialogUpdateTimestamp = new Date().toISOString();
              unsavedChanges = true;
            }
          "
          :fromDialogId="localResource.resource_dialog_id"
        />

        <div
          v-if="
            !localResource.resource_dialog_id &&
            props.isEditing &&
            localResource.resource_type === ResourceType.LISTENING
          "
          class="flex pt-16 pb-24 w-full justify-center"
        >
          <ProgressButton
            icon="add"
            iconSize="2xl"
            color="purple"
            text="Hörverstehen-Dialog"
            @click="createListeningDialogModal.open()"
          />
        </div>
        <div
          class="w-full relative flex justify-center py-8"
          :key="dialogUpdateTimestamp"
          v-if="hasDialog && localResource.resource_type === ResourceType.LISTENING"
        >
          <div class="absolute top-4 right-16">
            <ProgressButton
              v-if="props.isEditing"
              icon="manufacturing"
              iconSize="2xl"
              color="purple"
              text="Bearbeiten"
              @click="createListeningDialogModal.open()"
            />
          </div>
          <DialogHistory
            v-if="dialogIsFetched"
            :dialog="dialog"
            :updateTimestamp="dialogUpdateTimestamp"
            :allowViewClosedCaptions="localResource.show_dialog_closed_captions"
            :isEditing="props.isEditing"
          />
          <LoadingSpinnerLarge v-else />
        </div>
      </div>
      <!-- end reading or listening resource -->

      <!-- illustrative media upload (not considered for assessing student answers) -->
      <div class="flex flex-col gap-y-4" :class="{ 'pt-12 -mt-4': props.isEditing }">
        <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4" v-show="props.isEditing">
          <span translate="no" class="material-symbols-outlined notranslate text-2xl select-none">info</span>
          <span class="text-sm text-justify" v-if="localResource.resource_type !== ResourceType.NO_RESOURCE">
            <p>
              Ergänzend zum Text oder Hörverstehen-Dialog kannst du deinen Schüler:innen auch Videos, Audios oder Bilder
              zur Verfügung stellen und dazu eine Frage formulieren. Du kannst den Text auch verbergen, so dass deine
              Schüler:innen die Fragen allein mithilfe des Medieninhalts beantworten müssen.
            </p>
            <p class="pt-1">
              BEACHTE: Wenn du offene Fragen formulieren möchtest, müssen die Antworten aus dem Text bzw.
              Hörverstehen-Dialog hervorgehen. Unsere KI berücksichtigt nur den Text- bzw. Dialoginhalt und deine
              Musterantworten, nicht die zusätzlichen Medieninhalte.
            </p>
          </span>
          <span class="text-sm text-justify" v-else>
            <p>
              Du kannst hier Medien hochladen, z.B. zu illustrativen Zwecken. Beachte, dass für die Bewertung der Fragen
              nicht diese Medien berücksichtigt werden. Frage und Lösung müssen für sich genommen für die casuu-KI
              bewertbar sein.
            </p>
          </span>
        </div>
        <ExerciseMediaUploadCard
          :contentItemId="props.contentItem.id"
          v-if="props.isEditing"
          v-show="!localResource.resource_media_item_id"
          @uploaded-item="
            async (mediaItemId, mediaItemType, mediaItemUrl) => {
              localResource = { ...props.contentItem.resource_with_questions, resource_media_item_id: mediaItemId };
              localResource.resource_media_item = {
                media_url: mediaItemUrl,
                media_item_type: mediaItemType,
              };
              localResource.show_resource_text = true;
              unsavedChanges = true;
            }
          "
        />
        <div
          class="flex mx-auto relative transition-all duration-500 ease-in-out"
          :class="{
            'w-full h-fit': showMediaFullWidth,
            'w-[200px]': !showMediaFullWidth,
          }"
          v-if="!!localResource.resource_media_item_id"
          :key="localResource.resource_media_item_id"
        >
          <MediaHighlightCard
            :content-item-id="null"
            :url="localResource.resource_media_item.media_url"
            :type="localResource.resource_media_item.media_item_type"
            :allow-edit="props.isEditing"
            :max-on-click="true"
            :is-maximized="showMediaFullWidth"
            :show-description="false"
            @removeMediaItem="
              async () => {
                localResource = { ...props.contentItem.resource_with_questions, resource_media_item_id: null };
                localResource.resource_media_item = {
                  media_url: '',
                  media_item_type: '',
                };
                unsavedChanges = true;
              }
            "
            @toggleFullWidth="showMediaFullWidth = !showMediaFullWidth"
          />
          <span
            v-if="!showMediaFullWidth"
            class="absolute top-0 start-0 bg-gray-900/50 p-0.5 rounded-lg pointer-events-none"
          >
            <span class="text-white text-center"> Klicken für Vollansicht </span>
          </span>
        </div>
      </div>
      <!-- end illustrative media upload -->

      <!-- add questions -->
      <div v-if="props.isEditing" class="justify-center w-full flex gap-x-4 pt-12 pb-2">
        <ProgressButton icon="add" iconSize="2xl" text="Multiple Choice-Frage" @click="addMcQuestion" />
        <ProgressButton icon="add" iconSize="2xl" text="Offene Frage" @click="addOpenQuestion" />
      </div>

      <div class="w-full py-2">
        <div class="py-4 w-full min-h-48 flex-col flex gap-y-6">
          <div v-if="keepQuestionsMounted" v-for="(question, index) in questions" class="relative">
            <McQuestion
              v-if="question.type === 'mc'"
              :section-content-item-id="props.contentItem.id"
              :section-content-item-index="props.contentItem.index"
              :is-editing="props.isEditing"
              :section-index="props.sectionIndex"
              :question="question"
              @interactionStarted="startInteraction"
              @questionSolved="
                (val) => {
                  questionIsSolved[question.id] = val;
                }
              "
            />
            <OpenQuestion
              v-else-if="question.type === 'open'"
              :section-content-item-id="props.contentItem.id"
              :section-content-item-index="props.contentItem.index"
              :is-editing="props.isEditing"
              :section-index="props.sectionIndex"
              :resource-id="props.contentItem.resource_with_questions.id"
              :question="question"
              @interactionStarted="startInteraction"
              @questionSolved="
                (val) => {
                  questionIsSolved[question.id] = val;
                }
              "
              @scrollBy="(distance) => emit('scrollBy', distance)"
              @undoLastScroll="() => emit('undoLastScroll')"
            />

            <span
              class="absolute top-0.5 end-1.5 cursor-pointer flex items-center text-center text-red-600 hover:text-red-800"
              v-if="props.isEditing"
              @click="removeQuestion(question.id)"
            >
              <span translate="no" class="material-symbols-outlined notranslate bg-transparent w-fit text-2xl"
                >delete</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
    v-show="!interactionFetchCompleted"
  >
    <LoadingSpinnerLarge />
  </div>

  <div
    v-if="
      interactionFetchCompleted &&
      !props.isEditing &&
      itemInteractionState?.completed_at != null &&
      itemInteractionState?.reopened_at == null
    "
    class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
    @click="reopenExercise"
  >
    <span class="block group-hover:hidden">
      {{ t('message.exerciseAlreadyCompleted') }}
    </span>
    <span class="hidden group-hover:flex items-center hover:text-teal-600">
      {{ t('message.exercisePlayAgain') }}
      <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
    </span>
  </div>
</template>

<style scoped></style>
