<script setup lang="ts">
import { ref, onMounted, defineProps, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { GraduationCap, BookA } from 'lucide-vue-next';
import { useAlertStore, useAuthStore } from '@/stores';
import VocabDashboardStatsHeader from '@/components/didactics/VocabDashboardStatsHeader.vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import VocabTestModalNew from './vocab/VocabTestModalNew.vue';
import VocabListNew from './vocab/VocabListNew.vue';
import VocabTestButton from './vocab/VocabTestButton.vue';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import { MeaningAndMakingTestMode } from '@/helper/vocab';
import { VocabItemUpdate } from '@/apiclient';
import BottomNav from '@/components/BottomNav.vue';

const { t } = useI18n();

const authStore = useAuthStore();
const initialFetchCompleted = ref(false);

const vocabDashboardStatsHeader = ref(null);
const viewingList = ref(0);
const practiceMode = ref(false);
const fetchComplete = ref(false);
const showVocabTestButton = ref(false);
const showSearchbar = ref(false);
const onlyActive = ref(false);
const alertStore = useAlertStore();

const vocabTestModalIsOpen = ref(false);

const { vocabLists } = storeToRefs(authStore);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  startInPracticeMode: {
    type: Boolean,
    default: false,
  },
  hideTabularNav: {
    type: Boolean,
    default: false,
  },
});

watch(onlyActive, () => {
  viewingList.value = onlyActive.value ? 1 : 0;
});

onMounted(async () => {
  await authStore.fetchUserVocabLists();

  initialFetchCompleted.value = true;
  practiceMode.value = props.startInPracticeMode;
  showVocabTestButton.value = !practiceMode.value;
  fetchComplete.value = true;
});

const deleteVocabItem = async (vocabItemId: string) => {
  await (
    await getApiClient()
  ).vocabLists.removeVocabFromPersonalVocabList(vocabLists.value[viewingList.value].id, vocabItemId);
  await authStore.fetchUserVocabLists();
};

const updateVocabItem = async (vocabItemId: string, vocabItemUpdate: VocabItemUpdate) => {
  await authStore.updateUserVocabItem(vocabItemId, vocabItemUpdate);
};
</script>

<template>
  <main :key="$route.fullPath" id="content" class="flex h-full max-w-[85rem] mx-auto font-ttc">
    <div v-if="!initialFetchCompleted" class="flex w-full items-center justify-center">
      <LoadingSpinnerLarge />
    </div>
    <div v-else class="flex flex-col w-full mx-2 gap-y-3">
      <!-- header for practice mode -->
      <div v-if="practiceMode" class="mx-2 mt-8">
        <div class="flex flex-row items-center gap-x-4">
          <GraduationCap :size="32" />
          <h1 class="text-[32px] font-medium">Vokabeln lernen</h1>
        </div>

        <p class="text-lg">
          Lies die folgende Vokabelliste durch. Wenn du soweit bist und du alle Vokabeln einmal gelesen hast, kannst du
          die Übung starten.
        </p>
      </div>
      <!-- other header variant with filter -->
      <div v-else class="flex flex-row items-center mx-2 mt-2">
        <h1 class="text-[28px] font-medium">{{ $t('message.myVocabulary') }}</h1>

        <div
          class="flex flex-row cursor-pointer select-none items-center ml-auto border text-sm border-black border-opacity-5 rounded-lg px-3 py-2 gap-x-1"
          :class="{ 'bg-black text-white': onlyActive }"
          @click="onlyActive = !onlyActive"
        >
          <BookA :size="16" />
          {{ $t('message.activeVocab') }}
        </div>
      </div>
      <!-- end header -->

      <VocabDashboardStatsHeader
        v-if="!practiceMode"
        ref="vocabDashboardStatsHeader"
        :numVocabsInList="vocabLists[viewingList]?.vocab_items.length"
        @showSearchbar="showSearchbar = $event"
      />

      <VocabListNew
        :vocabList="vocabLists[viewingList]"
        :enableSeenCheck="practiceMode"
        :showSearchbar="!practiceMode && showSearchbar"
        :reserveSpaceForButton="showVocabTestButton"
        :with-bottom-nav="true"
        :allowRemoveVocab="true"
        :showTrashAsRemoveIcon="viewingList === 0"
        :isEditingFull="false"
        :isEditingRemarks="true"
        @allVocabsSeen="showVocabTestButton = true"
        @deleteItem="deleteVocabItem"
        @updateItem="updateVocabItem"
      />

      <BottomNav />
    </div>

    <!-- floating button -->
    <div v-if="showVocabTestButton">
      <VocabTestButton @startVocabTest="vocabTestModalIsOpen = true" :withBottomNav="true" />
    </div>
    <!-- end floating button -->
  </main>

  <VocabTestModalNew
    v-if="vocabTestModalIsOpen"
    overlayId="vocab-test-modal"
    :vocabItems="vocabLists[viewingList]?.vocab_items || []"
    :nItems="1"
    @closed="
      async () => {
        vocabTestModalIsOpen = false;
        await vocabDashboardStatsHeader.refetchStats();
        await (await getApiClient()).users.noteDailyVocabTestTaken().then(async (response) => {
          console.log(response);
          response.notifications?.forEach((notification: any) => {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          });
          await authStore.fetchUserXp();
        });
      }
    "
  />
</template>
