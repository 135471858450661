<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps(nodeViewProps);

const hintTextarea = ref(null);
const revealSolutionBecasueWrongSpan = ref(null);
const isMaximized = ref(false);
const rootContainer = ref(null);
const placeholder = ref('Gesucht');
const attemptOrSolutionInput = ref(null);
const isSolved = ref(false);
const isWrong = ref(false);
const uuid = uuidv4();

onMounted(async () => {
  if (!props.editor.isEditable) placeholder.value = '_'.repeat(props.node.attrs.solutionText.length);

  if (!!attemptOrSolutionInput.value) {
    await nextTick();
    attemptOrSolutionInput.value.focus();
  }

  if (!props.editor.isEditable) {
    props.editor.emit('nodeSolved', {
      id: uuid,
      isSolved: false,
    });
    props.editor.emit('nodeAttempted', {
      id: uuid,
      isAttempted: false,
    });
  }

  await nextTick();
  console.log('ClozeInput mounted');
  isMaximized.value = false;
});

watch(
  () => props.node.attrs.attemptText,
  (newVal) => {
    checkIfCorrect();
    if (newVal === '') isWrong.value = false;
    // we check if correct while the user is typing
    // but we check if wrong ONLY once the user is done typing, i.e. when the input loses focus
  },
);

const checkIfCorrect = () => {
  if (props.editor.isEditable) return;
  let oldIsSolved = isSolved.value;
  const alternatives = alternativeSolutions.value;

  isSolved.value =
    props.node.attrs.attemptText.trim().toLowerCase() === solution.value?.trim().toLowerCase() ||
    alternatives.some((alt: string) => alt.trim().toLowerCase() === props.node.attrs.attemptText.trim().toLowerCase());

  if (oldIsSolved !== isSolved.value)
    props.editor.emit('nodeSolved', {
      id: uuid,
      isSolved: isSolved.value,
    });
};

const checkIfWrong = () => {
  if (props.editor.isEditable) return;
  isWrong.value =
    props.node.attrs.attemptText.trim().toLowerCase() !== solution.value?.trim().toLowerCase() &&
    !alternativeSolutions.value.some(
      (alt: string) => alt.trim().toLowerCase() === props.node.attrs.attemptText.trim().toLowerCase(),
    );
};

const isAttempted = computed(() => isSolved.value || isWrong.value);

watch(
  () => isWrong.value,
  (newVal) => {
    console.log('maximize because wrong');
    if (newVal) {
      setTimeout(() => {
        isMaximized.value = true;
      }, 500); // wait for close animations to finish!
    }
  },
);

watch(
  () => isAttempted.value,
  (newVal) => {
    props.editor.emit('nodeAttempted', {
      id: uuid,
      isAttempted: newVal,
    });
  },
);

const delayedPositionClasses = ref('relative');
const appearAtOnceDisappearDelayed = ref(false);
const handleClickOutside = (event: Event) => {
  if (!rootContainer.value) return;
  let rect = rootContainer.value.getBoundingClientRect();
  isMaximized.value =
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom;
};

const debounceHandleClickOutside = debounce(handleClickOutside, 200);

const innerClozeInputWidth = computed(() => {
  if (props.editor.isEditable && isMaximized.value) return '100%';
  if (!solution.value) return '8ch';
  if (isMaximized.value) {
    let cappedHintTextLength = Math.min(props.node.attrs.hintText.length, 32);
    return 0.9 * Math.max(solution.value.length + 1, 0.75 * cappedHintTextLength + 3, 9) + 'ch';
  }
  return 0.9 * Math.max(solution.value.length + 1, 9) + 'ch';
});

const outerClozeInputWidthNonExpanding = computed(() => {
  if (!solution.value) return '9ch';
  return 0.9 * Math.max(solution.value.length + 2, 10) + 'ch';
});

const firstSolutionCharacterIsLowercase = computed(() => {
  return solution.value && solution.value[0] === solution.value[0].toLowerCase();
});

watch(
  () => isMaximized.value,
  async (nowMaximized) => {
    if (nowMaximized) {
      // Switch to absolute for symmetric opening
      window.addEventListener('click', debounceHandleClickOutside);
      delayedPositionClasses.value = 'absolute top-0 border-2';
      appearAtOnceDisappearDelayed.value = true;
      return;
    }

    window.removeEventListener('click', debounceHandleClickOutside);
    // Wait for collapse animation to complete (height transition)
    appearAtOnceDisappearDelayed.value = true;
    delayedPositionClasses.value = 'absolute -top-[9px] left-0 border-2';
    await new Promise((resolve) => setTimeout(resolve, 100));

    // After collapse finishes, switch back to relative to reflow inline
    delayedPositionClasses.value = 'relative bg-transparent border-none';
    appearAtOnceDisappearDelayed.value = false;
  },
  { immediate: true },
);

const solutionOrAttempt = computed({
  get() {
    return props.editor.isEditable ? props.node.attrs.solutionText : props.node.attrs.attemptText;
  },
  set(value) {
    if (props.editor.isEditable) {
      props.node.attrs.solutionText = value;
    } else {
      props.node.attrs.attemptText = value;
    }
  },
});

const solution = computed(() => props.node.attrs.solutionText);
const alternativeSolutions = computed(() => {
  try {
    return props.node.attrs.alternativeSolutions ? JSON.parse(props.node.attrs.alternativeSolutions) : [];
  } catch (e) {
    console.error('Failed to parse alternativeSolutions:', e, ' when parsing ', props.node.attrs.alternativeSolutions);
    return [];
  }
});

watch(
  () => props.node.attrs.hintText,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: 'hint',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => props.node.attrs.alternativeSolutions,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: 'alternativeSolutions',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => solutionOrAttempt.value,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: props.editor.isEditable ? 'solution' : 'attempt',
      value: newVal,
      nodeId: uuid,
    });
  },
);

const newAlternative = ref('');
const addAlternativeSolution = () => {
  if (!newAlternative.value.trim()) return;

  // Parse existing solutions from JSON string, or start with empty array
  const currentSolutions = props.node.attrs.alternativeSolutions
    ? JSON.parse(props.node.attrs.alternativeSolutions)
    : [];

  const updatedSolutions = [...currentSolutions, newAlternative.value.trim()];

  console.log('updatedSolutions', updatedSolutions);

  // Store as JSON string
  props.node.attrs.alternativeSolutions = JSON.stringify(updatedSolutions);

  console.log('updatedSolutions result', props.node.attrs.alternativeSolutions);

  // Clear the input
  newAlternative.value = '';
};

const removeAlternativeSolution = (index: number) => {
  const currentSolutions = props.node.attrs.alternativeSolutions
    ? JSON.parse(props.node.attrs.alternativeSolutions)
    : [];

  currentSolutions.splice(index, 1);

  props.node.attrs.alternativeSolutions = JSON.stringify(currentSolutions);
};
</script>

<template>
  <node-view-wrapper
    class="relative inline-flex w-1 items-center align-middle -translate-y-[2px] -my-1"
    as="span"
    :class="{
      'z-[10]': isMaximized,
      'z-1': !isMaximized,
    }"
    @click="isMaximized = true"
    :style="{
      verticalAlign: 'center',
      lineHeight: 'inherit',
      width: outerClozeInputWidthNonExpanding,
    }"
  >
    <!-- Placeholder to maintain space when absolute/ maxed -->
    <span
      v-if="appearAtOnceDisappearDelayed"
      :style="{
        width: outerClozeInputWidthNonExpanding,
      }"
    ></span>

    <span
      ref="rootContainer"
      :class="[
        'transition-all duration-100 transform',
        isMaximized ? 'top-0 left-0 rounded-lg ' : '',
        !isSolved && !isWrong ? 'border-blue-600 bg-transparent' : '',
        isSolved ? 'border-teal-500 bg-teal-50' : '',
        isWrong ? 'border-red-500 bg-red-50' : '',
        delayedPositionClasses,
      ]"
      :style="{
        transform: isMaximized ? 'translateY(-36px)' : 'translateY(0)',
      }"
    >
      <label
        class="text-white w-full px-1 transition-all duration-100 transform overflow-hidden text-xs font-bold rounded-b-none rounded-t-md flex justify-between items-center"
        :class="{
          'py-1 ': isMaximized,
          'bg-blue-600': !isSolved && !isWrong,
          'bg-teal-500': isSolved,
          'bg-red-500': isWrong,
        }"
        contenteditable="false"
        :style="{ height: isMaximized ? '16px' : '0', lineHeight: isMaximized ? '16px' : '0' }"
        >{{ props.editor.isEditable ? 'Lücke' : 'Eingabe' }}
        <span
          v-show="props.editor.isEditable"
          draggable="true"
          data-drag-handle
          translate="no"
          class="pl-1 material-symbols-outlined no-translate text-lg cursor-move"
        >
          drag_indicator
        </span>
      </label>

      <span class="flex-col flex transition-all duration-100 transform" :class="{ 'my-1 gap-y-1': isMaximized }">
        <input
          @focusin="isMaximized = true"
          @focusout="
            () => {
              if (!props.editor.isEditable) isMaximized = false;
            }
          "
          ref="attemptOrSolutionInput"
          contenteditable="true"
          class="mx-1 my-0 px-1 border transition-all duration-100 transform border-gray-300 rounded-md text-base md:text-sm"
          :class="{
            'focus:border-blue-600 focus:ring-blue-600 bg-gray-50': !isSolved && !isWrong,
            'focus:border-teal-600 focus:ring-teal-600 bg-teal-50': isSolved,
            'focus:border-red-600 focus:ring-red-600 bg-red-50': isWrong,
            'py-1 ': isMaximized,
            'py-0': !isMaximized,
          }"
          :style="{
            width: innerClozeInputWidth,
          }"
          :placeholder="placeholder"
          spellcheck="false"
          autocomplete="off"
          autocorrect="off"
          :autocapitalize="!props.editor.isEditable && firstSolutionCharacterIsLowercase ? 'off' : 'sentences'"
          v-model="solutionOrAttempt"
          @change="checkIfWrong"
        />
        <span
          class="flex-col mx-1 flex bg-gray-50 mx-auto overflow-hidden t transition-all duration-100 transform fallback-break"
          :style="{
            height: isMaximized
              ? props.editor.isEditable
                ? '100%'
                : revealSolutionBecasueWrongSpan.scrollHeight + hintTextarea.scrollHeight + 'px'
              : '0',
            width: innerClozeInputWidth,
          }"
        >
          <div v-if="props.editor.isEditable" class="flex-col flex gap-1 w-full">
            <div class="flex items-center gap-1">
              <input
                type="text"
                class="flex-grow px-1 mx-1 py-0 text-xs border border-gray-300 rounded-md focus:border-blue-600 focus:ring-blue-600"
                placeholder="Alternativlösung hinzufügen"
                v-model="newAlternative"
                @keyup.enter="addAlternativeSolution"
              />
              <button
                @click="addAlternativeSolution"
                class="px-2 py-0.5 text-xs text-white bg-blue-600 rounded-md hover:bg-blue-700"
                :disabled="!newAlternative"
              >
                +
              </button>
            </div>
            <div class="flex flex-wrap gap-1">
              <span
                v-for="(alt, index) in alternativeSolutions"
                :key="index"
                class="flex items-center gap-1 px-2 py-0.5 text-xs bg-gray-100 rounded-full"
              >
                {{ alt }}
                <button @click="removeAlternativeSolution(index)" class="text-gray-500 hover:text-gray-700">×</button>
              </span>
            </div>
          </div>
          <textarea
            v-show="props.editor.isEditable || !!props.node.attrs.hintText"
            ref="hintTextarea"
            contenteditable="true"
            class="px-1 resize-none rounded-md flex w-full text-gray-800 focus:ring-blue-600"
            :class="{
              'mx-1 bg-gray-50 text-base focus:border-blue-600 py-0': props.editor.isEditable,
              'text-xs': !props.editor.isEditable,
              'bg-transparent border-none': !isSolved && !isWrong,
              'bg-teal-50 border-none': isSolved,
              'bg-red-50 border-none': isWrong,
            }"
            placeholder="Hinweis"
            rows="1"
            v-model="props.node.attrs.hintText"
            @input="updateMinTextareaHeight($event)"
            :disabled="!props.editor.isEditable"
          />
          <span
            ref="revealSolutionBecasueWrongSpan"
            class="text-xs text-gray-500 px-1"
            v-show="isWrong && !props.editor.isEditable"
          >
            Lösung: {{ solution }}
          </span>
        </span>
      </span>
    </span>
  </node-view-wrapper>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
