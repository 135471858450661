<script setup lang="ts">
import TextFinishedButton from '@/components/didactics/text/TextFinishedButton.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { onBeforeRouteLeave } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';
import ProgressButton from '@/components/ProgressButton.vue';
import SetPhrase from './SetPhrase.vue';
import VocabTestModalNew from '@/components/didactics/vocab/VocabTestModalNew.vue';
import { storeToRefs } from 'pinia';

import { useI18n } from 'vue-i18n';
import test from 'node:test';
const { t } = useI18n();

const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  boxReducedWidth: {
    type: Number,
    required: true,
  },
  germanContainerWidth: {
    type: Number,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
});

const courseStore = useCourseStore();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const courseInteractionStore = useCourseInteractionStore();

const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const itemInteractionState = ref({});

const unsavedChanges = ref(false);
const contentEditor = ref(null);
const isSavingChanges = ref(false);
const vocabTestModalIsOpen = ref(false);
const localContent = ref([]);
const testFinished = ref(false);
const isSubmitting = ref(false);

const exerciseCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) return;
      setTimeout(() => {
        saveSetPhraseList();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

onMounted(async () => {
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveSetPhraseList();
      return undefined;
    }
  };
  localContent.value = props.contentItem.meaning_and_making_exercise;
  await fetchInteractionState();
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveSetPhraseList();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(props.contentItem.id);
  console.log('interactionState', interactionState, '.');
  // create local copy - we want to defer updates to wait for animations etc
  if (!!interactionState && interactionState !== {}) {
    itemInteractionState.value = { ...interactionState };
  }
};

const saveSetPhraseList = async () => {
  if (!props.isEditing) return;
  if (!props.contentItem) return;
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;

  try {
    await courseStore.updateMeaningAndMakingExercise(props.sectionId, props.contentItem.id, {
      title: localContent.value.title,
      description: localContent.value.description,
    });
    console.log('saved set phrase list');
    unsavedChanges.value = false;
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to autosave set phrase list', 'Error', error);
    throw new Error('Failed to autosave set phrase list');
  } finally {
    isSavingChanges.value = false;
  }
};

const addSetPhrase = async () => {
  console.log('add set phrase');
  try {
    // First save all existing set phrases
    for (const setPhrase of localContent.value.set_phrase_list.set_phrases) {
      await courseStore.updateSetPhraseInContentItemWithSetPhraseList(
        props.sectionId,
        props.contentItem.id,
        props.contentItem.meaning_and_making_exercise.set_phrase_list.id,
        setPhrase.id,
        {
          phrase: setPhrase.phrase,
          explanation: setPhrase.explanation,
        },
      );
    }

    // Then add the new set phrase
    await courseStore.addSetPhraseToContentItemWithSetPhraseList(props.sectionId, props.contentItem.id, {
      phrase: '',
      explanation: '',
    });
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to add set phrase', 'Error', error);
    throw new Error('Failed to add set phrase');
  }
};

const deleteSetPhrase = async (setPhraseId: string) => {
  console.log('delete set phrase with id', setPhraseId);
  try {
    await courseStore.deleteSetPhraseFromContentItemWithSetPhraseList(
      props.sectionId,
      props.contentItem.id,
      setPhraseId,
      props.contentItem.meaning_and_making_exercise.set_phrase_list.id,
    );
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to delete set phrase', 'Error', error);
    throw new Error('Failed to delete set phrase');
  }
};

const handleCloseModal = async (finished: boolean) => {
  console.log('handleCloseModal', finished);
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (finished) {
    testFinished.value = true;
    // if user doesnt do, set to completed after some secs
    setTimeout(() => {
      markComplete();
    }, 8000);
  }
  vocabTestModalIsOpen.value = false; // reenables minimize by click outside shortly after, not directly
};

const markComplete = async () => {
  // TODO: add set phrases to users to-be-created set phrases list

  courseInteractionStore
    .setSectionContentItemCompletedIfNotAlready(props.contentItem.id, props.sectionIndex, props.pageIndex)
    .then(async (response) => {
      if (!response) return;
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      itemInteractionState.value = response.section_content_item_interaction;
      await authStore.fetchUserXp();
    });
};

const setPhrasesAsVocabItems = computed(() => {
  return props.contentItem.meaning_and_making_exercise.set_phrase_list.set_phrases.map((setPhrase: SetPhrase) => ({
    id: setPhrase.id,
    term: setPhrase.phrase,
    remarks: setPhrase.explanation,
  }));
});
</script>

<template>
  <div
    class="relative w-full bg-lime-200 rounded-lg border border-lime-500 px-2 pb-1 text-sm flex-col flex"
    v-if="!!localContent"
  >
    <span class="text-sm pt-1 pb-2 text-lime-800 inline-flex text-center items-center">
      <span class="text-center uppercase flex items-center"> 🛠️ Redemittel </span>
      <span class="px-2 text-xl mb-1 flex items-center" v-if="props.isEditing || localContent.title !== ''"> | </span>
      <textarea
        v-if="props.isEditing"
        v-model="localContent.title"
        class="px-2 py-0 flex-grow bg-lime-100 resize-none border-lime-500 rounded-lg text-lime-800 my-auto"
        placeholder="Titel (optional)"
        rows="1"
        @change="unsavedChanges = true"
      />
      <span v-else class="text-lime-800 dark:text-neutral-200 font-semibold flex items-center">
        {{ localContent.title }}
      </span>
    </span>

    <span v-if="!props.isEditing && localContent.description !== ''" class="text-xs md:text-sm pt-2 pb-4">
      {{ localContent.description }}
    </span>
    <textarea
      v-if="props.isEditing"
      v-model="localContent.description"
      class="w-full bg-lime-100 text-xs md:text-sm px-2 py-0 mb-2 resize-none text-gray-800 border-lime-500 rounded-lg"
      placeholder="Erläuterungen (zur Gesamtliste; optional)"
      rows="1"
      @change="unsavedChanges = true"
    />

    <ul class="" v-if="!!localContent.set_phrase_list">
      <li v-for="setPhrase in localContent.set_phrase_list.set_phrases" :key="setPhrase.id" class="pb-4">
        <div class="flex-col flex w-full -ml-2">
          <SetPhrase
            :key="setPhrase.id"
            :setPhrase="setPhrase"
            :isEditing="props.isEditing"
            :sectionId="props.sectionId"
            :contentItemId="props.contentItem.id"
            :setPhraseListId="props.contentItem.meaning_and_making_exercise.set_phrase_list.id"
            @deleteSetPhrase="deleteSetPhrase"
          />
        </div>
      </li>
    </ul>

    <div class="pt-1 mx-auto justify-center flex">
      <ProgressButton
        v-if="props.isEditing"
        :disabled="isSubmitting"
        @click="
          () => {
            unsavedChanges = true;
            addSetPhrase();
          }
        "
        icon="add"
        iconSize="text-2xl"
        :text="'Redemittel hinzufügen'"
      />
    </div>

    <div v-if="!props.isEditing" class="w-full flex justify-center group">
      <div v-if="!testFinished && !exerciseCompleted" @click="vocabTestModalIsOpen = true">
        <ProgressButton text="Üben" />
      </div>
      <div class="" v-else-if="testFinished && !exerciseCompleted" @click="markComplete">
        <div class="block group-hover:hidden">
          <ProgressButton text="Vokabeln speichern" color="teal" icon="circle" iconSize="text-2xl" />
        </div>
        <div class="hidden group-hover:block">
          <ProgressButton
            text="Vokabeln speichern"
            color="teal"
            icon="check_circle"
            iconSize="text-2xl"
            :hovered="true"
          />
        </div>
      </div>
      <div v-else>
        <div class="block group-hover:hidden">
          <button
            type="button"
            class="group select-none py-1.5 px-3 text-xs md:text-sm inline-flex items-center gap-x-0.5 md:gap-x-2 font-medium rounded-lg shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white text-teal-500 bg-teal-200/10 border-gray-200 border-2 cursor-default dark:border-teal-700 dark:bg-teal-900 '"
          >
            <span translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
            {{ 'Fertig' }}
          </button>
        </div>
        <div class="hidden group-hover:block">
          <ProgressButton
            text="Nochmal üben"
            color="blue"
            icon="exercise"
            iconSize="text-2xl"
            @click="vocabTestModalIsOpen = true"
          />
        </div>
      </div>
    </div>

    <!-- <div class="absolute bottom-0.5 end-3">
      <TextFinishedButton
        v-if="!props.isEditing"
        :contentItemId="contentItem.id"
        :sectionIndex="<number>props.sectionIndex"
        :pageIndex="<number>props.pageIndex"
      />
    </div> -->
    <VocabTestModalNew
      v-if="vocabTestModalIsOpen"
      :overlayId="`set-phrase-test-modal-${uuidv4()}`"
      :vocabItems="setPhrasesAsVocabItems || []"
      :nItems="5"
      :mode="{
        USE_SET_PHRASE: 'Bilden Sie einen Satz mit dem Redemittel',
      }"
      @closed="handleCloseModal"
    />
  </div>
</template>

<style scoped></style>
