<script setup lang="ts">
import { ErrorMessage, Field, useField, useForm } from 'vee-validate';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';
import PersonDetails from '@/components/new_case/sections/PersonDetails.vue';
import { updateMinTextareaHeight } from '@/helper';
import { PersonDetailsForm } from '@/components/new_case/typing';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import { PatientCreate, PatientDetails, ThirdPersonCreate, ThirdPersonDetails } from '@/apiclient';
import { roleTypes } from '@/helper';

const alertStore = useAlertStore();

const details = defineModel({
  type: Object as () => Object,
  required: true,
});

onMounted(() => {
  console.log('PersonEdit mounted with startingFromCompleteCase', startingFromCompleteCase);
});

const emit = defineEmits([
  'click',
  'scrollToBottom',
  'detailsToggled',
  'isValid',
  'isGenerating',
  'generateFinished',
  'setProfileImage',
]);
const { startingFromCompleteCase, requireFullValidity, alwaysShowDetails, alwaysShowPatientDetails } = defineProps({
  startingFromCompleteCase: {
    type: Boolean,
    required: false,
    default: false,
  },
  requireFullValidity: {
    type: Boolean,
    required: false,
    default: true,
  },
  alwaysShowDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
  alwaysShowPatientDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
  editTouched: {
    type: Boolean,
    required: false,
    default: false,
  },
  selectTouched: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { value: localFirstName, errors: firstNameErrors } = useField('first_name', 'max:50|required', {
  initialValue: details.value.first_name,
});
const { value: localLastName, errors: lastNameErrors } = useField('last_name', 'max:50|required', {
  initialValue: details.value.last_name,
});
const { value: localAge, errors: ageErrors } = useField('age', 'numeric|min_value:1|max_value:120|required', {
  initialValue: details.value.age,
});
const { value: localSex, errors: sexErrors } = useField('sex', 'required', {
  initialValue: details.value.sex,
});
const { value: localFreehandDescription, errors: freehandDescriptionErrors } = useField(
  'freehand_description',
  'max:500|required',
  {
    initialValue: details.value.freehand_description,
  },
);

const showDetails = ref(alwaysShowDetails);
const oldFreehandDescription = ref('');
const generateStarted = ref(false);
const isGenerating = ref(false);
const detailsAreValid = ref(false);

const generateFinished = computed(() => {
  return !isGenerating.value && generateStarted.value;
});

watch(
  () => generateFinished.value,
  (value) => {
    if (value) {
      emit('generateFinished');
    }
  },
);

const showPatientDetails = computed(() => {
  console.log('alwaysShowPatientDetails', alwaysShowPatientDetails);
  if (alwaysShowPatientDetails) {
    return true;
  }
  console.log(
    'PersonEdit: showPatientDetails',
    showDetails.value && details.value.role && details.value.role.value === 'PATIENT',
  );
  if (details.value.role) {
    console.log('PersonEdit: showPatientDetails: showDetails.value', showDetails.value);
    console.log('PersonEdit: showPatientDetails: role.value', details.value.role.value);
  }
  return showDetails.value && details.value.role && details.value.role.value === 'PATIENT';
});

const freehandDescriptionChangedAfterGenerate = computed(() => {
  return details.value.freehand_description != oldFreehandDescription.value;
});

const roleIsValid = computed(() => {
  console.log('PersonEdit: roleIsValid', !!details.value.role);
  return !!details.value.role;
});

const sexIsValid = computed(() => {
  return !!details.value.sex;
});

const allValid = computed(() => {
  console.log('starting from complete case', startingFromCompleteCase, 'for ', localFirstName.value);
  console.log(
    'PersonEdit: isValid',
    'role: ',
    roleIsValid.value,
    'freehand desc errors:',
    freehandDescriptionErrors.value.length === 0,
    'generate started if not full required or loaded from full case: ',
    generateStarted.value || requireFullValidity || startingFromCompleteCase,
    'details: ',
    detailsAreValid.value,
    'sex: ',
    sexIsValid.value,
    'requireFullValidity: ',
    requireFullValidity,
    'first name errors: ',
    firstNameErrors.value,
    'last name errors: ',
    lastNameErrors.value,
    'age errors: ',
    ageErrors.value,
  );
  // return roleIsValid.value; //  && sexIsValid.value && Object.keys(errors).length === 0;
  let valid =
    roleIsValid.value &&
    freehandDescriptionErrors.value.length === 0 &&
    !!details.value.freehand_description &&
    (generateStarted.value || requireFullValidity || startingFromCompleteCase) &&
    ((detailsAreValid.value && sexIsValid.value) || !requireFullValidity) &&
    ((firstNameErrors.value.length === 0 && !!details.value.first_name) || !requireFullValidity) &&
    ((lastNameErrors.value.length === 0 && !!details.value.last_name) || !requireFullValidity) &&
    ((ageErrors.value.length === 0 && !!details.value.age) || !requireFullValidity);
  console.log('PersonEdit: isValid', valid);
  // emit('isValid', isValid);
  // console.log('PersonEdit emittted')
  return valid;
});

const descriptionFilled = computed(() => {
  return !!details.value.freehand_description;
});

const nameAndAgeFilled = computed(() => {
  return !!details.value.first_name && !!details.value.last_name && !!details.value.age;
});

function detailsValidityChanged(value) {
  console.log('detailsValidityChanged', value);
  detailsAreValid.value = value;
}

const isPhysician = computed(() => {
  return details.value.role && details.value.role.value === 'PHYSICIAN';
});

watch(
  () => showDetails.value,
  (value) => {
    emit('detailsToggled', value);
  },
);
watch(
  () => allValid.value,
  (value) => {
    console.log('PersonEdit emitting: isValid', value);
    emit('isValid', value);
  },
);

watch(
  () => isGenerating.value,
  async (value) => {
    emit('isGenerating', value);
    if (value) {
      showDetails.value = true;
    }
  },
);

async function generateImage() {
  if (details.value?.role == null) {
    return;
  }

  let profileImage;
  if (details.value?.role?.value === 'PATIENT') {
    console.log('creating patient profile image for ', details.value);
    profileImage = await (await getApiClient()).patients.createNewPatientProfileImage(details.value as PatientDetails);
  } else {
    console.log('creating third person profile image for ', details.value as ThirdPersonCreate);
    console.log('role is', details.value.role);
    profileImage = await (
      await getApiClient()
    ).thirdPersons.createNewThirdPersonProfileImage(details.value.role.value, details.value as ThirdPersonDetails);
  }

  console.log('retrieved image', profileImage);
  emit('setProfileImage', profileImage);
}

async function generatePerson() {
  generateStarted.value = true;
  isGenerating.value = true;
  oldFreehandDescription.value = details.value.freehand_description;

  // mock-generate: wait for 2s and reset to false - TODO replace by actual api call
  await new Promise((resolve) => setTimeout(resolve, 2000));

  let response;
  if (details.value.role.value === 'PATIENT') {
    response = await (
      await getApiClient()
    ).patients.generatePatientFromFreehandDescriptionAndOtherDetails(details.value);
  } else {
    response = await (
      await getApiClient()
    ).thirdPersons.generateThirdPersonFromFreehandDescriptionAndOtherDetails(details.value);
  }

  console.log('generatePerson response', response);

  for (const [key, value] of Object.entries(response)) {
    details.value[key] = value;
  }
  console.log('generatePerson details', details.value);

  generateImage();

  alertStore.success(
    details.value.role.display_name +
      ' ' +
      (details.value.academic_title ? details.value.academic_title + ' ' : '') +
      details.value.first_name +
      ' ' +
      details.value.last_name +
      ' erfolgreich erstellt.',
  );

  isGenerating.value = false;
}

defineExpose({
  generateImage,
});
</script>

<template>
  <!-- Card -->
  <form>
    <div class="py-1 sm:py-2 px-1">
      <div class="p-2 space-y-5">
        <!-- Grid Tip -->
        <!--        <div-->
        <!--            class="gap-y-1.5 sm:gap-y-0 sm:gap-x-5 sm:mt-2.5 inline-block text-xs text-justify text-gray-500 dark:text-neutral-500">-->
        <!--          Wähle die Art der Person.-->
        <!--          Gib alle Daten an, die dir wichtig sind und lass den Rest automatisch füllen.-->
        <!--        </div>-->
        <!-- End Col -->

        <!-- Grid Role -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufnm" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Rolle*
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <select
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              class="py-2 mb-1.5 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': details.role == null && editTouched,
              }"
              v-model="details.role"
            >
              <option selected="" :value="null">Auswählen</option>
              <option v-for="role in roleTypes" :value="role">{{ role.display_name }}</option>
            </select>
            <div
              v-show="details.role == null"
              class="text-xs"
              :class="{ 'text-red-600': editTouched, 'text-blue-600': !editTouched }"
            >
              Dies ist ein Pflichtfeld
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->

        <!-- Grid Freitext -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufnm" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Beschreibung*
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <!--            <Field v-slot="{ field, errors, value }" name="freehand_description" rules="max:1000|required">-->
            <textarea
              class="py-2 resize-none px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
              placeholder="Personenbeschreibung als Freitext. Details können nach Generieren überarbeitet werden."
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              @keyup.space.prevent="(event) => event.stopPropagation()"
              @input="
                (event) => {
                  localFreehandDescription = event.target.value;
                  updateMinTextareaHeight(event.target);
                }
              "
              rows="3"
              v-model="details.freehand_description"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500':
                  freehandDescriptionErrors.length > 0 || (details.freehand_description == null && editTouched),
              }"
            />
            <span v-if="freehandDescriptionErrors.length > 0" class="text-xs text-red-600">{{
              freehandDescriptionErrors[0]
            }}</span>
            <span
              v-if="
                (details.freehand_description === '' || details.freehand_description == null) &&
                freehandDescriptionErrors.length === 0
              "
              class="text-xs"
              :class="{ 'text-red-600': editTouched, 'text-blue-600': !editTouched }"
            >
              Dies ist ein Pflichtfeld
            </span>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->

        <!-- Grid Name -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufnm" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Name {{ requireFullValidity ? '*' : '' }}
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <div class="grid grid-cols-12 gap-2">
              <!-- title if physician -->
              <div v-if="isPhysician" class="col-span-2 overflow-hidden">
                <select
                  v-model="details.academic_title"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="text-transparent py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                >
                  <option selected="" :value="null">Titel</option>
                  <option value="DR">Dr.</option>
                  <option value="PROF_DR">Prof. Dr.</option>
                </select>
              </div>

              <!-- first name -->
              <div :class="{ 'col-span-6': !isPhysician, 'col-span-5': isPhysician }">
                <input
                  type="text"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                  placeholder="Vorname"
                  v-model="details.first_name"
                  @input="localFirstName = $event.target.value"
                  :class="{
                    'border-red-500 focus:border-red-500 focus:ring-red-500': firstNameErrors.length > 0,
                  }"
                />
                <span v-if="firstNameErrors.length > 0" class="text-xs text-red-600">{{ firstNameErrors[0] }}</span>
                <span
                  v-if="details.first_name === '' && firstNameErrors.length === 0 && requireFullValidity"
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>

              <!-- last name -->
              <div :class="{ 'col-span-6': !isPhysician, 'col-span-5': isPhysician }">
                <input
                  type="text"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                  placeholder="Zuname"
                  v-model="details.last_name"
                  @input="localLastName = $event.target.value"
                  :class="{
                    'border-red-500 focus:border-red-500 focus:ring-red-500': lastNameErrors.length > 0,
                  }"
                />
                <span v-if="lastNameErrors.length > 0" class="text-xs text-red-600">{{ lastNameErrors[0] }}</span>
                <span
                  v-if="details.last_name === '' && lastNameErrors.length === 0 && requireFullValidity"
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->

        <!-- Grid Alter -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufph" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Alter {{ requireFullValidity ? '*' : '' }}
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9 space-y-3">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-3">
                <input
                  type="number"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                  placeholder="Jahre"
                  v-model="details.age"
                  @input="localAge = $event.target.value"
                  :class="{
                    'border-red-500 focus:border-red-500 focus:ring-red-500': ageErrors.length > 0,
                  }"
                />
                <span v-if="ageErrors.length > 0" class="text-xs text-red-600">{{ ageErrors[0] }}</span>
                <!-- if ageErrors not set but age undefined -->
                <span
                  v-if="details.age === '' && ageErrors.length === 0 && requireFullValidity"
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Grid Geschlecht -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Geschlecht {{ requireFullValidity ? '*' : '' }}
            </label>
          </div>
          <!-- End Col -->

          <div
            class="sm:col-span-9"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': sexErrors.length > 0,
            }"
          >
            <div class="sm:flex">
              <label
                class="flex py-2 px-3 w-full border border-gray-200 -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              >
                <input
                  type="radio"
                  v-model="details.sex"
                  value="MALE"
                  class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                  @input.prevent="localSex = $event.target.value"
                />
                <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">männlich</span>
              </label>

              <label
                class="flex py-2 px-3 w-full border border-gray-200 -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              >
                <input
                  type="radio"
                  v-model="details.sex"
                  value="FEMALE"
                  class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                  @input.prevent="localSex = $event.target.value"
                />
                <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">weiblich</span>
              </label>

              <label
                class="flex py-2 px-3 w-full border border-gray-200 -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              >
                <input
                  type="radio"
                  v-model="details.sex"
                  value="DIVERSE"
                  class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                  @input.prevent="localSex = $event.target.value"
                />
                <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">divers</span>
              </label>
            </div>
            <span v-if="!sexIsValid && requireFullValidity" class="text-xs text-red-600">Dies ist ein Pflichtfeld</span>
          </div>
        </div>

        <!-- Grid Füllen -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"> Details </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <!-- Logo Upload Group -->
            <div class="flex flex-wrap items-center gap-3 sm:gap-5">
              <div class="grow">
                <div class="flex-col items-center justify-start gap-x-2">
                  <button
                    @click="
                      async (event) => {
                        console.log('generate started');
                        event.stopPropagation();
                        await generatePerson();
                        emit('scrollToBottom');
                      }
                    "
                    :disabled="
                      !details.role ||
                      (!descriptionFilled && !nameAndAgeFilled) ||
                      (showDetails && !freehandDescriptionChangedAfterGenerate)
                    "
                    type="button"
                    class="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <!--                    <span translate="no" class="material-symbols-outlined notranslate"> checklist_rtl </span>-->
                    <span translate="no" class="material-symbols-outlined notranslate">manufacturing</span>
                    Generieren
                  </button>
                  <div
                    v-if="
                      !(
                        !details.role ||
                        (!descriptionFilled && !nameAndAgeFilled) ||
                        (showDetails && !freehandDescriptionChangedAfterGenerate)
                      )
                    "
                    class="text-blue-600 text-xs"
                  >
                    Klicken zum Abschließen
                  </div>
                  <div v-else-if="isGenerating" class="text-gray-500 text-xs">
                    Du kannst mit der nächsten Person oder dem nächsten Schritt fortfahren und das Ergebnis später
                    kontrollieren.
                  </div>
                </div>
              </div>
            </div>
            <!-- End Logo Upload Group -->
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->
      </div>

      <!-- Person  Details -->
      <div
        class="p-2 space-y-5 transition-all duration-1000"
        :class="{
          '-mt-5 max-h-0 h-0 opacity-100': !showDetails,
          'mt-0 max-h-[58px] h-[58px] opacity-100': showDetails && isGenerating,
          'mt-0 h-[150px] max-h-[500px] opacity-100': showDetails && isGenerating && !showPatientDetails,
          'mt-0 h-[500px] max-h-[1500px] opacity-100': showDetails && isGenerating && showPatientDetails,
        }"
      >
        <PersonDetails
          :model-value="details"
          @update:model-value="(value) => (details = value)"
          :showDetails="showDetails"
          :showPatientDetails="showPatientDetails"
          :isLoading="isGenerating"
          :requireCompleteness="requireFullValidity"
          :startingFromCompleteCase="startingFromCompleteCase"
          @click="emit('click')"
          @scrollToBottom="emit('scrollToBottom')"
          @isValid="detailsValidityChanged"
        />
      </div>
    </div>
  </form>
</template>

<style scoped></style>
