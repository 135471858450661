<script setup lang="ts">
declare global {
  interface ImportMeta {
    env: {
      DEV: boolean;
    };
  }
}

import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { Mic, Send, ArrowRight, CircleCheckBig } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import AudioRecorderService from '@/services/AudioRecorderService';
import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  allowTextInput: {
    type: Boolean,
    default: true,
  },
  allowAudioInput: {
    type: Boolean,
    default: true,
  },
  hideSubmitButton: {
    type: Boolean,
    default: false,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
  isFinished: {
    type: Boolean,
    default: false,
  },
  ttsContext: {
    type: [String, null],
    default: null,
  },
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  input: [event: Event];
  submit: [];
  next: [];
  close: [];
}>();

const textarea = ref<HTMLTextAreaElement | null>(null);

const caseInteractionStore = useCaseInteractionStore();
const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

const audioService = new AudioRecorderService();
const { isRecording, transcriptionInProgress } = audioService;

const isStartingRecording = ref(false);
const delayedIsRecording = ref(false);
const buttonScale = computed(() => (isStartingRecording.value ? 'scale(0.85)' : 'scale(1)'));
const buttonType = computed(() => {
  if (props.isFinished) {
    return 'finish';
  }

  if (props.isSubmitted) {
    return 'next';
  }

  if (delayedIsRecording.value) {
    return 'recording';
  }

  if (props.modelValue.length > 0 && !props.hideSubmitButton) {
    return 'submit';
  }

  return 'default';
});

const buttonClasses = computed(() => {
  // default classes
  const classes = ['rounded-[48px] gap-x-2 px-4 py-3 text-white font-semibold select-none'];

  if (buttonType.value === 'default') {
    classes.push('bg-black'); // Default state
  } else if (buttonType.value === 'recording') {
    classes.push('bg-blue'); // Recording state
  } else if (buttonType.value === 'submit') {
    classes.push('bg-orange'); // Has text state
  } else if (buttonType.value === 'next') {
    classes.push('bg-green'); // Next state
  } else if (buttonType.value === 'finish') {
    classes.push('bg-green'); // Finish state
  }

  return classes;
});

const adjustTextareaHeight = () => {
  const target = textarea.value;
  if (!target) return;

  target.style.height = 'auto';
  target.style.height = `${Math.max(48, target.scrollHeight)}px`;
};

const onInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  emit('update:modelValue', target.value);
  emit('input', event);
};

// Handle speech-to-text result
const updateModelValue = (text: string) => {
  // textarea.value is the textarea itself, .value.value is the textarea's text
  if (!text || !textarea.value) return;
  console.log('onTranscriptionResult', text);
  emit('update:modelValue', text);

  textarea.value.value = text;
};

watch(
  () => [props.modelValue, props.placeholder],
  async (text) => {
    await nextTick();
    adjustTextareaHeight();
  },
);

watch(isRecording, (newVal) => {
  if (newVal) {
    // When starting recording, delay by 200ms
    setTimeout(() => {
      delayedIsRecording.value = true;
    }, 200);
  } else {
    // When stopping recording, update immediately
    delayedIsRecording.value = false;
  }
});

const startRecording = async () => {
  if (props.disabled || isRecording.value) return;

  isStartingRecording.value = true;
  setTimeout(() => {
    isStartingRecording.value = false;
  }, 300);

  await audioService.startRecording();
};

const stopRecording = async () => {
  if (!isRecording.value) return;
  const transcribedText = await audioService.stopRecording(
    currentCaseInteractionLanguage.value || 'deu',
    props.ttsContext,
  );
  if (!transcribedText) return;
  updateModelValue(transcribedText);
};

onMounted(() => {
  audioService.requestMicrophoneAccess();
});

onBeforeUnmount(() => {
  audioService.cleanup();
});
</script>

<template>
  <div class="mobile-textarea-container flex flex-row mt-auto items-end gap-x-2 px-4 py-6">
    <div v-if="!isSubmitted && allowTextInput" class="relative flex flex-grow">
      <textarea
        ref="textarea"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled || transcriptionInProgress"
        :rows="1"
        class="flex grow bg-gray-light border-none rounded-3xl max-h-48 px-4 py-3 resize-none focus:outline-none focus:ring-0"
        @input="onInput"
        @keydown.prevent.enter="emit('submit')"
      />

      <div v-if="transcriptionInProgress" class="absolute w-full h-full flex justify-center items-center">
        <span class="animate-spin inline-block w-5 h-5 border-[3px] border-blue border-t-transparent rounded-full" />
      </div>
    </div>

    <div class="ml-auto">
      <button
        v-if="(buttonType === 'default' || buttonType === 'recording') && allowAudioInput"
        @mousedown.prevent="startRecording"
        @mouseup.prevent="stopRecording"
        @touchstart.prevent="startRecording"
        @touchend.prevent="stopRecording"
        @touchcancel.prevent="stopRecording"
        @contextmenu.prevent
        draggable="false"
        type="button"
        class="transition-all duration-300"
        :style="{
          transform: buttonScale,
          transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        }"
        :class="buttonClasses"
        data-testid="audio-recorder-ptt"
      >
        <div class="flex flex-row items-center gap-x-2">
          <Mic :size="20" />
          <span>{{ t('message.language') }}</span>
        </div>
      </button>

      <button v-if="buttonType === 'submit'" :class="buttonClasses" @click="emit('submit')">
        <div class="flex flex-row items-center gap-x-2">
          <Send :size="20" />
          <span>{{ t('message.check') }}</span>
        </div>
      </button>

      <button v-if="buttonType === 'next'" :class="buttonClasses" @click="emit('next')">
        <div class="flex flex-row items-center gap-x-2">
          <ArrowRight :size="20" />
          <span>{{ t('message.next') }}</span>
        </div>
      </button>

      <button v-if="buttonType === 'finish'" :class="buttonClasses" @click="emit('close')">
        <div class="flex flex-row items-center gap-x-2">
          <CircleCheckBig :size="20" />
          <span>{{ t('message.done') }} </span>
          🎉
        </div>
      </button>
    </div>
  </div>
</template>

<style scoped>
.recording-button-enter-active,
.recording-button-leave-active {
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.recording-button-enter-from,
.recording-button-leave-to {
  transform: scale(0.95);
}
</style>
