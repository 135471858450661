<script setup lang="ts">
import { computed } from 'vue';
import { GraduationCap, CheckCircle } from 'lucide-vue-next';

const props = defineProps({
  testIsFinished: {
    type: Boolean,
    default: false,
  },
  exerciseIsCompleted: {
    type: Boolean,
    default: false,
  },
  withBottomNav: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['startVocabTest', 'finishExercise', 'repeatVocabTest']);

const buttonType = computed(() => {
  if (props.exerciseIsCompleted) {
    return 'repeatVocabTest';
  }

  if (props.testIsFinished) {
    return 'finish';
  }

  return 'startVocabTest';
});

const buttonClasses = computed(() => {
  // default classes
  const classes = [
    'flex text-white px-4 py-3 rounded-full text-base font-semibold gap-x-2 pointer-events-auto cursor-pointer',
  ];

  if (buttonType.value === 'startVocabTest') {
    classes.push('bg-black'); // Default state
  } else if (buttonType.value === 'finish') {
    classes.push('bg-orange'); // Has text state
  } else if (buttonType.value === 'repeatVocabTest') {
    classes.push('bg-green hover:bg-black'); // Next state
  }

  return classes;
});
</script>

<template>
  <div
    class="fixed left-0 flex justify-center w-full items-center mb-6 pointer-events-none"
    :class="props.withBottomNav ? 'bottom-16' : 'bottom-0'"
    @click="
      async () => {
        if (buttonType === 'startVocabTest') emit('startVocabTest');
        else if (buttonType === 'repeatVocabTest') emit('repeatVocabTest');
        else if (buttonType === 'finish') emit('finishExercise');
      }
    "
  >
    <div :class="buttonClasses" v-if="buttonType === 'startVocabTest'">
      <GraduationCap :size="20" />
      Übung starten
    </div>
    <div :class="buttonClasses" v-else-if="buttonType === 'finish'">
      <CheckCircle :size="20" />
      Übung abschließen
    </div>
    <div :class="buttonClasses" v-else-if="buttonType === 'repeatVocabTest'">
      <GraduationCap :size="20" />
      Nochmal üben
    </div>
  </div>
</template>
