<script setup lang="ts">
import { ref, computed, reactive, onMounted } from 'vue';
import CaseInteractionList from '@/components/cases/CaseInteractionList.vue';
import { router } from '@/router';
import { useAuthStore, useCaseInteractionStore, useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { ShieldCheck, ShieldEllipsis, SquareStack, SquareDot } from 'lucide-vue-next';
import BottomNav from '@/components/BottomNav.vue';
const caseStore = useCaseStore();
const authStore = useAuthStore();
const { currentCase } = storeToRefs(caseStore);
const caseInteractionStore = useCaseInteractionStore();

const { allUserCaseInteractions } = storeToRefs(caseInteractionStore);

const fetchCompleted = ref(false);
const showUniqueCasesOnly = ref(true);
const showActiveCasesOnly = ref(true);
const bottomNav = ref<HTMLDivElement | null>(null);
const innerHeader = ref<HTMLDivElement | null>(null);
const props = defineProps({
  inlineOverflowingX: {
    type: Boolean,
    default: false,
  },
  outerHeaderHeight: {
    type: Number,
    default: 0,
  },
});

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;
  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await router.isReady();

  await caseInteractionStore.loadCaseInteractionsOfUser(true).finally(() => {
    fetchCompleted.value = true;
  });
});

const computedStyleScrollContainer = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px - ${innerHeader.value?.scrollHeight}px - ${bottomNav.value?.scrollHeight}px - 32px )`,
  };
});
</script>

<template>
  <div
    ref="innerHeader"
    v-show="fetchCompleted"
    class="flex items-center justify-between px-4 pt-4 pb-2 bg-gray-light dark:bg-neutral-800 text-sm"
  >
    <button
      @click.passive="showActiveCasesOnly = !showActiveCasesOnly"
      class="flex items-center ml-1"
      :class="{
        'font-semibold text-black dark:text-white': showActiveCasesOnly,
        'text-gray-500 dark:text-gray-400': !showActiveCasesOnly,
      }"
    >
      <ShieldCheck v-show="!showActiveCasesOnly" :size="20" class="mr-1" />
      <ShieldEllipsis v-show="showActiveCasesOnly" :size="20" class="mr-1" />
      {{ $t(showActiveCasesOnly ? 'message.showing_active' : 'message.showing_active_and_completed') }}
    </button>

    <button
      @click.passive="showUniqueCasesOnly = !showUniqueCasesOnly"
      class="flex items-center ml-auto"
      :class="{
        'font-semibold text-black dark:text-white': showUniqueCasesOnly,
        'text-gray-500 dark:text-gray-400': !showUniqueCasesOnly,
      }"
    >
      <SquareStack v-show="!showUniqueCasesOnly" :size="20" class="mr-1" />
      <SquareDot v-show="showUniqueCasesOnly" :size="20" class="mr-1" />
      {{ $t(showUniqueCasesOnly ? 'message.showing_unique' : 'message.showing_all') }}
    </button>
  </div>

  <div class="min-w-full flex overflow-auto" :style="computedStyleScrollContainer">
    <CaseInteractionList
      :caseInteractions="allUserCaseInteractions"
      :isLoading="!fetchCompleted"
      :showUniqueCasesOnly="showUniqueCasesOnly"
      :showActiveCasesOnly="showActiveCasesOnly"
    />
  </div>

  <div>
    <BottomNav ref="bottomNav" />
  </div>
</template>

<style></style>
