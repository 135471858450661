<script setup>
import { useRouter } from 'vue-router';
import { useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

const caseStore = useCaseStore();
const { allCases } = storeToRefs(caseStore);
const router = useRouter();

// Fetch cases when component mounts
onMounted(async () => {
  if (allCases.value.length === 0) {
    await caseStore.fetchAllCases();
  }
});

const randomCase = computed(() => {
  if (allCases.value.length === 0) return null;
  return allCases.value[Math.floor(Math.random() * allCases.value.length)];
});

const caseHeading = computed(() => {
  return randomCase.value?.details?.heading || 'Start a new medical case';
});

async function startNewCase() {
  if (randomCase.value) {
    await router.push('/cases/' + randomCase.value.id);
  }
}
</script>

<template>
  <div
    @click="startNewCase"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col h-[198px]"
  >
    <!-- Header -->
    <div class="flex-1">
      <div class="flex items-start justify-between">
        <div class="space-y-1">
          <p class="text-sm text-gray-600 dark:text-gray-400">{{ $t('message.startDailyCase') }}</p>
          <h3 class="text-xl font-medium text-gray-900 dark:text-white">
            {{ caseHeading }}
          </h3>
        </div>
      </div>
    </div>

    <!-- Start Button -->
    <div class="flex items-center justify-between mt-auto">
      <button
        class="inline-flex items-center gap-2 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
      >
        <span class="material-symbols-outlined">play_arrow</span>
        {{ $t('message.start') }}
      </button>
      <div class="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
        <span>+80 XP</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
