<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps(nodeViewProps);

interface HTMLElementEvent extends Event {
  target: HTMLElement;
  clientX: number;
  clientY: number;
}

const hintTextarea = ref<HTMLTextAreaElement | null>(null);
const isMaximized = ref(false);
const rootContainer = ref<HTMLElement | null>(null);
const placeholder = ref('Gesucht');
const attemptOrSolutionInput = ref<HTMLInputElement | null>(null);
const isSolved = ref(false);
const isWrong = ref(false);
const uuid = uuidv4();
const shuffledAnswers = ref<string[]>([]);

onMounted(async () => {
  if (!props.editor.isEditable) placeholder.value = '_'.repeat(props.node.attrs.solutionText.length);

  if (!!attemptOrSolutionInput.value) {
    await nextTick();
    attemptOrSolutionInput.value.focus();
  }

  if (!props.editor.isEditable) {
    shuffledAnswers.value = generateShuffledAnswers();
    props.editor.emit('nodeSolved', {
      id: uuid,
      isSolved: false,
    });
    props.editor.emit('nodeAttempted', {
      id: uuid,
      isAttempted: false,
    });
  }

  await nextTick();
  isMaximized.value = false;
});

const isAttempted = computed(() => isSolved.value || isWrong.value);

watch(
  () => isWrong.value,
  (newVal) => {
    console.log('maximize because wrong');
    if (newVal) {
      setTimeout(() => {
        isMaximized.value = true;
      }, 500); // wait for close animations to finish!
    }
  },
);

watch(
  () => isAttempted.value,
  (newVal) => {},
  // NOTE: we count an MC cloze as attempted only once solved!
);

watch(
  () => isSolved.value,
  async (newVal) => {
    props.editor.emit('nodeAttempted', {
      id: uuid,
      isAttempted: true,
    });
    props.editor.emit('nodeSolved', {
      id: uuid,
      isSolved: newVal,
    });
    await new Promise((resolve) => setTimeout(resolve, 300)); // else animations interfere
    isMaximized.value = false;
  },
);

watch(
  () => props.node.attrs.attemptText,
  (newVal) => {
    if (newVal !== '') return;
    // so we have been reset:
    isWrong.value = false;
    isSolved.value = false;
  },
);

const delayedPositionClasses = ref('relative');
const appearAtOnceDisappearDelayed = ref(false);
const handleClickOutside = (event: HTMLElementEvent) => {
  if (!rootContainer.value) return;
  let rect = rootContainer.value.getBoundingClientRect();
  isMaximized.value =
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom;
};

const debounceHandleClickOutside = debounce(handleClickOutside, 200);

const innerClozeInputWidth = computed(() => {
  if (!solution.value) return '8ch';
  if (isMaximized.value) {
    return '100%';
  }
  return 0.9 * Math.max(solution.value.length + 1, 9) + 'ch';
});

const outerClozeInputWidthNonExpanding = computed(() => {
  if (!solution.value) return '9ch';
  return 0.9 * Math.max(solution.value.length + 2, 10) + 'ch';
});

watch(
  () => isMaximized.value,
  async (nowMaximized) => {
    if (nowMaximized) {
      // Switch to absolute for symmetric opening
      window.addEventListener('click', debounceHandleClickOutside);
      delayedPositionClasses.value = 'absolute top-0 border-2';
      appearAtOnceDisappearDelayed.value = true;
      await nextTick();
      updateMinTextareaHeight(hintTextarea.value, 1);
      return;
    }

    window.removeEventListener('click', debounceHandleClickOutside);
    // Wait for collapse animation to complete (height transition)
    appearAtOnceDisappearDelayed.value = true;
    delayedPositionClasses.value = 'absolute -top-[9px] left-0 border-2';
    await new Promise((resolve) => setTimeout(resolve, 100));

    // After collapse finishes, switch back to relative to reflow inline
    delayedPositionClasses.value = 'relative bg-transparent border-none';
    appearAtOnceDisappearDelayed.value = false;
  },
  { immediate: true },
);

const solutionOrAttempt = computed({
  get() {
    return props.editor.isEditable ? props.node.attrs.solutionText : props.node.attrs.attemptText;
  },
  set(value) {
    if (props.editor.isEditable) {
      props.node.attrs.solutionText = value;
    } else {
      props.node.attrs.attemptText = value;
    }
  },
});

const solution = computed(() => props.node.attrs.solutionText);
const wrongAnswers = computed(() => {
  try {
    return props.node.attrs.wrongAnswers ? JSON.parse(props.node.attrs.wrongAnswers) : [];
  } catch (e) {
    console.error('Failed to parse wrongAnswers:', e, ' when parsing ', props.node.attrs.wrongAnswers);
    return [];
  }
});

watch(
  () => props.node.attrs.hintText,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: 'hint',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => props.node.attrs.wrongAnswers,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: 'wrongAnswers',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => solutionOrAttempt.value,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: props.editor.isEditable ? 'solution' : 'attempt',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => isMaximized.value,
  async (newVal) => {
    if (newVal) {
      await nextTick();
      updateMinTextareaHeight(hintTextarea.value, 1);
    }
  },
);

const newWrongAnswer = ref('');
const addWrongAnswer = () => {
  if (!newWrongAnswer.value.trim()) return;

  // Parse existing solutions from JSON string, or start with empty array
  const currentWrongAnswers = props.node.attrs.wrongAnswers ? JSON.parse(props.node.attrs.wrongAnswers) : [];

  const updatedWrongAnswers = [...currentWrongAnswers, newWrongAnswer.value.trim()];

  console.log('updatedWrongAnswers', updatedWrongAnswers);

  // Store as JSON string
  props.node.attrs.wrongAnswers = JSON.stringify(updatedWrongAnswers);

  console.log('updatedWrongAnswers result', props.node.attrs.wrongAnswers);

  // Clear the input
  newWrongAnswer.value = '';
};

const removeWrongAnswer = (index: number) => {
  const currentWrongAnswers = props.node.attrs.wrongAnswers ? JSON.parse(props.node.attrs.wrongAnswers) : [];

  currentWrongAnswers.splice(index, 1);

  props.node.attrs.wrongAnswers = JSON.stringify(currentWrongAnswers);
};

const generateShuffledAnswers = () => {
  const allAnswers = [props.node.attrs.solutionText, ...wrongAnswers.value];
  // Fisher-Yates shuffle
  for (let i = allAnswers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allAnswers[i], allAnswers[j]] = [allAnswers[j], allAnswers[i]];
  }
  return allAnswers;
};

const handleAnswerSelection = (selectedAnswer: string) => {
  if (props.editor.isEditable) return;

  props.node.attrs.attemptText = selectedAnswer;
  isWrong.value = selectedAnswer !== props.node.attrs.solutionText;
  isSolved.value = selectedAnswer === props.node.attrs.solutionText;
};
</script>

<template>
  <node-view-wrapper
    class="relative inline-flex w-1 items-center align-middle -translate-y-[2px] -my-1"
    as="span"
    :class="{
      'z-[10]': isMaximized,
      'z-1': !isMaximized,
    }"
    @click="isMaximized = true"
    :style="{
      verticalAlign: 'center',
      lineHeight: 'inherit',
      width: outerClozeInputWidthNonExpanding,
    }"
  >
    <!-- Placeholder to maintain space when absolute/ maxed -->
    <span
      v-if="appearAtOnceDisappearDelayed"
      :style="{
        width: outerClozeInputWidthNonExpanding,
      }"
    ></span>

    <span
      ref="rootContainer"
      :class="[
        'transition-all duration-100 transform',
        isMaximized ? 'top-0 left-0 rounded-lg ' : '',
        !isSolved && !isWrong ? 'border-blue-600 bg-transparent' : '',
        isSolved ? 'border-teal-500 bg-teal-50' : '',
        isWrong ? 'border-red-500 bg-red-50' : '',
        delayedPositionClasses,
      ]"
      :style="{
        transform: isMaximized ? 'translateY(-36px)' : 'translateY(0)',
      }"
    >
      <label
        class="text-white w-full px-1 transition-all duration-100 transform overflow-hidden text-xs font-bold rounded-b-none rounded-t-md flex justify-between items-center"
        :class="{
          'py-1 ': isMaximized,
          'bg-blue-600': !isSolved && !isWrong,
          'bg-teal-500': isSolved,
          'bg-red-500': isWrong,
        }"
        contenteditable="false"
        :style="{ height: isMaximized ? '16px' : '0', lineHeight: isMaximized ? '16px' : '0' }"
        >{{ props.editor.isEditable ? 'MC-Lücke' : 'Auswahl' }}
        <span
          v-show="props.editor.isEditable"
          draggable="true"
          data-drag-handle
          translate="no"
          class="pl-1 material-symbols-outlined no-translate text-lg cursor-move"
        >
          drag_indicator
        </span>
      </label>

      <span class="flex-col flex transition-all duration-100 transform" :class="{ 'my-1 gap-y-1': isMaximized }">
        <!-- Edit mode - keep existing input -->
        <input
          v-if="props.editor.isEditable"
          @focusin="isMaximized = true"
          ref="attemptOrSolutionInput"
          contenteditable="true"
          class="mx-1 my-0 px-1 border transition-all duration-100 transform border-gray-300 rounded-md text-base md:text-sm"
          :class="{
            'focus:border-blue-600 focus:ring-blue-600 bg-gray-50': !isSolved && !isWrong,
            'focus:border-teal-600 focus:ring-teal-600 bg-teal-50': isSolved,
            'focus:border-red-600 focus:ring-red-600 bg-red-50': isWrong,
            'py-1': isMaximized,
            'py-0': !isMaximized,
          }"
          :style="{ width: innerClozeInputWidth }"
          :placeholder="placeholder"
          spellcheck="false"
          autocomplete="off"
          autocorrect="off"
          v-model="solutionOrAttempt"
        />

        <!-- View mode - show selected answer or placeholder -->
        <div
          v-else-if="!isMaximized"
          class="mx-1 my-0 px-1 whitespace-nowrap min-h-[1.5rem] flex items-center cursor-pointer border transition-all duration-100 transform border-gray-300 rounded-md text-base md:text-sm"
          :class="{
            'bg-gray-50': !isSolved && !isWrong,
            'bg-teal-50': isSolved,
            'bg-red-50': isWrong,
          }"
          :style="{ width: innerClozeInputWidth }"
        >
          {{ isAttempted ? solutionOrAttempt : placeholder }}
        </div>

        <!-- Multiple choice options container -->
        <div
          class="flex-col mx-1 flex bg-gray-50 mx-auto overflow-hidden transition-all duration-100 transform"
          :class="{
            'h-fit': isMaximized,
            'h-0': !isMaximized,
          }"
          :style="{
            width: innerClozeInputWidth,
          }"
        >
          <!-- Edit mode content - keep existing -->
          <div v-if="props.editor.isEditable" class="flex-col flex gap-1 w-full">
            <div class="flex items-center gap-1">
              <input
                type="text"
                class="flex-grow px-1 mx-1 py-0 text-xs border border-gray-300 rounded-md focus:border-blue-600 focus:ring-blue-600"
                placeholder="Falschantwort hinzufügen"
                v-model="newWrongAnswer"
                @keyup.enter="addWrongAnswer"
              />
              <button
                @click="addWrongAnswer"
                class="px-2 py-0.5 text-xs text-white bg-blue-600 rounded-md hover:bg-blue-700"
                :disabled="!newWrongAnswer"
              >
                +
              </button>
            </div>
            <div class="flex flex-wrap gap-1">
              <span
                v-for="(wrongAnswer, index) in wrongAnswers"
                :key="index"
                class="flex items-center gap-1 px-2 py-0.5 text-xs bg-gray-100 rounded-full"
              >
                {{ wrongAnswer }}
                <button @click="removeWrongAnswer(index)" class="text-gray-500 hover:text-gray-700">×</button>
              </span>
            </div>
          </div>

          <!-- View mode - multiple choice options -->
          <div v-else-if="isMaximized" class="flex flex-col gap-1 p-1">
            <button
              v-for="answer in shuffledAnswers"
              :key="answer"
              @click="handleAnswerSelection(answer)"
              class="px-2 py-1 whitespace-nowrap text-left text-sm border rounded-md transition-colors"
              :class="{
                'border-gray-300 hover:bg-gray-50': !solutionOrAttempt,
                'bg-teal-50 border-teal-500': solutionOrAttempt === answer && answer === props.node.attrs.solutionText,
                'bg-red-50 border-red-500': solutionOrAttempt === answer && answer !== props.node.attrs.solutionText,
                'border-gray-300': solutionOrAttempt && solutionOrAttempt !== answer,
              }"
            >
              {{ answer }}
            </button>
          </div>

          <!-- Hint text area - keep existing -->
          <textarea
            v-show="props.editor.isEditable || !!props.node.attrs.hintText"
            ref="hintTextarea"
            contenteditable="true"
            class="px-1 resize-none rounded-md flex w-full text-gray-800 focus:ring-blue-600"
            :class="{
              'mx-1 bg-gray-50 text-base focus:border-blue-600 py-0': props.editor.isEditable,
              'text-xs': !props.editor.isEditable,
              'bg-transparent border-none': !isSolved && !isWrong,
              'bg-teal-50 border-none': isSolved,
              'bg-red-50 border-none': isWrong,
            }"
            placeholder="Hinweis"
            rows="1"
            v-model="props.node.attrs.hintText"
            @input="updateMinTextareaHeight($event)"
            :disabled="!props.editor.isEditable"
          />
        </div>
      </span>
    </span>
  </node-view-wrapper>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
