<script setup lang="ts">
// Add SoundMeter type definition first
interface SoundMeter {
  context: AudioContext;
  instant: number;
  slow: number;
  clip: number;
  script: ScriptProcessorNode;
  mic?: MediaStreamAudioSourceNode;
  connectToSource: (stream: MediaStream, callback?: (error: Error | null) => void) => void;
  stop: () => void;
}

declare global {
  interface Window {
    AudioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
    audioContext?: AudioContext;
    soundMeter?: SoundMeter;
    isRecording?: boolean;
    lastTranscriptionAttempt?: number;
    lastTranscriptionText?: string;
    refusedTranscriptionText?: string;
  }
}

import { getApiClient } from '@/apiclient/client';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { storeToRefs } from 'pinia';

import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore } from '@/stores';
import { ref, computed, watch, onMounted, onUnmounted, onBeforeUnmount } from 'vue';

import { SoundMeter } from '/src/audio/soundmeter.js';
import { useAudioStore } from '@/stores/audio.store';
import AudioRecorderService from '@/services/AudioRecorderService';

const AUDIO_TYPE = 'audio';

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  reset: {
    default: false,
    type: Boolean,
  },
  size: {
    type: String,
    default: 'normal',
    validator: (value: string) => ['small', 'normal'].includes(value),
  },
  testIdSuffix: {
    type: String,
    default: '',
  },
  ttsContext: {
    type: String,
    default: null,
  },
  // unlockAudioContext: {
  //   type: Function,
  //   default: null,
  // },
});

const audioService = new AudioRecorderService();
const { isRecording, transcriptionInProgress, volume } = audioService;

const mockProgress = ref(0);

const emit = defineEmits<{
  newTranscription: [text: string];
  transcriptionInProgress: [inProgress: boolean];
  resetComplete: [];
}>();

const caseInteractionStore = useCaseInteractionStore();
const alertStore = useAlertStore();
const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

const containerClasses = computed(() => ({
  'h-32 w-32 lg:h-44 lg:w-44': props.size === 'normal',
  'h-8 w-8': props.size === 'small',
}));

function assert(condition: any, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

function startMockProgress(timeInMs: number) {
  // linearly increment mockProgress from 0 to 100 over timeInMs milliseconds
  mockProgress.value = 0;
  const interval = setInterval(() => {
    mockProgress.value += 1;
    if (mockProgress.value >= 100) {
      clearInterval(interval);
      mockProgress.value = 0;
    }
  }, timeInMs / 100);
}

const buttonClasses = computed(() => ({
  'h-24 w-24 lg:h-32 lg:w-32': props.size === 'normal',
  'h-8 w-8': props.size === 'small',
  'bg-gray-500 cursor-not-allowed focus:z-10 group-focus:outline-none shadow-none shadow-gray-400': props.disabled,
  'bg-blue-300 cursor-pointer group-hover:bg-blue-300 shadow-none shadow-gray-400':
    !props.disabled && transcribedText.value,
  'bg-blue-600 cursor-pointer group-hover:bg-blue-700 shadow-none shadow-gray-400':
    !props.disabled && !transcribedText.value && !isRecording.value,
  'bg-red-500 cursor-pointer group-hover:bg-red-600 shadow-inner shadow-none shadow-red-400': isRecording.value,
  'shadow-none': mockProgress.value > 20,
}));

const iconClasses = computed(() => ({
  'text-8xl md:text-7xl lg:text-8xl lg:-mt-2': props.size === 'normal',
  'text-3xl': props.size === 'small',
}));

const recorder = ref(null);
const stream = ref(null);
const isStopped = ref(true);
const isPaused = ref(false);
const transcribedText = ref('');
const pttStartTime = ref(null);
const pttStopTime = ref(null);
const minPttDuration = 500; // in ms

const audioStore = useAudioStore();

const isDev = !!import.meta.env.DEV;

watch(isRecording, (newVal, oldVal) => {
  if (isDev) {
    console.log(`isRecording changed from ${oldVal} to ${newVal}`);
    window.isRecording = newVal;
  }
});

async function startRecording() {
  if (props.disabled || isRecording.value) {
    console.log('Early return - disabled:', props.disabled, 'isRecording:', isRecording.value);
    return;
  }
  console.log('START');
  startMockProgress(300);
  pttStartTime.value = new Date();

  await audioService.startRecording();
}

async function stopRecording() {
  if (!isRecording.value) {
    console.log('Early return from stopRecording - not recording');
    return;
  }

  pttStopTime.value = new Date();

  if (pttStopTime.value - pttStartTime.value < minPttDuration) {
    console.log('Recording too short. Not sending.');
    alertStore.info('Mikrofon-Knopf gedrückt halten, um zu sprechen.');
    return;
  }

  emit('transcriptionInProgress', true);
  startMockProgress(800);
  const transcribedText = await audioService.stopRecording(
    currentCaseInteractionLanguage.value || 'deu',
    props.ttsContext,
  );
  if (!transcribedText) {
    emit('transcriptionInProgress', false);
    return;
  }
  emit('newTranscription', transcribedText);
  emit('transcriptionInProgress', false);
  startMockProgress(4000);
}

// Watch reset prop
watch(
  () => props.reset,
  (newVal) => {
    if (newVal) {
      console.debug('Resetting audio recorder');
      transcribedText.value = '';
      emit('resetComplete'); // notify parent that reset is complete
    }
  },
);

// Computed properties for visual feedback
const showHalo = computed(() => {
  // Determine whether to show the halo based on volume
  return isRecording.value && volume.value > 0;
});

const haloSize = computed(() => {
  // Calculate halo size based on volume
  return `${Math.min(184, 112 + (156 - 112) * (volume.value * 10))}px`;
});

const haloScale = computed(() => {
  return `${Math.min(1.4, 1 + volume.value * 10)}`;
});

const haloInverseScale = computed(() => {
  return `${1.1 / Math.min(1.4, 1 + volume.value * 10)}`;
});

onMounted(() => {
  audioService.requestMicrophoneAccess();
});

onBeforeUnmount(() => {
  audioService.cleanup();
});
</script>

<template>
  <!-- Vol: {{ volume }}. -->
  <div
    class="group pointer-events-none relative flex z-[90] items-center justify-center opacity-100 rounded-full select-none transition-all duration-300 ease-in-out"
    draggable="false"
    :class="containerClasses"
    @contextmenu.prevent
  >
    <div
      class="absolute flex z-[90] pointer-events-none items-center justify-center bg-red-400/50 rounded-full"
      :class="{
        'h-24 w-24 lg:h-32 lg:w-32': size === 'normal',
        'h-8 w-8': size === 'small',
      }"
      :style="{ transform: `scale(${haloScale})` }"
      @contextmenu.prevent
    >
      <div class="grid pointer-events-none" :style="{ transform: `scale(${haloInverseScale})` }">
        <div
          class="col-start-1 row-start-1 pointer-events-none relative items-center justify-center"
          :class="{
            'h-24 w-24 lg:h-32 lg:w-32': size === 'normal',
            'h-8 w-8': size === 'small',
          }"
        >
          <svg
            class="h-full w-full pointer-events-none"
            width="40"
            height="40"
            viewBox="0 0 36 36"
            xmlns="http://www.w3.org/2000/svg"
          >
            <!-- Background Circle -->
            <circle
              v-show="mockProgress > 0"
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current pointer-events-none text-transparent shadow-none shadow-gray-400"
              stroke-width="4"
            ></circle>
            <!-- Progress Circle inside a group with rotation -->
            <g class="origin-center -rotate-90 transform pointer-events-none">
              <circle
                v-show="mockProgress > 0"
                cx="18"
                cy="18"
                r="16"
                fill="none"
                class="stroke-current pointer-events-none transition-colors duration-300"
                :class="[
                  !disabled // if so: grey. Else: blue
                    ? !transcribedText // If filled: light blue. If not: dark blue (not recording) or red (recording)
                      ? isRecording
                        ? 'text-red-500 group-hover:text-red-600 shadow-inner shadow-none shadow-red-400 '
                        : 'text-blue-600 group-hover:text-blue-600 shadow-none shadow-gray-400  '
                      : 'text-blue-300 group-hover:text-blue-300 shadow-none shadow-gray-400  '
                    : 'text-gray-500 focus:ring-2 focus:ring-blue-600 focus:z-10 focus:outline-none shadow-none shadow-gray-400',
                ]"
                stroke-width="4"
                stroke-dasharray="100"
                :stroke-dashoffset="100 - mockProgress"
              ></circle>
            </g>
          </svg>
        </div>
        <!-- actual button-->
        <button
          @mousedown.prevent="startRecording"
          @mouseup.prevent="stopRecording"
          @touchstart.prevent="startRecording"
          @touchend.prevent="stopRecording"
          @touchcancel.prevent="stopRecording"
          @contextmenu.prevent
          draggable="false"
          type="button"
          :style="{ transform: `scale(${1 / 1.1})` }"
          class="z-[90] robust-button row-start-1 col-start-1 pointer-events-auto flex border-none flex-shrink-0 opacity-100 justify-center items-center rounded-full text-white transition-colors duration-300"
          :class="buttonClasses"
          :data-testid="`audio-recorder-ptt-${testIdSuffix}`"
        >
          <div class="items-center opacity-100 group-hover:scale-100">
            <div class="items-center opacity-100">
              <div translate="no" class="material-symbols-outlined notranslate select-none" :class="iconClasses">
                mic
              </div>
            </div>
            <div
              class="items-center -mt-4 mb-2 hidden text-sm select-none"
              :class="{
                'lg:block': size === 'normal',
                hidden: size === 'small',
              }"
            >
              Push to talk
            </div>
            <div
              class="items-center -mt-2 mb-2 text-sm select-none"
              :class="{
                'hidden md:block lg:hidden': size === 'normal',
                hidden: size === 'small',
              }"
            >
              PTT
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes pulse {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.95;
  }
}

.animate-pulse {
  animation: pulse 50s infinite;
}

.robust-button {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* iOS-specific */
  -ms-user-select: none; /* IE/Edge */
  touch-action: manipulation; /* Prevent unwanted touch behaviors */
  pointer-events: auto; /* Ensure the button itself is still clickable */
}
</style>
