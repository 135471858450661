<script setup>
import { storeToRefs } from 'pinia';
import { useAlertStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const alertStore = useAlertStore();
const { alerts } = storeToRefs(alertStore);

const isSmallScreen = computed(() => window.innerWidth < 768);

const newestAlerts = computed(() => {
  // newest two alerts on small screens, three on larger screens
  if (isSmallScreen.value) return alerts.value.slice(0, 2);
  return alerts.value.slice(0, 3);
});
</script>

<template>
  <div class="container">
    <div v-for="alert in newestAlerts" :key="alert.id" class="relative mb-4">
      <div
        class="max-w-[450px] border cursor-pointer rounded-lg p-1 md:p-4"
        :class="{
          'bg-white dark:bg-neutral-800 dark:border-neutral-700 border-gray-200': alert.type !== 'xp',
          'bg-transparent border-transparent': alert.type === 'xp',
          'animate-bounce-in shadow-lg': alert.visible && alert.type !== 'xp',
          'animate-fade-out shadow-lg': !alert.visible && alert.type !== 'xp',
          'animate-xp-alert': alert.visible && alert.type === 'xp',
          'animate-fade-out-xp': !alert.visible && alert.type === 'xp',
        }"
        role="alert"
        tabindex="-1"
        aria-labelledby="hs-discovery-label"
        @click.prevent="alertStore.clearAlert(alert.id)"
      >
        <div class="flex">
          <div class="shrink-0">
            <span
              :class="{
                'text-red-400': alert.type === 'error',
                'text-green-400': alert.type === 'success',
                'text-blue-400': alert.type === 'info',
                'text-orange-500 text-7xl': alert.type === 'xp',
              }"
              translate="no"
              class="material-symbols-outlined notranslate"
            >
              {{
                alert.type === 'error'
                  ? 'error'
                  : alert.type === 'success'
                  ? 'task_alt'
                  : alert.type === 'xp'
                  ? 'exercise'
                  : 'info'
              }}
            </span>
          </div>
          <div class="md:ms-3">
            <h3
              id="hs-discovery-label"
              :class="{
                'text-gray-800 dark:text-white text-sm md:text-base': alert.type !== 'xp',
                'text-orange-500 text-5xl': alert.type === 'xp',
              }"
              class="font-semibold"
            >
              {{ t(alert.title) }}
            </h3>
            <p
              :class="{
                'text-gray-700 dark:text-neutral-400': alert.type !== 'xp',
                'text-orange-400': alert.type === 'xp',
              }"
              class="md:mt-2 text-sm"
            >
              {{ t(alert.message) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.animate-bounce-in {
  animation: bounce-in 0.5s ease-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.animate-fade-out {
  animation: fade-out 0.5s ease-out forwards;
}

@keyframes xp-alert {
  0% {
    transform: scale(0.8) translateZ(-100px);
    opacity: 0;
  }
  20% {
    transform: scale(1.1) translateZ(0);
    opacity: 1;
  }
  60% {
    transform: scale(1) translateZ(50px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateZ(0);
    opacity: 1;
  }
}

.animate-xp-alert {
  animation: xp-alert 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  perspective: 1000px;
}

@keyframes fade-out-xp {
  0% {
    opacity: 1;
    transform: scale(0.2) translateZ(0);
  }
  100% {
    opacity: 0;
    transform: scale(4) translateZ(100px);
  }
}

.animate-fade-out-xp {
  animation: fade-out-xp 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  perspective: 1000px;
}
</style>
