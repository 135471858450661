<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const bottomNavContainer = ref(null);
const navigate = (path: string) => {
  router.push(path);
};

const scrollHeight = computed(() => {
  return bottomNavContainer.value?.scrollHeight;
});

defineExpose({
  scrollHeight,
});
</script>

<template>
  <nav ref="bottomNavContainer" class="fixed bottom-0 left-0 w-full z-[30] lg:hidden bg-gray dark:bg-gray-800">
    <!-- Navigation content -->
    <div class="grid grid-cols-4 h-16 relative bg-gray dark:bg-gray-800">
      <!-- Home -->
      <button
        @click="navigate('/home')"
        class="flex flex-col items-center justify-center gap-1"
        :class="route.path === '/home' ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
        <span class="text-xs">{{ t('message.home') }}</span>
      </button>

      <!-- Courses -->
      <button
        @click="navigate('/my-courses')"
        class="flex flex-col items-center justify-center gap-1"
        :class="route.path.includes('/my-courses') ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
          />
        </svg>
        <span class="text-xs">{{ t('message.courses') }}</span>
      </button>

      <!-- Vocabulary -->
      <button
        @click="navigate('/vocab-dashboard')"
        class="flex flex-col items-center justify-center gap-1"
        :class="
          route.path.includes('/vocab-dashboard') ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
          />
        </svg>
        <span class="text-xs">{{ t('message.vocabulary') }}</span>
      </button>

      <!-- Cases -->
      <button
        @click="navigate(`/users/${useAuthStore().userId}/case-interactions`)"
        class="flex flex-col items-center justify-center gap-1"
        :class="
          route.path.includes('case-interactions') ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
        <span class="text-xs">{{ t('message.cases') }}</span>
      </button>
    </div>
  </nav>
</template>

<style scoped>
nav::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, transparent, rgb(232 232 232 / 1)); /* #E8E8E8 */
}

/* Dark mode gradient remains unchanged */
:global(.dark) nav::before {
  background: linear-gradient(to bottom, transparent, rgb(31 41 55 / 1));
}
</style>
