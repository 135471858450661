<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { router } from '@/router';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumbElement from '@/components/breadcrumbs/BreadcrumbElement.vue';
import { debounce } from 'lodash';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { headingToId } from '@/helper';
import CoursePage from '@/views/courses/CoursePage.vue';
import BreadcrumbSeparator from '@/components/breadcrumbs/BreadcrumbSeparator.vue';
import CourseSectionHeader from '@/views/courses/CourseSectionHeader.vue';
import EditCoursePage from '@/views/courses/EditCoursePage.vue';
import { HSDropdown } from 'preline';
import ChapterBreadcrumbElementWithDropdown from '@/components/breadcrumbs/ChapterBreadcrumbElementWithDropdown.vue';
import SectionBreadcrumbElementWithDropdown from '@/components/breadcrumbs/SectionBreadcrumbElementWithDropdown.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import CourseSectionTitlePage from '@/views/courses/CourseSectionTitlePage.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { CourseService } from '@/services/CourseService';
import SectionContentReorderModal from '@/components/courses/SectionContentReorderModal.vue';
import { getApiClient } from '@/apiclient/client';

const courseStore = useCourseStore();
const { currentCourse, currentChapter, currentChapterSortedSections, currentChapterTitle } = storeToRefs(courseStore);
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);

const courseInteractionStore = useCourseInteractionStore();

const alertStore = useAlertStore();
const isEditing = ref(false);
const isLoading = ref(false);
const innerHeader = ref(null);
const sectionId = ref('');
const pageTransitionName = ref('slide-right');
const sectionTransitionName = ref('slide-right');
const appendDummyPage = ref(false);
const userConformationModalDeleteSection = ref(null);

const chapterId = ref('');
const fullWidth = ref(1024);
const sectionScrollContainer = ref(null);
const coursePageLoaded = ref(false);
const currentScrollHeight = ref(0);
const previousScrollHeight = ref(0);
const headerHeightReducedBy = ref(0);
const addingNewPage = ref(false);
const contentFetchCompleted = ref(false);
const courseAndChapterFetchCompleted = ref(false);
const isDeleted = ref(false);

const showNative = ref(false);
const someDropdownOpen = ref(false);
const editOrder = ref(false);

const headerIsCollapsed = ref(false);
const headerFollowsComputedCollapseTimeout = ref(0);
const isTransitioning = ref(false);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  page: {
    type: [Number, null],
    default: 0,
  },
});

const currentlyViewingPageWithArrayIndex = ref(props.page);

const showingCourseSectionTitlePage = computed(() => {
  return currentlyViewingPageWithArrayIndex.value === null;
});

const emit = defineEmits(['transition-direction']);

const currentSection = computed(() => {
  let section = currentChapterSortedSections.value.find((section) => section.id === sectionId.value);
  if (!section) {
    return {
      id: 'incomplete-load',
      title: 'Lädt...',
      index: 0,
      section_content_items: [],
    };
  }
  return section;
});

type SectionContentItem = {
  page_index: number;
  [key: string]: any; // other properties
};

function sectionContentItemsByPage(sectionContentItems: SectionContentItem[]): PageGroup[] {
  const groupedItems: { [key: number]: SectionContentItem[] } = {};

  // First, group items by their page_index
  sectionContentItems.forEach((item) => {
    if (!groupedItems[item.page_index]) {
      groupedItems[item.page_index] = [];
    }
    groupedItems[item.page_index].push(item);
  });

  // Convert to array of PageGroup objects, maintaining original page indices
  // and adding array indices
  return Object.entries(groupedItems).map(([pageIndex, items], arrayIndex) => ({
    pageIndex: parseInt(pageIndex),
    arrayIndex,
    items: items,
  }));
}

const currentSectionPages = computed(() => {
  if (!currentSection.value) {
    return null;
  }
  let pages = CourseService.sectionContentItemsByPage(currentSection.value.section_content_items || []);
  if (appendDummyPage.value) {
    pages.push({
      pageIndex: pages.length > 0 ? Math.max(...pages.map((p) => p.pageIndex)) + 1 : 0,
      arrayIndex: pages.length,
      items: [],
    });
  }
  return pages;
});

const currentSectionPagesWithTitlePageBefore = computed(() => {
  if (!currentSectionPages.value) return [];
  return [{ pageIndex: -1, arrayIndex: -1, items: [] }, ...currentSectionPages.value];
});

const innerHeaderHeight = computed(() => {
  return innerHeader.value ? innerHeader.value.offsetHeight : 0;
});

const currentCourseTitleShortened = computed(() => {
  return currentCourse.value.title.length > 12
    ? currentCourse.value.title.substring(0, 12) + '...'
    : currentCourse.value.title;
});

const currentChapterTitleShortened = computed(() => {
  return currentChapter.value.title.length > 10
    ? currentChapter.value.title.substring(0, 10) + '...'
    : currentChapter.value.title;
});

const currentSectionTitleShortened = computed(() => {
  if (!currentSection.value) {
    return '';
  }
  return currentSection.value.title.length > 20
    ? currentSection.value.title.substring(0, 20) + '...'
    : currentSection.value.title;
});

const currentChapterSortedVisibleSections = computed(() => {
  if (isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return currentChapterSortedSections.value;
  }
  let visible = currentChapterSortedSections.value.filter((section) => section.published_at != null);
  console.log('#Visible sections: ' + visible.length);
  return visible;
});

const getWidth = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth: ' + screenWidth);
  if (screenWidth < 640) {
    fullWidth.value = screenWidth;
    return;
  }
  let factor = showNative.value ? 0.9 : 0.75;
  fullWidth.value = Math.round(screenWidth * factor);
};

const debounceGetWidth = debounce(getWidth, 100);

watch(showNative, () => {
  getWidth();
});

const mountingHelper = async () => {
  if (!storeLoaded.value) {
    // go one step back
    router.back();
    return;
  }

  // get editing state from local storage
  let courseId = courseStore.currentCourse.id;
  try {
    const savedState = localStorage.getItem(`course-editing-${courseId}`);
    if (savedState && isOwnerOrEditorOfParentCourseOrAdmin.value) {
      try {
        isEditing.value = JSON.parse(savedState);
      } catch (e) {
        console.warn('Invalid saved state in localStorage, resetting...', e);
        localStorage.removeItem(`course-editing-${courseId}`);
        isEditing.value = false;
      }
    }
  } catch (e) {
    console.error('Error accessing localStorage:', e);
    isEditing.value = false;
  }

  isLoading.value = false;

  await nextTick();
  HSDropdown.autoInit();

  courseInteractionStore.saveLastViewedForCurrentCourse(sectionId.value, props.page);
};

onBeforeMount(async () => {
  sectionId.value = router.currentRoute.value.params.sectionId;

  isLoading.value = true;

  let section = await (await getApiClient()).sections.getSection(sectionId.value);
  if (!section) {
    router.back();
    return;
  }
  let chapter;
  let course;

  console.log('section: ', section);

  if (!currentChapter.value || !currentChapter.value.sections.find((s) => s.id === sectionId.value)) {
    await courseStore.reset();
    await courseInteractionStore.reset();

    // load chapter, see if course fits
    chapter = await (await getApiClient()).chapters.getChapter(section.chapter_id);
    if (!chapter) {
      router.back();
      return;
    }

    console.log('chapter: ', chapter);

    if (!currentCourse.value || currentCourse.value.id !== chapter.course_id) {
      await courseStore.setCurrentCourse(chapter.course_id);
      await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();
    }

    await courseStore.setCurrentChapter(chapter.id);
    await courseInteractionStore.startCurrentChapter().finally(() => {
      courseAndChapterFetchCompleted.value = true;
    });
  } else {
    courseAndChapterFetchCompleted.value = true;
  }
});

watch(
  () => courseAndChapterFetchCompleted.value,
  (newVal) => {
    if (newVal) {
      setTimeout(async () => {
        await fetchSectionContents(); // replaces case preview with full case details
      }, 200); // deferring this is a bit of a dirty hack
    }
  },
);

// Add this ref to track scroll position
const scrollPosition = ref(0);

// Add this ref to track if user has manually scrolled
const userHasScrolled = ref(false);

// Update the handleScroll function to track manual scrolling
const handleScroll = () => {
  if (sectionScrollContainer.value) {
    scrollPosition.value = sectionScrollContainer.value.scrollTop;
    userHasScrolled.value = true;
  }
};

const fetchSectionContents = async () => {
  contentFetchCompleted.value = false;
  if (!currentSection.value) {
    return;
  }
  if (!courseAndChapterFetchCompleted.value) {
    return;
  }
  courseStore
    .refetchSelectedSection(currentSection.value.index)
    .then(async () => {
      contentFetchCompleted.value = true;
    })
    .catch((error) => {
      alertStore.error('Fehler beim Laden der Seite', 'Fehler', error);
      throw error;
      router.back();
    })
    .finally(() => {});
  await nextTick();
  appendDummyPage.value = false;
};

onMounted(async () => {
  isLoading.value = true;
  await nextTick(async () => {
    await mountingHelper();
  });

  if (props.page == null) {
    showCourseSectionTitlePage();
  }

  getWidth();
  setTimeout(() => {
    getWidth(); // let transition animations finish
  }, 400);
  window.addEventListener('resize', debounceGetWidth);

  // Add scroll event listener
  if (sectionScrollContainer.value) {
    sectionScrollContainer.value.addEventListener('scroll', handleScroll);
  }

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  emit('transition-direction', ''); // reset router transition

  setTimeout(async () => {
    await fetchSectionContents(); // replaces case preview with full case details
  }, 200); // deferring this is a bit of a dirty hack
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetWidth);

  // Remove scroll event listener
  if (sectionScrollContainer.value) {
    sectionScrollContainer.value.removeEventListener('scroll', handleScroll);
  }
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSortedSections.value !== null;
});

const isOwnerOrEditorOfParentCourseOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

function scrollToSection(sectionTitle: string) {
  // TODO this needs to become scrollToPage !
  console.log('Scrolling to section: ' + sectionTitle);
  let id = headingToId(sectionTitle);
  const element = document.getElementById(id);
  console.log('Element: ' + element);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const goToChapter = async (chapterIndex: number, atEnd: boolean = false) => {
  if (!courseStore.currentCourse || !courseStore.currentCourse.chapters) {
    return;
  }
  if (chapterIndex < 0 || chapterIndex >= courseStore.currentCourse.chapters.length) {
    return;
  }
  await router.isReady();
  if (!atEnd) {
    await router.push('/chapter/' + courseStore.currentCourse.chapters[chapterIndex].id);
  } else {
    let targetChapter = courseStore.currentCourse.chapters[chapterIndex];
    await courseStore.setCurrentChapter(targetChapter.id);
    let lastSection = targetChapter.sections[targetChapter.sections.length - 1];
    let lastPage = getMaxPageIndex(lastSection.index, chapterIndex);
    await router.push('/section/' + lastSection.id + '?page=' + lastPage);
  }
};

const getMaxPageIndex = (sectionIndex: number, chapterIndex: number = null) => {
  let targetSection;
  if (chapterIndex === null) {
    targetSection = courseStore.currentChapterSortedSections[sectionIndex];
  } else {
    targetSection = courseStore.currentCourse.chapters[chapterIndex].sections[sectionIndex];
  }
  if (!targetSection?.section_content_items) {
    return -1;
  }
  return Math.max(...targetSection.section_content_items.map((item) => item.page_index));
};

const goToSection = async (index: number, atEnd: boolean = false) => {
  console.log('goToSection: ' + index);
  console.log('currentSection.value.index: ' + currentSection.value?.index);
  if (currentSection.value?.index === null) return;
  if (index === currentSection.value.index) {
    return;
  }
  await router.isReady();
  // Determine the transition direction
  const direction = index < currentSection.value.index ? 'slide-right' : 'slide-left';
  // Emit the direction to the parent
  emit('transition-direction', direction);

  if (!atEnd) {
    await router.push('/section/' + courseStore.currentChapterSortedSections[index].id);
  } else {
    await router.push(
      '/section/' + courseStore.currentChapterSortedSections[index].id + '?page=' + getMaxPageIndex(index),
    );
  }
};

const goToPage = async (newPageIndex: number) => {
  if (!currentSectionPages.value) return;
  if (newPageIndex < 0 || newPageIndex >= currentSectionPages.value.length) {
    return;
  }
  if (newPageIndex === currentlyViewingPageWithArrayIndex.value) {
    return;
  }
  currentlyViewingPageWithArrayIndex.value = newPageIndex;
  await new Promise((resolve) => setTimeout(resolve, 400));
  scrollToTop();
  courseInteractionStore.saveLastViewedForCurrentCourse(sectionId.value, newPageIndex);
};

watch(
  () => currentlyViewingPageWithArrayIndex.value,
  (newIndex, oldIndex) => {
    pageTransitionName.value = newIndex > oldIndex ? 'slide-left' : 'slide-right';
  },
);

const toggleIsEditing = (isEditingNow: boolean) => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  if (!courseStore.currentCourse?.id) {
    return;
  }
  isEditing.value = isEditingNow;
  localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditingNow));
};

const addPage = async () => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  if (!currentSectionPages.value) return;

  addingNewPage.value = true;
  appendDummyPage.value = true;
  setTimeout(() => {
    if (!currentSectionPages.value) return;
    const lastPage = currentSectionPages.value.length - 1;
    goToPage(lastPage);
  }, 200);
};

// Update handleCoursePageLoaded function
const handleCoursePageLoaded = async (pageIndex: number, loaded: boolean, hasContent: boolean) => {
  if (!loaded || !sectionScrollContainer.value) return;

  const page = currentSectionPages.value?.find((p) => p.pageIndex === pageIndex);
  if (!page) return;

  if (page.arrayIndex === currentlyViewingPageWithArrayIndex.value) {
    coursePageLoaded.value = true;
  }

  addingNewPage.value = !hasContent;
  console.log('newPage: ' + addingNewPage.value);

  if (!hasContent) {
    // new page added
    setTimeout(() => {
      scrollToBottom();
    }, 700); // delay: let slide animation finish first
    return;
  } else if (!!currentSectionPages.value && page.arrayIndex === currentSectionPages.value.length - 1) {
    addingNewPage.value = false; // last page has content, so adding new page is finished
    appendDummyPage.value = false;
    return;
  }

  // Only scroll to top if this is the current page and user hasn't manually scrolled
  if (page.arrayIndex === currentlyViewingPageWithArrayIndex.value && !userHasScrolled.value) {
    await nextTick();
    sectionScrollContainer.value.scrollTop = 0;
  }
};

const showCourseSectionTitlePage = () => {
  currentlyViewingPageWithArrayIndex.value = -1;
};

const hideCourseSectionTitlePage = () => {
  currentlyViewingPageWithArrayIndex.value = 0;
};

watch(
  () => currentSectionPages.value?.length,
  (newLength, oldLength) => {
    if (newLength === undefined || oldLength === undefined) return;
    if (newLength > oldLength && !addingNewPage.value) {
      appendDummyPage.value = false;
    }
  },
);

const scrollToBottom = () => {
  if (!sectionScrollContainer.value) return;
  console.log('scrolling to bottom');
  sectionScrollContainer.value.scroll({
    top: sectionScrollContainer.value.scrollHeight,
    behavior: 'smooth',
  });
};

const scrollToTop = () => {
  if (!sectionScrollContainer.value) return;
  console.log('scrolling to top');
  sectionScrollContainer.value.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

const courseSectionHeader = ref(null);

const minHeaderHeight = computed(() => {
  if (!courseSectionHeader.value?.minHeight) return 64;
  return courseSectionHeader.value.minHeight;
});

const collapsableHeaderHeight = computed(() => {
  if (!courseSectionHeader.value?.fullHeight) return null;
  return courseSectionHeader.value.fullHeight - minHeaderHeight.value;
});

const computedHeaderHeight = computed(() => {
  if (!courseSectionHeader.value?.fullHeight) return null;
  if (!collapsableHeaderHeight.value) return null;

  const fullHeight = courseSectionHeader.value.fullHeight;

  // Start collapsing only after scrolling past the difference
  const collapsibleHeight = fullHeight - minHeaderHeight.value;
  const remainingHeight =
    minHeaderHeight.value + Math.max(0, collapsableHeaderHeight.value - headerHeightReducedBy.value);

  return remainingHeight;
});

watch(computedHeaderHeight, (newHeight) => {});

const computedStyleScrollContainer = computed(() => {
  return {
    flex: '1 1 auto',
    overflowY: 'auto',
    minHeight: 0,
  };
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(100vh - ${props.outerHeaderHeight}px)`,
  };
});

const lastScrollAmount = ref(0);

const handleScrollBy = async (distance: number) => {
  let scrollPositionBefore = sectionScrollContainer.value?.scrollTop;
  sectionScrollContainer.value?.scrollBy(0, distance);
  sectionScrollContainer.value?.addEventListener(
    'scrollend',
    () => {
      let scrollPositionAfter = sectionScrollContainer.value?.scrollTop;
      lastScrollAmount.value = scrollPositionAfter - scrollPositionBefore;
    },
    { once: true },
  );
};

const handleUndoLastScroll = () => {
  if (lastScrollAmount.value) {
    sectionScrollContainer.value?.scrollBy(0, -lastScrollAmount.value);
    lastScrollAmount.value = 0;
  }
};

const headerIsInView = computed(() => {
  if (!courseSectionHeader.value?.fullHeight) return true;
  return scrollPosition.value + minHeaderHeight.value < courseSectionHeader.value.fullHeight;
});

const onDeleteEmptySection = (sectionId: string) => {
  console.log('onDeleteEmptySection: ' + sectionId);
  if (!currentSection.value) {
    return;
  }
  if (!userConformationModalDeleteSection.value) {
    return;
  }
  userConformationModalDeleteSection.value.promptUserConformation(sectionId).catch((error: any) => {
    console.debug('Error: ' + error);
    return false;
  });
};

const deleteEmptySection = (sectionId: string) => {
  console.log('deleteEmptySection: ' + sectionId);
  isDeleted.value = true; // this sets off autosave attempts and removes the breadcrumb for the section level
  courseStore
    .deleteSection(sectionId)
    .then(async () => {
      alertStore.success('Section deleted successfully');
      await nextTick();
      await router.push('/chapter/' + courseStore.currentChapterId);
    })
    .catch((error: any) => {
      console.debug('Error: ' + error);
      isDeleted.value = false;
      return false;
    });
};

// Reset userHasScrolled when changing pages
watch(
  () => currentlyViewingPageWithArrayIndex.value,
  () => {
    userHasScrolled.value = false;
  },
);

watch(
  () => isEditing.value,
  async (newValue) => {
    if (newValue) return; // no changes before as editing was false, so nothing to refetch
    isLoading.value = true;
    await courseStore.refetchSelectedSection(currentSection.value?.index);
    isLoading.value = false;
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <transition
      :name="sectionTransitionName"
      :mode="'out-in'"
      v-if="courseAndChapterFetchCompleted"
      @before-enter="isTransitioning = true"
      @after-enter="isTransitioning = false"
      @before-leave="isTransitioning = true"
      @after-leave="isTransitioning = false"
    >
      <div
        class="flex flex-col min-w-full overflow-hidden relative"
        :style="computedStyleMain"
        :key="currentSection?.id"
      >
        <header class="flex min-w-full overflow-visible">
          <!-- Navigation -->
          <h2
            ref="innerHeader"
            :class="{
              'overflow-visible': someDropdownOpen,
              'overflow-y-visible overflow-x-scroll': !someDropdownOpen,
            }"
            class="w-full sticky top-0 z-40 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-1.5 justify-center flex"
          >
            <div class="w-full mx-auto px-2 md:px-6 lg:px-8 inline-flex justify-between">
              <ol class="inline-flex overflow-visible items-center whitespace-nowrap w-full">
                <!-- level 1 breadcrump: all courses -->
                <BreadcrumbElement class="hidden md:inline-flex" label="Kurse" to="/courses" />
                <!-- level 2 breadcrump: current course -->
                <BreadcrumbElement
                  data-testid="breadcrumb-course"
                  class="hidden md:inline-flex"
                  :label="currentCourse.title"
                  :to="'/course/' + currentCourse.id"
                />
                <BreadcrumbElement
                  class="inline-flex md:hidden"
                  :label="currentCourseTitleShortened"
                  :to="'/course/' + currentCourse.id"
                />
                <!-- level 3 breadcump: current chapter -->
                <ChapterBreadcrumbElementWithDropdown
                  data-testid="breadcrumb-chapter"
                  :current-chapter="currentChapter"
                  :current-chapter-title="currentChapterTitle"
                  :current-chapter-title-shortened="currentChapterTitleShortened"
                  :chapters="currentCourse.chapters"
                  id-prefix="course-section-chapter"
                  @dropdownOpen="someDropdownOpen = $event"
                  @goToChapter="goToChapter"
                />
                <BreadcrumbSeparator />

                <!-- level 4 breadcump: current section -->
                <SectionBreadcrumbElementWithDropdown
                  v-if="!isDeleted"
                  :current-section="currentSection"
                  :current-section-title="currentSection?.title || 'Lädt...'"
                  :current-section-title-shortened="currentSectionTitleShortened"
                  :sections="currentChapterSortedVisibleSections"
                  id-prefix="course-section-section"
                  @goToSection="goToSection"
                  @dropdownOpen="someDropdownOpen = $event"
                />
              </ol>
            </div>
          </h2>
        </header>

        <main
          ref="sectionScrollContainer"
          v-if="!isLoading"
          :id="pageId()"
          class="flex flex-col min-w-full scroll-smooth pb-[200px] sm:pb-4"
          :style="computedStyleScrollContainer"
        >
          <div
            class="flex mx-auto transition-[width] duration-500"
            :style="{
              position: 'static',
              width: `${fullWidth}px`,
            }"
            :key="`section-header-static-${currentSection?.id || 'incomplete-load'}`"
            v-show="!showingCourseSectionTitlePage"
          >
            <CourseSectionHeader
              ref="courseSectionHeader"
              :is-in-view="true"
              :chapter="currentChapter"
              :section="currentSection"
              :numVisibleSections="currentChapterSortedVisibleSections.length"
              :allow-editing="isEditing"
              :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
              :is-deleted="isDeleted"
              @onToggleIsEditing="toggleIsEditing"
              @onAddPage="addPage"
              @scrollTo="(sectionTitle) => scrollToSection(sectionTitle)"
              @toPreviousSectionEnd="goToSection(currentSection.index - 1, true)"
              @toPreviousChapterEnd="goToChapter(currentChapter.index - 1, true)"
              @toNextSection="goToSection(currentSection.index + 1)"
              @toPreviousPage="
                () => {
                  if (currentlyViewingPageWithArrayIndex === 0) {
                    showCourseSectionTitlePage();
                    return;
                  }
                  goToPage(currentlyViewingPageWithArrayIndex - 1);
                }
              "
              @toNextPage="
                () => {
                  if (currentlyViewingPageWithArrayIndex === null) {
                    hideCourseSectionTitlePage();
                    return;
                  }
                  goToPage(currentlyViewingPageWithArrayIndex + 1);
                }
              "
              @toNextChapter="goToChapter(currentChapter.index + 1)"
              :pageIndex="currentlyViewingPageWithArrayIndex"
              :numPages="currentSectionPages?.length"
              @onDeleteEmptySection="(sectionId: string) => onDeleteEmptySection(sectionId)"
              @onEditOrder="editOrder = true"
            />
          </div>
          <div
            v-show="!headerIsInView && !showingCourseSectionTitlePage"
            class="flex z-30 mx-auto duration-500 ease-in-out"
            :style="{
              position: 'fixed',
              width: `100%`,
            }"
            :key="`section-header-collapsed-${currentSection?.id || 'incomplete-load'}`"
          >
            <CourseSectionHeader
              :is-in-view="false"
              :chapter="currentChapter"
              :section="currentSection"
              :numVisibleSections="currentChapterSortedVisibleSections.length"
              :allow-editing="isEditing"
              :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
              :is-deleted="isDeleted"
              @onToggleIsEditing="toggleIsEditing"
              @onAddPage="addPage"
              @scrollTo="(sectionTitle) => scrollToSection(sectionTitle)"
              @toPreviousSection="goToSection(currentSection.index - 1)"
              @toNextSection="goToSection(currentSection.index + 1)"
              @toPreviousPage="goToPage(currentlyViewingPageWithArrayIndex - 1)"
              @toNextPage="goToPage(currentlyViewingPageWithArrayIndex + 1)"
              @toNextChapter="goToChapter(currentChapter.index + 1)"
              :pageIndex="currentlyViewingPageWithArrayIndex"
              :numPages="currentSectionPages?.length"
              @onDeleteEmptySection="(sectionId: string) => onDeleteEmptySection(sectionId)"
              @onEditOrder="editOrder = true"
            />
          </div>

          <div
            v-for="page in currentSectionPagesWithTitlePageBefore"
            :key="`page-${page?.pageIndex || 'title'}-${page?.items?.[0]?.id || ''}`"
            class="relative"
          >
            <transition
              :name="pageTransitionName"
              :mode="'out-in'"
              @before-enter="isTransitioning = true"
              @after-leave="isTransitioning = false"
              @after-enter="isTransitioning = false"
              @before-leave="isTransitioning = true"
            >
              <div v-show="page.arrayIndex === currentlyViewingPageWithArrayIndex" class="pt-4">
                <div v-if="page.arrayIndex === -1" class="">
                  <CourseSectionTitlePage
                    :chapter="currentChapter"
                    :section="currentSection"
                    :numVisibleSections="currentChapterSortedVisibleSections.length"
                    :allow-editing="isEditing"
                    :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
                    :is-deleted="isDeleted"
                    :full-width="fullWidth"
                    @onToggleIsEditing="toggleIsEditing"
                    @onAddPage="addPage"
                  />
                </div>
                <EditCoursePage
                  v-else-if="isEditing"
                  :pageContents="page.items"
                  :pageIndex="page.pageIndex"
                  :arrayIndex="page.arrayIndex"
                  :fetchingSectionContentsCompleted="contentFetchCompleted"
                  :sectionIndex="currentSection.index"
                  :sectionId="currentSection.id"
                  :chapterId="chapterId"
                  :chapterIndex="currentChapter.index"
                  :show-native="showNative"
                  :full-width="fullWidth"
                  :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                  :current-scroll-height="currentScrollHeight"
                  @scrollToBottom="scrollToBottom"
                  @scrollTo="
                    (scrollHeight) => {
                      console.log('scrolling to ', scrollHeight);
                      sectionScrollContainer.scroll({
                        top: scrollHeight,
                        behavior: 'smooth',
                      });
                    }
                  "
                  @pageLoaded="handleCoursePageLoaded"
                  @refetchSectionContents="fetchSectionContents"
                />
                <CoursePage
                  v-else
                  :pageContents="page.items"
                  :pageIndex="page.pageIndex"
                  :pageIsVisible="page.arrayIndex === currentlyViewingPageWithArrayIndex"
                  :arrayIndex="page.arrayIndex"
                  :fetchingSectionContentsCompleted="contentFetchCompleted"
                  :sectionIndex="currentSection.index"
                  :sectionId="currentSection.id"
                  :chapterId="chapterId"
                  :chapterIndex="currentChapter.index"
                  :show-native="showNative"
                  :full-width="fullWidth"
                  :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                  :is-transitioning="isTransitioning"
                  @showNativeToggled="showNative = $event"
                  @scrollBy="handleScrollBy"
                  @undoLastScroll="handleUndoLastScroll"
                  @pageLoaded="handleCoursePageLoaded"
                />
              </div>
            </transition>
          </div>
        </main>
        <div v-else class="min-w-full mx-auto flex">
          <div class="pt-28 w-full flex mx-auto justify-center items-center h-full">
            <LoadingSpinnerLarge />
          </div>
        </div>
      </div>
    </transition>

    <UserConfirmationModal
      ref="userConformationModalDeleteSection"
      prompt_message=""
      approve_message="Ja, löschen"
      discard_message="Nein, zurück"
      approve_color="bg-red-600 hover:bg-red-700"
      overlayId="confirmDeleteSection"
      @approved="(sectionId: string) => deleteEmptySection(sectionId)"
    >
    </UserConfirmationModal>

    <SectionContentReorderModal
      v-if="editOrder"
      :section="currentSection"
      @closed="
        () => {
          editOrder = false;
        }
      "
      @updated="
        () => {
          fetchSectionContents();
        }
      "
    />
  </div>
</template>

<style scoped>
/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
}

.slide-left-enter-from {
  transform: translateX(105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-left-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-left-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}

/* Slide Right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-right-enter-from {
  transform: translateX(-105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-right-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-right-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}
</style>
