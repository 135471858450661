import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from './auth.store';

// import xpSound from '@/assets/sounds/337049__shinephoenixstormcrow__320655__rhodesmas__level-up-01.mp3';
// import errorSound from '@/assets/sounds/734442__universfield__error-8.mp3';
// import majorFailureSound from '@/assets/sounds/699923__8bitmyketison__multimedia-sfx-error-01.wav';

const RESET_AFTER = 3500;
const XP_RESET_AFTER = 500;
const FADE_OUT_DURATION = 500;

const xpSoundUrl =
  'https://assets-prd.data.casuu.health/337049__shinephoenixstormcrow__320655__rhodesmas__level-up-01.mp3';
const errorSoundUrl = 'https://assets-prd.data.casuu.health/734442__universfield__error-8.mp3';
const majorFailureSoundUrl = 'https://assets-prd.data.casuu.health/699923__8bitmyketison__multimedia-sfx-error-01.wav';

export const useAlertStore = defineStore({
  id: 'alert',
  state: () => ({
    alerts: [] as Array<{
      id: string;
      message: string;
      type: 'success' | 'error' | 'info' | 'xp';
      title: string;
      visible: boolean;
    }>,
  }),
  actions: {
    success(message: string, title?: string) {
      this.addAlert(message, 'success', title);
    },
    error(message: string, title?: string, error?: Error) {
      let errorDetails = '';
      if (error) {
        let errorMessage = error.message;
        let errorTitle = error.name;
        errorDetails = '(' + errorTitle + ': ' + errorMessage + ')';
      }
      this.addAlert(message + ' ' + errorDetails, 'error', title);
    },
    info(message: string, title?: string) {
      this.addAlert(message, 'info', title);
    },
    xp(message: string, title?: string) {
      this.playXpSound();
      this.addAlert(message, 'xp', title);
    },
    addAlert(message: string, type: 'success' | 'error' | 'info' | 'xp', title?: string) {
      const id = uuidv4();
      if (!title) {
        title = 'status.' + type + '.alertTitle';
      }
      this.alerts.unshift({ id, message, type, title, visible: true });

      setTimeout(
        () => {
          this.clearAlert(id);
        },
        type === 'xp' ? XP_RESET_AFTER : RESET_AFTER,
      );
    },
    clearAlert(id: string) {
      const alert = this.alerts.find((alert) => alert.id === id);
      if (alert) {
        // Triggers exit animation
        alert.visible = false;

        // Removes the alert after exit animation
        setTimeout(() => {
          this.alerts = this.alerts.filter((alert) => alert.id !== id);
        }, FADE_OUT_DURATION);
      }
    },
    playXpSound() {
      const authStore = useAuthStore();
      if (!authStore.user.notification_sounds) return;

      const audio = new Audio(xpSoundUrl);
      audio.play().catch((error) => {
        console.error('Failed to play XP sound:', error);
      });
    },
    async playErrorSound(majorFailure = false) {
      const authStore = useAuthStore();
      if (!authStore.user.notification_sounds) return;

      const audio = new Audio(errorSoundUrl);
      audio.play().catch((error) => {
        console.error('Failed to play error sound:', error);
      });
      if (majorFailure) {
        await new Promise((resolve) => setTimeout(resolve, 1200));
        const majorFailureAudio = new Audio(majorFailureSoundUrl);
        majorFailureAudio.play().catch((error) => {
          console.error('Failed to play major failure sound:', error);
        });
      }
    },
    async playMajorFailureSound() {
      const authStore = useAuthStore();
      if (!authStore.user.notification_sounds) return;

      const audio = new Audio(majorFailureSoundUrl);
      audio.play().catch((error) => {
        console.error('Failed to play major failure sound:', error);
      });
    },
    async playSuccessSound() {
      const authStore = useAuthStore();
      if (!authStore.user.notification_sounds) return;

      await this.playXpSound(); // TODO maybe use another one here
    },
    clear() {
      this.alerts.length = 0;
    },
  },
});
