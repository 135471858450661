import { defineStore } from 'pinia';
import {
  Chapter,
  Course,
  CourseCreate,
  LearningObjective,
  PairOfTermsGameUpdate,
  VocabItemUpdate,
  VocabItem,
  Section,
  ResourceType,
} from '@/apiclient';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores/alert.store';
import { VocabItemDefault } from '@/helper';

interface UpdateSectionOptions {
  title?: string | null;
  learningObjectives?: LearningObjective[] | null;
  internalIndex?: number | null;
  publishedAt?: string | null;
}

// interface PairOfTermsItemCreate {
//     id: string | null;
//     type: string | null;
//     content: string | null;
//     description: string | null;
//     media_item_id: string | null;
// }
//
// interface PairOfTermsItemTupleUpdate {
//     id: string;
//     pair_of_terms_items: PairOfTermsItemCreate[];
// }

// interface PairOfTermsGameUpdate {
//     pair_of_terms_item_tuples: PairOfTermsItemTupleUpdate[];
// }

export const useCourseStore = defineStore({
  id: 'course',
  state: () => {
    // Initialize state with default values
    const defaultState = {
      currentCourse: null as Course | null,
      currentChapterIndex: null as number | null,
      settingCoursePromise: null as Promise<Boolean> | null,
      settingChapterPromise: null as Promise<Boolean> | null,
      lastRemountEnforced: null as Date | null,
    };

    try {
      // Try to load all related items from localStorage
      const savedCourse = localStorage.getItem('currentCourse');
      const savedChapterIndex = localStorage.getItem('currentChapterIndex');

      // Only proceed if both items exist and are valid
      if (savedCourse && savedChapterIndex) {
        const parsedCourse = JSON.parse(savedCourse);
        const parsedChapterIndex = parseInt(savedChapterIndex, 10);

        // Validate the parsed data
        if (
          parsedCourse &&
          !isNaN(parsedChapterIndex) &&
          parsedChapterIndex >= 0 &&
          parsedCourse.chapters &&
          parsedChapterIndex < parsedCourse.chapters.length
        ) {
          return {
            ...defaultState,
            currentCourse: parsedCourse,
            currentChapterIndex: parsedChapterIndex,
          };
        }
      }

      // If we get here, something was invalid - clear everything
      console.warn('Invalid or inconsistent localStorage data - resetting state');
      localStorage.removeItem('currentCourse');
      localStorage.removeItem('currentChapterIndex');
    } catch (e) {
      // On any error, clear everything
      console.error('Error loading course store from localStorage:', e);
      localStorage.removeItem('currentCourse');
      localStorage.removeItem('currentChapterIndex');
    }

    // Return default state if anything fails
    return defaultState;
  },
  getters: {
    currentChapter: (state) => {
      if (!state.currentCourse?.chapters || state.currentChapterIndex === null) {
        return null;
      }
      if (state.currentChapterIndex < 0 || state.currentChapterIndex >= state.currentCourse.chapters.length) {
        return null;
      }
      return state.currentCourse.chapters[state.currentChapterIndex];
    },
    currentCourseTitle: (state) => (state.currentCourse ? state.currentCourse.title : null),
    currentCourseDescription: (state) => (state.currentCourse ? state.currentCourse.description : null),
    currentChapterTitle: (state) => {
      if (!state.currentCourse?.chapters || state.currentChapterIndex === null) return null;
      return state.currentCourse.chapters[state.currentChapterIndex]?.title ?? null;
    },
    currentChapterId: (state) => {
      if (!state.currentCourse?.chapters || state.currentChapterIndex === null) return null;
      return state.currentCourse.chapters[state.currentChapterIndex]?.id ?? null;
    },
    currentCourseChapters: (state) => state.currentCourse?.chapters ?? [],
    currentChapterSortedSections: (state) => {
      if (!state.currentCourse?.chapters || state.currentChapterIndex === null) return [];
      // Create a new array and map each section to a new object to ensure reactivity
      return [...(state.currentCourse.chapters[state.currentChapterIndex]?.sections ?? [])]
        .map((section) => ({
          ...section, // Spread operator creates a new object, ensuring Vue can track changes
        }))
        .sort((a: { index: number }, b: { index: number }) => a.index - b.index);
    },
    numberOfChapters: (state) => state.currentCourse?.chapters?.length ?? 0,
    numberOfCases: (state) =>
      !!state.currentCourse && !!state.currentCourse.chapters
        ? state.currentCourse.chapters.reduce((totalCases, chapter) => {
            if (chapter.sections == undefined) {
              return totalCases;
            }
            return (
              totalCases +
              chapter.sections.reduce((sectionTotal, section) => {
                return sectionTotal + (!!section.n_cases ? section.n_cases : 0);
              }, 0)
            );
          }, 0)
        : 0,
  },
  actions: {
    isOwnerOfCurrentCourse(userId: string) {
      if (!this.currentCourse) {
        return false;
      }
      console.log('Current course user id is: ', this.currentCourse.user.id);
      return this.currentCourse.user.id === userId;
    },
    isEditorOfCurrentCourse(userId: string) {
      // return true if userId is in this.currentCourse.course_user_editors.user_id
      if (!this.currentCourse) {
        return false;
      }
      if (!this.currentCourse.course_user_editorships) {
        return false;
      }
      return this.currentCourse.course_user_editorships.some(
        (editor) => editor.user.id === userId && editor.deactivated_at == null,
      );
    },
    isOwnerOfCurrentChapter(userId: string) {
      if (!this.currentChapter) {
        return false;
      }
      return this.currentChapter.user.id === userId;
    },
    async setCurrentCourse(courseId: string) {
      if (this.settingCoursePromise) {
        console.log('setCurrentCourse: already setting course, waiting for promise');
        // TODO: wait or return ?
        await this.settingCoursePromise;
        console.log('setCurrentCourse: setting course promise resolved');
      }

      if (this.currentCourse && this.currentCourse.id === courseId) {
        // do not reload the same course (e.g. fetched from local storage)
        return;
      }

      // load the course incl chapters from the API
      this.settingCoursePromise = new Promise(async (resolve, reject) => {
        try {
          console.log('setCurrentCourse: loading course details for courseId: ' + courseId);
          this.currentCourse = await (await getApiClient()).courses.getCourse(courseId, 5);
          localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
      return this.settingCoursePromise;
    },
    async setCurrentChapter(chapterId: string, forceReload: boolean = false) {
      if (this.settingChapterPromise) {
        await this.settingChapterPromise;
      }

      // Early return if we're already on this chapter
      if (this.currentChapter && this.currentChapter.id === chapterId && !forceReload) {
        // do not reload the same chapter (e.g. fetched from local storage)
        return;
      }

      // Wait for any pending course loading
      await this.settingCoursePromise;

      this.settingChapterPromise = new Promise(async (resolve, reject) => {
        try {
          const chapter = await (await getApiClient()).chapters.getChapter(chapterId);

          if (!this.currentCourse || this.currentCourse.id !== chapter.course_id) {
            await this.setCurrentCourse(chapter.course_id);
          }

          // Find the chapter index in the current course
          const chapterIndex = this.currentCourse?.chapters?.findIndex((c) => c.id === chapterId) ?? -1;
          if (chapterIndex === -1) {
            throw new Error('Chapter not found in current course');
          }

          this.currentChapterIndex = chapterIndex;
          localStorage.setItem('currentChapterIndex', this.currentChapterIndex.toString());

          // Update the chapter in the course with the detailed version
          if (this.currentCourse?.chapters) {
            this.currentCourse.chapters[chapterIndex] = chapter as (typeof this.currentCourse.chapters)[number];
            localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
          }

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });

      return this.settingChapterPromise;
    },
    async listCourses() {
      try {
        console.log('Fetching courses...');
        const apiClient = await getApiClient();
        const courses = await apiClient.courses.listCourses();
        console.log('Courses fetched:', courses);
        return courses;
      } catch (error) {
        console.error('Error fetching courses:', error);
        // Re-throw the error to be handled by the component
        throw error;
      }
    },
    // async setCurrentChapterByIndex(chapterIndex: number) {
    //   if (!this.currentCourse) {
    //     return;
    //   }
    //   if (this.currentChapterIndex === chapterIndex) {
    //     // do not reload the same chapter (e.g. fetched from local storage)
    //     return this.currentChapter;
    //   }
    //   this.currentChapterIndex = chapterIndex;
    //   localStorage.setItem('currentChapterIndex', this.currentChapterIndex.toString());
    //   await this._setChapterForCurrentIndex();
    // },
    async _setChapterForCurrentIndex() {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        return null;
      }

      // Early return if we already have the chapter with the correct index
      if (this.currentChapter && this.currentChapter.index === this.currentChapterIndex) {
        // do not reload the same chapter (e.g. fetched from local storage)
        return this.currentChapter;
      }

      const chapter = await (
        await getApiClient()
      ).chapters.getChapterByCourse(this.currentCourse.id, this.currentChapterIndex);

      // Update the chapter in the course
      if (this.currentCourse?.chapters) {
        this.currentCourse.chapters[this.currentChapterIndex] = chapter as (typeof this.currentCourse.chapters)[number];
        localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      }

      return chapter;
    },
    async appendEmptySection() {
      if (!this.currentChapter) {
        return;
      }
      const newSection = await (
        await getApiClient()
      ).sections.createSection({
        title: 'Überschrift',
        index: this.currentChapter.sections ? this.currentChapter.sections.length : 0,
        chapter_id: this.currentChapter.id,
      });
      if (!this.currentChapter.sections) {
        this.currentChapter.sections = [];
      }
      this.currentChapter.sections.push({
        ...newSection,
        user_id: newSection.user.id,
      } as (typeof this.currentChapter.sections)[number]);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async appendEmptyChapter() {
      if (!this.currentCourse) {
        return;
      }
      const newChapter = await (
        await getApiClient()
      ).chapters.createChapter({
        title: 'Chapter Title',
        description: 'Chapter description.',
        index: this.currentCourse.chapters ? this.currentCourse.chapters.length : 0,
        course_id: this.currentCourse.id,
      });
      if (!this.currentCourse.chapters) {
        this.currentCourse.chapters = [];
      }
      this.currentCourse.chapters.push({
        ...newChapter,
        user_id: newChapter.user.id,
      } as (typeof this.currentCourse.chapters)[number]);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async appendEmptyCourse() {
      const newCourse = await (
        await getApiClient()
      ).courses.createCourse({
        title: 'Course Title',
        description: 'Course description.',
      } as CourseCreate);
      this.currentCourse = newCourse;
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async moveChapter(oldIndex: number, newIndex: number) {
      if (!this.currentCourse) {
        return;
      }
      if (!this.currentCourse.chapters || this.currentCourse.chapters.length <= 1) {
        return;
      }

      // Ensure indices are within the valid range
      if (
        oldIndex < 0 ||
        oldIndex >= this.currentCourse.chapters.length ||
        newIndex < 0 ||
        newIndex >= this.currentCourse.chapters.length
      ) {
        return;
      }

      // Remove the chapter from the old position
      const [movedChapter] = this.currentCourse.chapters.splice(oldIndex, 1);

      // Insert the chapter at the new position
      this.currentCourse.chapters.splice(newIndex, 0, movedChapter);

      // Update the 'index' property of each chapter to reflect the new order
      this.currentCourse.chapters.forEach((chapter, index) => {
        chapter.index = index; // Update the 'index' to match the new order
      });

      // make persistent changes (setting to temporary negative indices to avoid conflicts)
      const apiClient = await getApiClient();
      const tempUpdatePromises = this.currentCourse.chapters.map((chapter) =>
        apiClient.chapters.updateChapter(chapter.id, { index: -chapter.index }),
      );
      await Promise.all(tempUpdatePromises);
      const finalUpdatePromises = this.currentCourse.chapters.map((chapter) =>
        apiClient.chapters.updateChapter(chapter.id, { index: chapter.index }),
      );
      await Promise.all(finalUpdatePromises);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));

      // await this.setCurrentCourse(this.currentCourse.id);
      // TODO do updates by hand to avoid reloading the whole course
    },
    async moveSection(oldIndex: number, newIndex: number) {
      if (
        this.currentChapterIndex === null ||
        !this.currentCourse ||
        !this.currentCourse.chapters ||
        !this.currentCourse.chapters[this.currentChapterIndex]
      ) {
        return;
      }
      const sections = this.currentCourse.chapters[this.currentChapterIndex].sections;
      if (!sections || sections.length <= 1) {
        return;
      }

      // make a list of tuples with [sectionId, updated index]
      const indexedIds = this.currentCourse.chapters[this.currentChapterIndex]?.sections?.map((section) => [
        section.id,
        section.index,
      ]);
      if (!indexedIds) {
        return;
      }
      // move the section to the new index
      indexedIds.splice(newIndex, 0, indexedIds.splice(oldIndex, 1)[0]);
      // update the index of each section
      for (let i = 0; i < indexedIds.length; i++) {
        await (await getApiClient()).sections.updateSection(indexedIds[i][0] as string, { index: i });
      }

      // TODO do updates by hand to avoid reloading the whole course
      await this.setCurrentChapter(this.currentCourse.chapters[this.currentChapterIndex].id, true);

      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async deleteChapter(index: number) {
      if (!this.currentCourse) {
        return;
      }
      if (!!this.currentCourse.chapters && this.currentCourse.chapters.length <= 1) {
        return;
      }
      const chapterId = this.currentCourse.chapters?.find((chapter) => chapter.index === index)?.id;
      if (!chapterId) {
        return;
      }
      await (await getApiClient()).chapters.deleteChapter(chapterId);
      await this.setCurrentCourse(this.currentCourse.id);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      // TODO do updates by hand to avoid reloading the whole course
    },
    async addCategorizationExerciseCategory(sectionId: string, contentItemId: string, category: string = '') {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters?.[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      let newCategory = await (
        await getApiClient()
      ).courseSectionItems.addCategoryToCategorizationExercise(contentItemId, {
        name: category,
      });

      let sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      let contentItemIndex = currentChapter.sections[sectionIndex].section_content_items?.findIndex(
        (contentItem) => contentItem.id === contentItemId,
      );
      if (contentItemIndex === -1) {
        throw new Error('Content item not found in section');
      }

      // Update the category in the course state
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].categorization_exercise.categorization_exercise_categories.push(newCategory);

      // Update timestamp
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();

      // Save to localStorage
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async removeCategorizationExerciseCategory(sectionId: string, contentItemId: string, categoryId: string) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters?.[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      await (
        await getApiClient()
      ).courseSectionItems.deleteCategoryFromCategorizationExercise(contentItemId, categoryId);

      let sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      let contentItemIndex = currentChapter.sections[sectionIndex].section_content_items?.findIndex(
        (contentItem) => contentItem.id === contentItemId,
      );
      if (contentItemIndex === -1) {
        throw new Error('Content item not found in section');
      }
      let categoryIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories.findIndex(
        (category) => category.id === categoryId,
      );
      if (categoryIndex === undefined) {
        throw new Error('Category not found in content item');
      }

      // Remove the category from the course state
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].categorization_exercise.categorization_exercise_categories.splice(categoryIndex, 1);

      // Update timestamp
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();

      // Save to localStorage
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateCategorizationExercise(sectionId: string, contentItemId: string, update: Object) {
      if (!this.currentCourse || this.currentChapterIndex === null || !this.currentCourse.chapters) {
        throw new Error('No or empty course or no chapter index set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.updateSectionContentItemWithCategorizationExercise(contentItemId, update);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateCategorizationExerciseCategory(
      sectionId: string,
      contentItemId: string,
      categoryId: string,
      categoryName: string,
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      await (
        await getApiClient()
      ).courseSectionItems.updateCategoryInCategorizationExercise(contentItemId, {
        id: categoryId,
        name: categoryName,
      });

      let sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      let contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (contentItem) => contentItem.id === contentItemId,
      );
      let categoryIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories.findIndex(
        (category) => category.id === categoryId,
      );

      // Update the category name in the course state
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].categorization_exercise.categorization_exercise_categories[categoryIndex].name = categoryName;

      // Update timestamp
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();

      // Save to localStorage
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addItemToCategorizationExerciseCategory(sectionId: string, contentItemId: string, categoryId: string) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      let item = await (
        await getApiClient()
      ).courseSectionItems.addItemToCategoryInCategorizationExercise(contentItemId, {
        categorization_exercise_category_id: categoryId,
      });

      let sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      let contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (contentItem) => contentItem.id === contentItemId,
      );
      let categoryIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories.findIndex(
        (category) => category.id === categoryId,
      );

      // Add the item to the category in the course state
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].categorization_exercise.categorization_exercise_categories[categoryIndex].categorization_items.push(item);

      // Update timestamp
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();

      // Save to localStorage
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateCategorizationExerciseItem(
      sectionId: string,
      contentItemId: string,
      categoryId: string,
      itemId: string,
      itemContent: string,
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      await (
        await getApiClient()
      ).courseSectionItems.updateItemInCategoryInCategorizationExercise(contentItemId, {
        id: itemId,
        content: itemContent,
      });

      let sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      let contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (contentItem) => contentItem.id === contentItemId,
      );
      let categoryIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories.findIndex(
        (category) => category.id === categoryId,
      );
      let itemIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories[
        categoryIndex
      ].categorization_items.findIndex((item) => item.id === itemId);

      // Update the item content in the course state
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].categorization_exercise.categorization_exercise_categories[categoryIndex].categorization_items[
        itemIndex
      ].content = itemContent;

      // Update timestamp
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();

      // Save to localStorage
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async removeCategorizationExerciseItem(
      sectionId: string,
      contentItemId: string,
      categoryId: string,
      itemId: string,
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      await (await getApiClient()).courseSectionItems.deleteCategorizationExerciseItem(contentItemId, itemId);

      let sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      let contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (contentItem) => contentItem.id === contentItemId,
      );
      let categoryIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories.findIndex(
        (category) => category.id === categoryId,
      );
      let itemIndex = this.currentCourse.chapters[this.currentChapterIndex].sections[
        sectionIndex
      ].section_content_items[contentItemIndex].categorization_exercise.categorization_exercise_categories[
        categoryIndex
      ].categorization_items.findIndex((item) => item.id === itemId);

      // Remove the item from the category in the course state
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].categorization_exercise.categorization_exercise_categories[categoryIndex].categorization_items.splice(
        itemIndex,
        1,
      );

      // Update timestamp
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();

      // Save to localStorage
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async removeContentItem(sectionId: string, contentItemId: string) {
      console.log('Deleting content item: ' + contentItemId);
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.deleteSectionContentItem(contentItemId);
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async refetchSelectedSection(externalSectionIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections[externalSectionIndex]) {
        throw new Error('No section at external index ' + externalSectionIndex);
      }
      if (this.settingCoursePromise) {
        console.log('refetchSelectedSection: setting course still in progress, waiting for promise');
        await this.settingCoursePromise;
        console.log('refetchSelectedSection: setting course promise resolved. Continue refetching section.');
      }
      if (this.settingChapterPromise) {
        console.log('refetchSelectedSection: setting chapter still in progress, waiting for promise');
        await this.settingChapterPromise;
        console.log('refetchSelectedSection: setting chapter promise resolved. Continue refetching section.');
      }
      console.log('refreshing section #', externalSectionIndex);
      // Before checking anything else, validate the section index first
      if (typeof externalSectionIndex !== 'number' || externalSectionIndex < 0 || this.currentChapterIndex === null) {
        throw new Error('Invalid section index');
      }
      // Check course and chapter structure
      if (!this.currentCourse?.chapters?.[this.currentChapterIndex]?.sections) {
        throw new Error('Course structure is invalid');
      }
      // Check if section index is within bounds
      if (externalSectionIndex >= this.currentCourse.chapters[this.currentChapterIndex].sections.length) {
        throw new Error('Section index out of bounds');
      }
      this.currentCourse.chapters[this.currentChapterIndex].sections[externalSectionIndex] = await (
        await getApiClient()
      ).sections.getSection(this.currentCourse.chapters[this.currentChapterIndex].sections[externalSectionIndex].id);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addCase(sectionId: string, caseId: string, pageIndex: number) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.appendSectionContentItemWithCase(sectionId, caseId, pageIndex);
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addTextSnippet(sectionId: string, pageIndex: number, designation: string = 'GENERAL') {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithTextContent(sectionId, {
        content: 'Ein Text mit Erläuterungen, Tabellen, ... .',
        page_index: pageIndex,
        designation: designation,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addEmptyVocabList(sectionId: string, pageIndex: number) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.appendSectionContentItemWithEmptyVocabList(sectionId, pageIndex);
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },

    async addEmptySetPhraseList(sectionId: string, pageIndex: number, addSingleSetPhrase: boolean = false) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithEmptySetPhraseList(sectionId, pageIndex, addSingleSetPhrase);
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateMeaningAndMakingExercise(
      sectionId: string,
      contentItemId: string,
      meaningAndMakingExercise: {
        title: string;
        description: string;
        tasks: { [key: string]: string };
      },
    ) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.updateSectionContentItemWithMeaningAndMakingExercise(contentItemId, {
        title: meaningAndMakingExercise.title,
        description: meaningAndMakingExercise.description,
        tasks: meaningAndMakingExercise.tasks,
      });

      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section: Section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex(
        (contentItem: Object) => contentItem.id === contentItemId,
      );
      if (!!meaningAndMakingExercise.title) {
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].meaning_and_making_exercise.title = meaningAndMakingExercise.title;
      }
      if (!!meaningAndMakingExercise.description) {
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].meaning_and_making_exercise.description = meaningAndMakingExercise.description;
      }
      if (!!meaningAndMakingExercise.tasks) {
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].meaning_and_making_exercise.tasks = meaningAndMakingExercise.tasks;
      }
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addSetPhraseToContentItemWithSetPhraseList(
      sectionId: string,
      contentItemId: string,
      setPhrase: {
        phrase: string;
        explanation: string;
      },
    ) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.addSetPhraseToContentItemWithSetPhraseList(contentItemId, setPhrase);
      // refetch section item's set phrase list
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section: Section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex(
        (contentItem: Object) => contentItem.id === contentItemId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.set_phrase_list = await (
        await getApiClient()
      ).courseSectionItems.getSetPhraseListForContentItem(contentItemId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      // force remount
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();
    },
    async deleteSetPhraseFromContentItemWithSetPhraseList(
      sectionId: string,
      contentItemId: string,
      setPhraseId: string,
      setPhraseListId: string,
    ) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.deleteSetPhraseFromContentItemWithSetPhraseList(contentItemId, setPhraseId, setPhraseListId);
      // refetch section item's set phrase list
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section: Section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex(
        (contentItem: Object) => contentItem.id === contentItemId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.set_phrase_list = await (
        await getApiClient()
      ).courseSectionItems.getSetPhraseListForContentItem(contentItemId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      // force remount
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].updateTimestamp = new Date().toISOString();
    },
    async updateSetPhraseInContentItemWithSetPhraseList(
      sectionId: string,
      contentItemId: string,
      setPhraseListId: string,
      setPhraseId: string,
      setPhraseUpdate: {
        phrase?: string | null;
        explanation?: string | null;
      },
    ) {
      const alertStore = useAlertStore();
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems
        .updateSetPhraseInContentItemWithSetPhraseList(contentItemId, setPhraseListId, setPhraseId, setPhraseUpdate)
        .catch((error) => {
          alertStore.error('Fehler beim Speichern des Redemittels', 'Fehler', error);
        });
      // update local storage
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section: Section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex(
        (contentItem: Object) => contentItem.id === contentItemId,
      );
      let setPhraseIndex = section.section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.set_phrase_list.set_phrases.findIndex(
        (setPhrase: SetPhrase) => setPhrase.id === setPhraseId,
      );
      let setPhrase =
        section.section_content_items[contentItemIndex].meaning_and_making_exercise.set_phrase_list.set_phrases[
          setPhraseIndex
        ];
      section.section_content_items[contentItemIndex].meaning_and_making_exercise.set_phrase_list.set_phrases[
        setPhraseIndex
      ] = {
        ...setPhrase,
        ...setPhraseUpdate,
      };
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },

    async addFormStructuredExercise(
      sectionId: string,
      taskInstructions: string,
      caseDescription: string,
      pageIndex: number,
    ) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithFormStructuredExercise(sectionId, {
        task_instructions: taskInstructions,
        case_description: caseDescription,
        page_index: pageIndex,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addFormCaseExercise(sectionId: string, taskInstructions: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithFormCaseExercise(sectionId, {
        task_instructions: taskInstructions,
        page_index: pageIndex,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addVocabItemToContentItemWithVocabList(
      sectionId: string,
      contentItemId: string,
      vocabItem: VocabItemUpdate = VocabItemDefault,
    ) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems.addVocabItemToContentItemWithVocabList(contentItemId, vocabItem);
      // refetch section item's vocab list
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex((contentItem) => contentItem.id === contentItemId);
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.vocab_list = await (
        await getApiClient()
      ).courseSectionItems.getVocabListForContentItem(contentItemId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async deleteVocabItemFromContentItemWithVocabList(sectionId: string, contentItemId: string, vocabItemId: string) {
      if (this.currentChapterIndex == null || !this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.deleteVocabItemFromContentItemWithVocabList(contentItemId, vocabItemId);
      // refetch section item's vocab list
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section: Section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex(
        (contentItem: Object) => contentItem.id === contentItemId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.vocab_list = await (
        await getApiClient()
      ).courseSectionItems.getVocabListForContentItem(contentItemId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateVocabItemInContentItemWithVocabList(
      sectionId: string,
      contentItemId: string,
      vocabItemId: string,
      vocabItemUpdate: VocabItemUpdate,
    ) {
      const alertStore = useAlertStore();
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (await getApiClient()).courseSectionItems
        .updateVocabItemInContentItemWithVocabList(contentItemId, vocabItemId, vocabItemUpdate)
        .catch((error) => {
          alertStore.error('Fehler beim Speichern der Vokabel', 'Fehler', error);
        });
      // update local storage
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section: Section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex(
        (contentItem: Object) => contentItem.id === contentItemId,
      );
      let vocabItemIndex = section.section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.vocab_list.vocab_items.findIndex(
        (vocabItem: VocabItem) => vocabItem.id === vocabItemId,
      );
      let vocabItem =
        section.section_content_items[contentItemIndex].meaning_and_making_exercise.vocab_list.vocab_items[
          vocabItemIndex
        ];
      section.section_content_items[contentItemIndex].meaning_and_making_exercise.vocab_list.vocab_items[
        vocabItemIndex
      ] = {
        ...vocabItem,
        ...vocabItemUpdate,
      };
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addVocabListFromExtractions(sectionId: string, textToExtractFrom: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithVocabListExtractingFromString(
        sectionId,
        textToExtractFrom,
        pageIndex,
      );
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async generateAndAddMoreVocab(sectionId: string, contentItemId: string, context: string) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      let updatedContentItem = await (
        await getApiClient()
      ).courseSectionItems.addMoreVocabOfSameContextToVocabList(contentItemId, context);
      let sectionIndex = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      let section = this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex];
      let contentItemIndex = section.section_content_items.findIndex((contentItem) => contentItem.id === contentItemId);
      console.log('updated content item: ', JSON.stringify(updatedContentItem));
      this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
        contentItemIndex
      ].meaning_and_making_exercise.vocab_list = updatedContentItem.meaning_and_making_exercise.vocab_list;
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addClassificationExercise(sectionId: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithEmptyCategorizationExercise(sectionId, {
        page_index: pageIndex,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addMcQuestion(sectionId: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithResourceWithQuestions(sectionId, {
        task_instructions: '',
        show_resource_text: false,
        init_empty_mc_question: true,
        init_empty_open_question: false,
        page_index: pageIndex,
        resource_type: ResourceType.NO_RESOURCE,
      }); // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addOpenQuestion(sectionId: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithResourceWithQuestions(sectionId, {
        task_instructions: '',
        show_resource_text: false,
        init_empty_mc_question: false,
        init_empty_open_question: true,
        page_index: pageIndex,
        resource_type: ResourceType.NO_RESOURCE,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addContentWithQuestions(sectionId: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithResourceWithQuestions(sectionId, {
        title: '',
        task_instructions: '',
        resource_text: '',
        show_resource_text: true,
        init_empty_mc_question: false,
        init_empty_open_question: false,
        page_index: pageIndex,
        resource_type: ResourceType.READING,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addCloze(sectionId: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithCloze(sectionId, {
        title: '',
        html_content: '<p></p>',
        page_index: pageIndex,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async addPairOfTermsGame(sectionId: string, pageIndex: number) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.appendSectionContentItemWithPairOfTermsGame(sectionId, {
        page_index: pageIndex,
      });
      // refetch section
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updatePairOfTermsGame(
      sectionId: string,
      contentItemId: string,
      pairOfTermsGameUpdate: PairOfTermsGameUpdate,
    ) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        throw new Error('No current chapter set');
      }
      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        throw new Error('No sections in current chapter');
      }
      await (
        await getApiClient()
      ).courseSectionItems.updateSectionContentItemWithPairOfTermsGame(contentItemId, pairOfTermsGameUpdate, true);
      // refetch section
      // TODO improve performance - do not refetch entire section, only updated game!
      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = await (
        await getApiClient()
      ).sections.getSection(sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async publishSection(sectionId: string, publish: boolean) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        return;
      }
      await this.updateSection(sectionId, { publishedAt: publish ? new Date().toISOString() : null });
    },
    async updateSection(sectionId: string, options: UpdateSectionOptions) {
      if (!this.currentCourse.chapters[this.currentChapterIndex]) {
        return;
      }

      const { title = null, learningObjectives = null, internalIndex = null, publishedAt = null } = options;

      // global section updates (everything apart from content items)
      const updatedSection = await (
        await getApiClient()
      ).sections.updateSection(sectionId, {
        title: title,
        learning_objectives: learningObjectives,
        published_at: publishedAt,
      });

      if (!this.currentCourse.chapters[this.currentChapterIndex].sections) {
        this.currentCourse.chapters[this.currentChapterIndex].sections = [];
      }

      let index = this.currentCourse.chapters[this.currentChapterIndex].sections.findIndex(
        (section) => section.id === sectionId,
      );

      // Merge the updated data with existing section data instead of replacing
      this.currentCourse.chapters[this.currentChapterIndex].sections[index] = {
        ...this.currentCourse.chapters[this.currentChapterIndex].sections[index],
        ...updatedSection,
      };

      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));

      // Log the final state for debugging
      console.log(
        'Final section state:',
        JSON.stringify(this.currentCourse.chapters[this.currentChapterIndex].sections[index]),
      );
    },
    async updateChapter(chapterId: string, options: { title?: string | null; description?: string | null }) {
      if (!this.currentCourse) {
        return;
      }
      const { title = null, description = null } = options;
      const updatedChapter = await (await getApiClient()).chapters.updateChapter(chapterId, { title, description });
      this.currentCourse.chapters[this.currentChapterIndex].title = updatedChapter.title;
      this.currentCourse.chapters[this.currentChapterIndex].description = updatedChapter.description;
      this.currentCourse.chapters[this.currentChapterIndex].title = updatedChapter.title;
      this.currentCourse.chapters[this.currentChapterIndex].description = updatedChapter.description;
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      this.lastRemountEnforced = new Date();
    },
    async deleteSection(sectionId: string) {
      if (!this.currentChapter) {
        return;
      }
      await (await getApiClient()).sections.deleteSection(sectionId);
      this.currentCourse.chapters[this.currentChapterIndex].sections = this.currentCourse.chapters[
        this.currentChapterIndex
      ].sections?.filter((section) => section.id !== sectionId);
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async requestSectionTranslations(sectionId: string, sourceLanguageCode: string, targetLanguageCodes: string[]) {
      if (!this.currentChapter) {
        return;
      }
      await (
        await getApiClient()
      ).sections.requestSectionTranslations(sectionId, sourceLanguageCode, targetLanguageCodes);
    },
    async extractSectionVocabularyAndExampleFormulations(sectionId: string) {
      if (!this.currentChapter) {
        return;
      }
      await (await getApiClient()).sections.extractSectionVocabularyAndExampleFormulations(sectionId);
    },
    async updateChapterTitleAndDescription(chapterId: string, title: string, description: string) {
      if (!this.currentCourse) {
        return;
      }
      const updatedChapter = await (
        await getApiClient()
      ).chapters.updateChapter(chapterId, {
        title,
        description,
      });
      if (!this.currentCourse.chapters) {
        this.currentCourse.chapters = [];
      }
      // updateChapter is not fully loaded, so we move the old section to the new index and only updated the
      // properties that we actually updated, i.e. title and content
      const index = this.currentCourse.chapters.findIndex((chapter) => chapter.id === chapterId);
      this.currentCourse.chapters[index].title = updatedChapter.title;
      this.currentCourse.chapters[index].description = updatedChapter.description;
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateCourseTitleAndDescription(courseId: string, title: string, description: string) {
      console.log(
        'updateCourseTitleAndDescription: courseId: ' +
          courseId +
          ', title: ' +
          title +
          ', description: ' +
          description,
      );
      if (!this.currentCourse) {
        console.log('updateCourseTitleAndDescription: No current course set');
        return;
      }
      const updatedCourse = await (
        await getApiClient()
      ).courses.updateCourse(courseId, {
        title,
        description,
      });
      console.log(
        'updateCourseTitleAndDescription: updatedCourse: ' + updatedCourse.title + ', ' + updatedCourse.description,
      );
      // updateCourse is not fully loaded, so we only updated the
      // properties that we actually updated, i.e. title and content
      this.currentCourse.title = updatedCourse.title;
      this.currentCourse.description = updatedCourse.description;
      localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
    },
    async updateTextItem(sectionId: string, contentItemId: string, content: string) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      // Update via API
      await (
        await getApiClient()
      ).courseSectionItems.updateSectionContentItemWithTextContent(contentItemId, {
        content: content,
      });

      // Update local state
      const sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      const contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (item) => item.id === contentItemId,
      );

      if (contentItemIndex !== -1) {
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].text_item.content = content;

        // Update timestamp
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].updateTimestamp = new Date().toISOString();

        // Save to localStorage
        localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      }
    },
    async updateClozeItem(
      sectionId: string,
      contentItemId: string,
      updates: {
        title: string;
        task_instructions: string;
        html_content: string;
      },
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      // Update via API
      await (await getApiClient()).courseSectionItems.updateClozeItem(contentItemId, updates);

      // Update local state
      const sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      const contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (item) => item.id === contentItemId,
      );

      if (contentItemIndex !== -1) {
        const cloze =
          this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
            contentItemIndex
          ].cloze;

        cloze.title = updates.title;
        cloze.task_instructions = updates.task_instructions;
        cloze.html_content = updates.html_content;

        // Update timestamp
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].updateTimestamp = new Date().toISOString();

        // Save to localStorage
        localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      }
    },
    async updateFormStructuredExercise(
      sectionId: string,
      contentItemId: string,
      updates: {
        task_instructions: string;
        case_story: string;
        case_description: string;
      },
      remountWithUpdate: boolean = false,
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      // Update via API
      await (await getApiClient()).courseSectionItems.updateFormStructuredExerciseItem(contentItemId, updates);

      // Update local state
      const sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      const contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (item) => item.id === contentItemId,
      );

      if (contentItemIndex !== -1) {
        const exercise =
          this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
            contentItemIndex
          ].form_structured_exercise;

        exercise.task_instructions = updates.task_instructions;
        exercise.case_story = updates.case_story;
        exercise.case_description = updates.case_description;

        // Update timestamp
        if (remountWithUpdate) {
          this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
            contentItemIndex
          ].updateTimestamp = new Date().toISOString();
        }

        // Save to localStorage
        localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      }
    },
    async updateBarthelForm(
      sectionId: string,
      contentItemId: string,
      updates: {
        form_data: any;
        human_readable_form_data: string;
      },
      isFormCase: boolean = false,
      remountWithUpdate: boolean = false,
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      // Update via API
      if (!isFormCase) {
        await (await getApiClient()).courseSectionItems.updateFormStructuredExerciseItem(contentItemId, updates);
      } else {
        await (await getApiClient()).courseSectionItems.updateFormCaseExerciseItem(contentItemId, updates);
      }

      // Update local state
      const sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      const contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (item) => item.id === contentItemId,
      );

      if (contentItemIndex !== -1) {
        const exercise = isFormCase
          ? this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
              contentItemIndex
            ].form_case
          : this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
              contentItemIndex
            ].form_structured_exercise;

        exercise.form_data = updates.form_data;
        exercise.human_readable_form_data = updates.human_readable_form_data;

        // Update timestamp
        if (remountWithUpdate) {
          this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
            contentItemIndex
          ].updateTimestamp = new Date().toISOString();
        }

        // Save to localStorage
        localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      }
    },
    async updateFormCaseExercise(
      sectionId: string,
      contentItemId: string,
      updates: {
        task_instructions: string;
        case_id: string;
      },
    ) {
      if (!this.currentCourse || this.currentChapterIndex === null) {
        throw new Error('No current course or chapter index set');
      }

      const currentChapter = this.currentCourse.chapters[this.currentChapterIndex];
      if (!currentChapter?.sections) {
        throw new Error('No sections in current chapter');
      }

      // Update via API
      await (await getApiClient()).courseSectionItems.updateFormCaseExerciseItem(contentItemId, updates);

      // Update local state
      const sectionIndex = currentChapter.sections.findIndex((section) => section.id === sectionId);
      const contentItemIndex = currentChapter.sections[sectionIndex].section_content_items.findIndex(
        (item) => item.id === contentItemId,
      );

      if (contentItemIndex !== -1) {
        const exercise =
          this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
            contentItemIndex
          ].form_case;

        exercise.task_instructions = updates.task_instructions;
        exercise.case_id = updates.case_id;

        // Update timestamp
        this.currentCourse.chapters[this.currentChapterIndex].sections[sectionIndex].section_content_items[
          contentItemIndex
        ].updateTimestamp = new Date().toISOString();

        // Save to localStorage
        localStorage.setItem('currentCourse', JSON.stringify(this.currentCourse));
      }
    },
    reset() {
      this.currentCourse = null;
      this.currentChapterIndex = null;
      this.settingCoursePromise = null;
      this.settingChapterPromise = null;
      this.lastRemountEnforced = null;
    },
  },
});
