<script setup lang="ts">
const props = defineProps({
  icon: {
    type: Object,
    default: null,
  },
  title: {
    type: String,
    default: '',
  },
  value: {
    type: [Number, null],
    default: null,
  },
});
</script>

<template>
  <div class="flex flex-row items-center border text-sm border-black border-opacity-5 rounded-lg px-3 py-2 gap-x-1">
    <component v-if="icon" :is="icon" :size="16" />
    {{ props.value !== null ? props.value : '' }}
    {{ props.title }}
  </div>
</template>
