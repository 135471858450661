import { SectionContentItem_Input as SectionContentItem } from '@/apiclient';

type PageGroup = {
  pageIndex: number; // original, non-continuous index
  arrayIndex: number; // continuous index for navigation
  items: SectionContentItem[];
};

export class CourseService {
  static sectionContentItemsByPage(sectionContentItems: SectionContentItem[]): PageGroup[] {
    if (!sectionContentItems) {
      return [];
    }

    const groupedItems: { [key: number]: SectionContentItem[] } = {};

    sectionContentItems.forEach((item) => {
      if (!groupedItems[item.page_index]) {
        groupedItems[item.page_index] = [];
      }
      groupedItems[item.page_index].push(item);
    });

    return Object.entries(groupedItems).map(([pageIndex, items], arrayIndex) => ({
      pageIndex: parseInt(pageIndex),
      arrayIndex,
      items: items,
    }));
  }

  static displayCotentItemType(item: SectionContentItem): string {
    switch (item.content_type.toUpperCase()) {
      case 'PAGE_HEAD':
        return 'Seitenüberschrift';
      case 'TEXT':
        switch (item.item_designation || 'GENERAL') {
          case 'GRAMMAR_BOX':
            return 'Grammatik-Box';
          case 'LEARNING_STRATEGY_BOX':
            return 'Lernstrategie-Box';
          case 'CULTURE_BOX':
            return 'Kultur-Box';
          case 'CLINICAL_PRACTICE_BOX':
            return 'Klinische Praxis-Box';
          case 'GENERAL':
            return 'Text';
          default:
            return 'Text';
        }
      case 'MEDIA':
        return 'Medien';
      case 'CASE':
        return 'Fallbeispiel';
      case 'FORM_CASE':
        return 'Formular-Fallbeispiel';
      case 'FORM_STRUCTURED_EXERCISE':
        return 'Strukturierte Formular-Übung';
      case 'VOCAB_LIST':
        return 'Vokabelliste';
      case 'PAIR_OF_TERMS_GAME':
        return 'Zuordnungsübung (Paare bilden)';
      case 'CATEGORIZATION_EXERCISE':
        return 'Kategorisierungsübung';
      case 'CLOZE':
        return 'Lückentext';
      case 'RESOURCE_WITH_QUESTIONS':
        return 'Lesematerial oder Audio-Dialog mit Fragen';
      case 'SET_PHRASE_LIST':
        return 'Redemittel-Box';
      default:
        return item.content_type;
    }
  }
}
