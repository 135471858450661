<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import CourseInteractionQuickactionResume from '@/components/quickaction-cards/CourseInteractionQuickactionResume.vue';
const authStore = useAuthStore();
const courseInteractionStore = useCourseInteractionStore();

const props = defineProps({
  inlineOverflowingX: {
    type: Boolean,
    default: false,
  },
});

const { allCourseInteractionsWithProgress: courseInteractions } = storeToRefs(courseInteractionStore);

const fetchCompleted = ref(false);

async function loadCourseInteractionsOfUser() {
  await courseInteractionStore.loadCourseInteractionsOfUserWithProgress(authStore.userId, true);
  fetchCompleted.value = true;
}

onMounted(async () => {
  await loadCourseInteractionsOfUser();
  // load dummy course images
  //   for (let i = 0; i < 13; i++) {
  //     // console.log('Importing: ', `@/assets/images/courses/0${i}.png`);
  //     let num = i < 10 ? `0${i}` : i;
  //     import(`../assets/images/courses/${num}.png`).then((img) => {
  //       imgs.value.push(img.default);
  //     });
  //   }
  //   console.log('Loaded course highlight card images: ', imgs.value.length, ' images loaded.');
  //
});
</script>

<template>
  <div v-if="!fetchCompleted" class="w-full flex justify-center py-4">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
    />
  </div>
  <div v-else class="relative w-full">
    <div class="flex gap-4 overflow-x-auto px-4 -mx-4 pb-4 snap-x snap-mandatory">
      <div v-for="(courseInteraction, index) in courseInteractions" class="w-full flex-none snap-center">
        <CourseInteractionQuickactionResume :course-interaction="courseInteraction" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.overflow-x-auto {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-x-auto::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
</style>
