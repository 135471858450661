import { ref } from 'vue';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import { SoundMeter } from '@/audio/soundmeter.js';

declare global {
  interface ImportMeta {
    env: {
      DEV: boolean;
      MODE: string;
    };
  }
}

export default class AudioRecorderService {
  public isRecording = ref(false);
  public transcriptionInProgress = ref(false);
  public volume = ref(0.0);

  private recorder: RecordRTCPromisesHandler | null = null;
  private minPttDuration = 500; // in ms
  private pttStartTime: Date | null = null;
  private stream: MediaStream | null = null;
  private isDev = !!import.meta.env.DEV;

  async requestMicrophoneAccess(): Promise<MediaStream> {
    try {
      try {
        // Check if we're in test environment using window.process.env
        const isTestEnvironment = typeof window !== 'undefined' && window.process?.env?.NODE_ENV === 'test';

        if (this.isDev) {
          console.log('Browser NODE_ENV:', window.process?.env?.NODE_ENV);
          console.log('Is test environment:', isTestEnvironment);
        }

        // Configure audio constraints based on environment
        const audioConstraints = isTestEnvironment
          ? {
              audio: {
                echoCancellation: false, // Disable for tests
              },
            }
          : {
              audio: true, // Use default settings in production
            };

        if (this.isDev) {
          console.log('Using audio constraints:', audioConstraints);
        }

        this.stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
        return this.stream;
      } catch (error) {
        // fallback: production mode with default settings
        console.log('Error requesting microphone access:', error);
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        return this.stream;
      }
    } catch (fallbackError) {
      const alertStore = useAlertStore();
      alertStore.error(
        'Error accessing microphone',
        'Error',
        fallbackError instanceof Error ? fallbackError : new Error(String(fallbackError)),
      );
      throw fallbackError;
    }
  }

  async startRecording() {
    if (!this.stream) {
      this.stream = await this.requestMicrophoneAccess();
    }

    if (!this.stream) throw new Error('Failed to get audio stream');
    if (this.isDev) window.isRecording = true;

    this.isRecording.value = true;
    this.pttStartTime = new Date();

    this.recorder = new RecordRTCPromisesHandler(this.stream, {
      type: 'audio',
      mimeType: 'audio/webm',
      timeSlice: 1000,
      ondataavailable: (blob: Blob) => {
        console.log('Data available:', blob.size, 'bytes');
      },
    });

    await this.recorder.startRecording();
    await this.trackVolume(this.stream);
  }

  async trackVolume(stream: MediaStream) {
    try {
      const audioContext = new AudioContext();
      const soundMeter = new SoundMeter(audioContext);
      window.soundMeter = soundMeter;

      await new Promise<void>((resolve, reject) => {
        soundMeter.connectToSource(stream, (error: any) => {
          if (error) {
            console.error('Error connecting sound meter:', error);
            reject(error);
            return;
          }
          console.log('Sound meter connected successfully');
          resolve();
        });
      });

      // Setup volume tracking interval
      const meterRefresh = setInterval(() => {
        if (!window.soundMeter || !this.isRecording.value) {
          clearInterval(meterRefresh);
          this.volume.value = 0.0;
          return;
        }
        this.volume.value = Number(soundMeter.instant.toFixed(2));
      }, 50);

      console.log('Volume tracking started');
    } catch (e) {
      console.error('Error in trackVolume:', e);
      // dont throw, just silently continue without volume tracking
    }
  }

  async stopRecording(language: string = 'deu', ttsContext: string | null = null) {
    this.isRecording.value = false;

    if (!this.recorder) return;
    if (this.isDev) window.isRecording = false;

    const pttStopTime = new Date();
    if (this.pttStartTime && pttStopTime.getTime() - this.pttStartTime.getTime() < this.minPttDuration) {
      const alertStore = useAlertStore();
      alertStore.info('Mikrofon-Knopf gedrückt halten, um zu sprechen.');
      return;
    }

    try {
      await this.recorder.stopRecording();
      const blob = await this.recorder.getBlob();
      if (!blob || blob.size === 0) {
        throw new Error('No audio data recorded');
      }

      this.transcriptionInProgress.value = true;
      if (this.isDev) {
        console.log('Transcription attempt at:', Date.now());
      }

      const result = await this.transcribeAudio(blob, language, ttsContext);

      if (result.length > 0 && this.isDev) {
        console.log('Transcription result:', result);
      }

      return result;
    } catch (error) {
      console.error('Error in stopRecording:', error);
      const alertStore = useAlertStore();
      alertStore.error(
        'Error when transcribing audio.',
        'Error',
        error instanceof Error ? error : new Error(String(error)),
      );
      throw error;
    } finally {
      this.transcriptionInProgress.value = false;
    }
  }

  cleanup() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
    this.recorder = null;
    this.isRecording.value = false;
    this.pttStartTime = null;
    this.transcriptionInProgress.value = false;
  }

  private shouldRefuseTranscription(text: string): boolean {
    const refusePatterns = [
      "Das war's. Bis zum nächsten Mal.",
      "Das war's für heute. Bis zum nächsten Mal.",
      'Untertitel der Amara.org-Community',
      "Hallo und herzlichen Dank für's Zuschauen.",
      "So, das war's. Ich hoffe, es hat euch gefallen. Bis zum nächsten Mal!",
      'Untertitel im Auftrag des ZDF',
      'des ZDF, funk 2017',
      'Untertitelung aufgrund der Audioqualität nicht möglich',
      'Untertitel von Stephanie Geiges',
      'Hallo und herzlich willkommen bei der Live-Untertitelung von SWISS TXT.',
      'It was different for them when you demand them by talking to as a language designed to communicate with',
      'Update Google Chrome Today, I am going to demo this openSRC',
      'Ich hoffe, das Video hat euch gefallen',
      'Ich hoffe, es hat euch gefallen. Wenn ja, dann lasst mir ein Like',
      'LookingForwardToAnalysis',
      'Sprecher Jochen Graf Deutsche Bearbeitung sub&dub company Redaktion Julian Windisch',
      'But it could also switch to English',
      'It could also switch to English',
      'Thank you for watching',
    ];

    return refusePatterns.some((pattern) => text === pattern || text.includes(pattern));
  }

  private getAudioFormat(blob: Blob): string {
    let audioFormat = '';
    if (blob.type.includes('audio/webm')) {
      audioFormat = 'webm';
    } else if (blob.type.includes('audio/wav')) {
      audioFormat = 'wav';
    } else if (blob.type.includes('audio/mp4')) {
      audioFormat = 'mp4';
    } else if (blob.type.includes('audio/ogg')) {
      audioFormat = 'ogg';
    } else if (blob.type.includes('audio/mp3')) {
      audioFormat = 'mp3';
    } else if (blob.type.includes('audio/mpeg')) {
      audioFormat = 'mpeg';
    } else if (blob.type.includes('audio/mpga')) {
      audioFormat = 'mpga';
    } else if (blob.type.includes('audio/oga')) {
      audioFormat = 'oga';
    } else if (blob.type.includes('audio/flac')) {
      audioFormat = 'flac';
    } else if (blob.type.includes('audio/m4a')) {
      audioFormat = 'm4a';
    } else {
      // fallback: try send as webm ...
      const alertStore = useAlertStore();
      alertStore.info('Audio format ' + blob.type + ' not supported. Sending as webm.');
      audioFormat = 'webm';
    }
    console.log('Audio format:', audioFormat);
    return audioFormat;
  }

  private async transcribeAudio(blob: Blob, language: string, ttsContext: string | null = null): Promise<string> {
    if (!blob || blob.size === 0) {
      throw new Error('No audio data recorded');
    }

    const audioFormat = this.getAudioFormat(blob);

    const result = await (await getApiClient()).stt.speechToText({ audio: blob }, audioFormat, language, ttsContext);

    if (this.shouldRefuseTranscription(result)) {
      console.warn('Transcription failed. Refusing "' + result + '". Sending empty string.');
      return '';
    }

    if (this.isDev) window.lastTranscriptionText = result;
    return result;
  }
}
