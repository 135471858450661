<script setup>
const emit = defineEmits(['takeDailyQuickTest']);

function startDailyTest() {
  emit('takeDailyQuickTest');
}
</script>

<template>
  <div
    @click="startDailyTest"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col justify-between w-[148px] h-[148px]"
  >
    <div class="flex justify-start">
      <span class="text-sm font-medium text-gray-600 dark:text-gray-400">{{ $t('message.vocabDailyTest') }}</span>
    </div>
    <div class="flex items-center justify-center flex-1">
      <button class="inline-flex items-center gap-2 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-50">
        <span class="material-symbols-outlined">translate</span>
        {{ $t('message.start') }}
      </button>
    </div>
    <div class="flex justify-end">
      <span class="text-sm">+20 XP</span>
    </div>
  </div>
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
