<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import TextEditor from '@/views/courses/TextEditor.vue';
import { onBeforeUnmount, onMounted, ref, watch, nextTick } from 'vue';
import { debounce } from 'lodash';
import ChapterTableOfContents from '@/views/courses/ChapterTableOfContents.vue';
import Tooltip from '@/components/Tooltip.vue';
import Badge from '@/components/Badge.vue';
import { useAlertStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { computed } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import { useI18n } from 'vue-i18n';
import MercuuBrandLogo from '@/components/MercuuBrandLogo.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import confetti from 'canvas-confetti';
import { router } from '@/router';
import { sanitizeContent } from '@/helper/stringArithmetics';
const { t } = useI18n();

const emit = defineEmits([
  'scrollTo',
  'toNextSection',
  'toPreviousSectionEnd',
  'toPreviousChapterEnd',
  'toNextPage',
  'toPreviousPage',
  'toNextChapter',
  'onToggleIsEditing',
  'onAddPage',
  'onDeleteEmptySection',
  'onEditOrder',
]);
const titleEditor = ref(null);
const subtitleEditor = ref(null);
const learningObjectives = ref(null);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const leaveInvalidPageTimeout = ref(null);

const unsavedChanges = ref(false);
const isSavingChanges = ref(false);

const props = defineProps({
  allowEditing: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  chapter: {
    type: Object,
    required: true,
  },
  section: {
    type: Object,
    required: true,
  },
  numVisibleSections: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  numPages: {
    type: Number,
    required: true,
  },
  isInView: {
    type: Boolean,
    required: true,
  },
  isDeleted: {
    type: Boolean,
    required: true,
  },
});

const headerContainer = ref<HTMLElement | null>(null);
const fullHeight = ref(0);
const minHeight = ref(48);
const initialHeightMeasured = ref(false);
const isExpanded = ref(true);
const isFullExpanded = ref(true);
const HYSTERESIS = 10; // 10px buffer zone

const invalidPage = computed(() => {
  return props.pageIndex > props.numPages - 1 && props.numPages > 0;
});

const adjustHeight = async () => {
  await nextTick();
  if (headerContainer.value) {
    const height = headerContainer.value.offsetHeight;
    fullHeight.value = height + 16; // add 16px for the padding
    initialHeightMeasured.value = true;
  }
};

watch(
  () => [props.section?.learning_objectives, props.section?.title],
  async () => {
    await nextTick();
    adjustHeight();
  },
  { deep: true },
);

onMounted(async () => {
  await nextTick();
  setTimeout(adjustHeight, 100);
  setTimeout(adjustHeight, 500);
  window.addEventListener('resize', debounce(adjustHeight, 200));
  if (!props.section) {
    throw new Error('Section not found');
  }
  console.log('# learning objectives: ' + props.section.learning_objectives?.length);
  setLearningObjectives(props.section.learning_objectives);
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      console.log('onbeforeunload triggered saveTitleAndLearningObjectives');
      saveTitleAndLearningObjectives();
      return undefined;
    }
  };
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustHeight);
  if (unsavedChanges.value) {
    console.log('onBeforeUnmount triggered saveTitleAndLearningObjectives');
    saveTitleAndLearningObjectives();
  }
  if (props.allowEditing && !isPublished.value && !props.isDeleted) {
    alertStore.info(
      'Bitte beachten Sie, dass die Kurseinheit (Section) ' +
        (props.chapter?.index !== undefined ? props.chapter.index + 1 : '(undefined)') +
        '.' +
        (props.section?.index !== undefined ? props.section.index + 1 : '(undefined)') +
        ' noch nicht veröffentlicht ist. ' +
        'Für Ihre Schüler:innen ist sie daher noch nicht sichtbar!',
      'Kurseinheit (Section) ist verborgen',
    );
  }
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      console.log('onBeforeRouteLeave triggered saveTitleAndLearningObjectives');
      await saveTitleAndLearningObjectives();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const isPublished = computed(() => {
  return props.section?.published_at != null;
});

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  console.log('setLearningObjectives', sectionLearningObjectives);
  if (!sectionLearningObjectives || !Array.isArray(sectionLearningObjectives)) {
    console.warn('Invalid learning objectives data');
    return;
  }
  if (!learningObjectives.value) {
    console.warn('null ref in learningObjectives');
    return;
  }
  if (!props.section) {
    return;
  }
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  try {
    learningObjectives.value.setLearningObjectives(sectionLearningObjectives.slice(), false);
  } catch (error) {
    console.error('Failed to set learning objectives:', error);
  }
}

const publishSection = (publish: boolean) => {
  console.log('publishing: ' + publish);
  if (!props.section) {
    return;
  }
  courseStore
    .publishSection(props.section.id, publish)
    .then(() => {
      alertStore.success(
        publish
          ? 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt für die Kursteilnehmer:innen sichtbar.'
          : 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt vor den Kursteilnehmer:innen verborgen.',
      );
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Veröffentlichen/ Verbergen: ' + error);
      throw Error('Error publishing section', error);
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.allowEditing || props.isDeleted) return;
      setTimeout(() => {
        console.log('watcher triggered saveTitleAndLearningObjectives');
        saveTitleAndLearningObjectives();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

const saveTitleAndLearningObjectives = async () => {
  console.log('saveTitleAndLearningObjectives');
  console.log('allowEditing', props.allowEditing);
  console.log('sectionId', props.section?.id);
  console.log('isDeleted', props.isDeleted);
  console.log('unsavedChanges', unsavedChanges.value);
  console.log('isSavingChanges', isSavingChanges.value);
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  if (!unsavedChanges.value || !props.allowEditing || !props.section?.id || props.isDeleted) return;
  if (!titleEditor.value?.getRawTextContent) {
    console.warn('Title editor not properly initialized');
    return;
  }
  const rawTitle = titleEditor.value.getRawTextContent();
  if (rawTitle === null || rawTitle === undefined) {
    console.warn('Title content is null or undefined');
    return;
  }
  if (!learningObjectives.value?.getLearningObjectives) {
    console.warn('Learning objectives not properly initialized');
    return;
  }
  console.log('saveTitleAndLearningObjectives - deleted? ' + props.isDeleted);
  console.log('learning objectives are: ' + JSON.stringify(learningObjectives.value.getLearningObjectives()));
  console.log('title is: ' + rawTitle);
  courseStore
    .updateSection(props.section.id, {
      title: sanitizeContent(rawTitle),
      learningObjectives: learningObjectives.value.getLearningObjectives(),
      publishedAt: props.section.published_at,
    })
    .then(() => {
      unsavedChanges.value = false;
      // alertStore.success('Titel und Lernziele gespeichert', 'Gespeichert');
      titleEditor.value?.resetEmitState();
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave title', 'Error', error);
      throw new Error('Failed to autosave title');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

const onDeleteSectionIfEmpty = async () => {
  if (props.numPages > 0) {
    alertStore.error(
      'Löschen nicht möglich, da noch Lernbausteine vorhanden sind. Bitte löschen Sie zuerst alle Lernbausteine.',
      'Unzulässig',
    );
    return;
  }
  emit('onDeleteEmptySection', props.section.id);
};

const screenIsMdOrLarger = computed(() => {
  return window.innerWidth > 768;
});

const isPageComplete = computed(() => {
  return !!courseInteractionStore.pageCompletionStatus?.[props.section.id]?.[props.pageIndex];
});

const isSectionComplete = computed(() => {
  let isComplete = true;
  for (let i = 0; i < props.numPages; i++) {
    if (!courseInteractionStore.pageCompletionStatus?.[props.section.id]?.[i]) {
      isComplete = false;
      break;
    }
  }
  return isComplete;
});

const isChapterComplete = computed(() => {
  return courseInteractionStore.chapterIsComplete(props.chapter.id);
});

const isCourseComplete = computed(() => {
  return courseInteractionStore.currentCourseIsComplete;
});

const showExpandedView = computed(() => {
  return props.isInView && props.allowToggleEditing;
});

const finishCourse = async () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 1500));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 1500));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 2000));
  router.push('/courses');
  await new Promise((resolve) => setTimeout(resolve, 500));
  router.go(0);
};

watch(invalidPage, (newValue) => {
  if (newValue) {
    leaveInvalidPageTimeout.value = setTimeout(() => {
      emit('toPreviousPage');
    }, 1000);
  } else if (leaveInvalidPageTimeout.value) {
    clearTimeout(leaveInvalidPageTimeout.value);
    leaveInvalidPageTimeout.value = null;
  }
});

// Add computed property for progress percentage
const progressPercentage = computed(() => {
  if (props.numPages <= 1) return 100;
  return ((props.pageIndex + 1) / props.numPages) * 100;
});

const isLargeScreen = computed(() => {
  return window.innerWidth > 768;
});

defineExpose({
  titleEditor,
  subtitleEditor,
  fullHeight,
  minHeight,
});
</script>

<template>
  <div
    ref="headerContainer"
    v-if="!isDeleted"
    class="w-full relative mx-auto transition-all duration-200 ease-out"
    :class="{
      'pt-0 sm:pt-4': props.isInView,
      'pt-0': !props.isInView,
    }"
    :style="{
      height: props.isInView ? 'auto' : minHeight + 'px',
    }"
  >
    <div
      class="transition-[color] relative duration-300 flex"
      :class="{
        'w-full flex overflow-hidden': props.isInView,
        'w-full justify-end': !props.isInView,
        'rounded-xl border border-gray-200 shadow-sm bg-white dark:bg-neutral-900 dark:border-gray-700': isLargeScreen,
      }"
    >
      <div
        :class="{
          'w-full flex justify-center': props.isInView,
          'rounded-lg border border-gray-200 bg-white shadow-sm mx-4 my-2': !props.isInView,
        }"
      >
        <div class="w-full flex h-fit justify-center">
          <h2 class="w-full flex-col h-fit flex relative py-2 text-center dark:text-white">
            <span
              v-if="isChapterComplete"
              class="w-full flex justify-center items-center inline-flex text-base text-gray-400"
            >
              <span
                v-if="props.chapter.index + 1 < courseStore?.currentCourse?.chapters?.length"
                @click="emit('toNextChapter')"
                class="h-8 mx-auto px-3 py-2 my-4 inline-flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 hover:bg-blue-700"
              >
                🏁&nbsp;&nbsp; Zum nächsten Kapitel &nbsp;🥳
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span
                v-else-if="isCourseComplete && props.chapter.index + 1 === courseStore?.currentCourse?.chapters?.length"
                @click="finishCourse"
                class="h-8 mx-auto px-3 py-2 my-4 inline-flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 hover:bg-blue-700"
              >
                🏁&nbsp;&nbsp; Kurs abschließen &nbsp;🥳&nbsp;🥳&nbsp;🥳
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
            </span>

            <span
              v-if="!isChapterComplete || props.isInView"
              class="justify-between items-center inline-flex text-gray-400"
              :class="{
                'w-full gap-x-8 text-base px-2': props.isInView,
                'w-fit gap-x-2 pt-0 text-base font-normal px-2 pb-1': !props.isInView,
              }"
            >
              <!-- Add progress bar container -->
              <div
                class="absolute bottom-0 left-0 w-full h-1 overflow-hidden"
                :class="{
                  'bg-gray-100': props.pageIndex > -1,
                  'bg-transparent': props.pageIndex === -1,
                  'rounded-b-xl': isLargeScreen,
                }"
              >
                <div
                  class="h-full bg-blue-600 transition-all duration-300 ease-out"
                  :style="{ width: `${progressPercentage}%` }"
                ></div>
              </div>

              <span
                @click="
                  () => {
                    if (props.pageIndex > -1) {
                      emit('toPreviousPage');
                    } else if (props.section.index > 0) {
                      emit('toPreviousSectionEnd');
                    } else if (props.chapter.index > 0) {
                      emit('toPreviousChapterEnd');
                    }
                  }
                "
                v-if="props.pageIndex > -1 || props.section.index > 0 || props.chapter.index > 0"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="h-8 w-8"></span>

              <span
                class="inline-flex gap-x-8 items-center"
                :class="{
                  'py-0': props.isInView,
                  'py-2': !props.isInView,
                }"
              >
                <span
                  class="text-base px-2 relative"
                  :class="{
                    'flex-col flex items-center pr-2': !props.isInView,
                  }"
                >
                  <span
                    v-show="props.allowToggleEditing"
                    :class="{
                      'absolute end-12': props.isInView,
                      '': !props.isInView,
                    }"
                  >
                    <span v-show="props.isInView" class="hidden sm:block text-sm">Bearbeiten</span>
                    <span v-show="!props.isInView" class="text-sm">Bearb.</span>
                  </span>
                  <input
                    v-show="props.allowToggleEditing"
                    :checked="props.allowEditing"
                    @change="emit('onToggleIsEditing', $event?.target?.checked)"
                    :invalidPage="props.disableToggleEditing"
                    type="checkbox"
                    id="hs-xs-switch-edit-chapter"
                    class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 invalidPage:opacity-50 invalidPage:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                  />
                </span>
                <span
                  class="text-base px-2 cursor-pointer items-center text-center flex"
                  :class="{
                    'flex-col flex pr-2 mt-2': !props.isInView,
                  }"
                  v-show="props.allowEditing"
                  @click="emit('onEditOrder')"
                >
                  <span class="text-sm">Sortieren</span>
                  <span
                    translate="no"
                    class="no-translate select-none material-symbols-outlined"
                    :class="{
                      'text-3xl': props.isInView,
                      'text-xl': !props.isInView,
                    }"
                    >swap_vert</span
                  >
                </span>
                <span v-if="!!invalidPage" class="inline-flex">
                  <span
                    class="animate-spin inline-block w-8 h-8 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full mr-6"
                  />
                </span>
                <span
                  v-else-if="props.numPages > 0 && props.pageIndex > -1"
                  :class="{
                    'inline-flex': props.isInView,
                    'flex-col flex': !props.isInView && !props.allowToggleEditing,
                    hidden: props.allowToggleEditing && !props.isInView,
                  }"
                  data-testid="somewhere-to-click-without-consequences "
                >
                  <span class="text-sm sm:text-base">Lernbaustein&nbsp;</span>
                  <span class="text-sm sm:text-base">
                    <span data-testid="page-number" class="text-sm sm:text-base">{{ props.pageIndex + 1 }}</span>
                    <span class="text-sm sm:text-base">&nbsp;von {{ props.numPages }}</span>
                    <span class="pl-4"></span>
                  </span>
                </span>
                <span v-else-if="props.pageIndex === -1" class="text-xl pr-5"> </span>
                <span v-else class="text-xl pr-5"> Noch kein Lernbaustein vorhanden </span>
                <ProgressButton
                  v-show="props.allowEditing && props.isInView"
                  :text="t('message.addParagraph')"
                  icon="add"
                  iconSize="text-3xl"
                  :roundedFull="true"
                  @click="emit('onAddPage')"
                  data-testid="add-page-button"
                />
              </span>

              <span class="items-center group relative">
                <span
                  @click="emit('toNextPage')"
                  v-if="props.pageIndex === -1"
                  class="absolute top-1 right-10 text-blue-600 group-hover:text-blue-700"
                  >Los</span
                >
                <span
                  @click="emit('toNextSection')"
                  v-if="props.pageIndex === props.numPages - 1 && isSectionComplete"
                  class="absolute -top-1 right-10 flex-col flex text-sm text-blue-600 group-hover:text-blue-700"
                  ><span>Nächste</span><span>Lektion</span>
                </span>
                <!-- next page button -->
                <span
                  v-if="props.pageIndex < props.numPages - 1"
                  data-testid="next-page-button"
                  @click="emit('toNextPage')"
                  class="h-8 w-8 flex items-center justify-center text-center border rounded-lg cursor-pointer"
                  :class="{
                    'text-gray-400 group-hover:text-gray-500 group-hover:bg-gray-50 border-gray-300 group-hover:border-gray-500':
                      (!isPageComplete || props.allowEditing) && props.pageIndex > -1,
                    'ring-pulse text-white bg-blue-600 group-hover:bg-blue-700':
                      (isPageComplete && !props.allowEditing) || (props.pageIndex === -1 && !props.allowEditing),
                  }"
                >
                  <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                    >chevron_right</span
                  >
                </span>
                <!-- next section button (show if complete) -->
                <span
                  v-if="props.pageIndex === props.numPages - 1 && isSectionComplete"
                  data-testid="next-page-button"
                  @click="emit('toNextSection')"
                  class="h-8 w-8 flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 group-hover:bg-blue-700"
                >
                  <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                    >chevron_right</span
                  >
                </span>
              </span>
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes ring-pulse {
  0% {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0);
  }
  70% {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.7);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.2);
  }
}

.ring-pulse {
  animation: ring-pulse 2s infinite;
}

.ring-pulse:hover {
  animation: none;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 1);
}

/* Add smooth transition for progress bar */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
</style>
