<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { truncate } from '@/helper';

const props = defineProps({
  caseInteraction: {
    type: Object,
    required: true,
  },
  xpReward: {
    type: Number,
    default: 80,
  },
});

const router = useRouter();

const titlePerson = computed(() => {
  if (!!props.caseInteraction.case.patient) {
    return props.caseInteraction.case.patient;
  }
  if (!props.caseInteraction.case.third_persons) {
    console.error('CaseQuickactionResume.vue: case ' + props.case.id + ' has no patient and third_persons');
    return;
  }
  return props.caseInteraction.case.third_persons[0];
});

const caseSetting = computed(() => {
  return props.caseInteraction.case?.tasks?.[0]?.details?.location || '';
});

const currentTask = computed(() => {
  if (!props.caseInteraction.current_interaction?.current_task_interaction_index) {
    return props.caseInteraction.case.tasks?.[0];
  }
  return props.caseInteraction.case.tasks?.[
    props.caseInteraction.case.current_interaction.current_task_interaction_index
  ];
});

const currentSubtask = computed(() => {
  if (!currentTask.value?.subtasks) return null;

  const taskInteraction =
    props.caseInteraction.current_interaction?.task_interactions?.[
      props.caseInteraction.current_interaction.current_task_interaction_index
    ];

  if (!taskInteraction) return currentTask.value.subtasks[0];

  // Find first uncompleted subtask
  const uncompletedSubtaskIndex = taskInteraction.subtask_interactions?.findIndex((subtask) => !subtask.completed_at);

  return uncompletedSubtaskIndex >= 0
    ? currentTask.value.subtasks[uncompletedSubtaskIndex]
    : currentTask.value.subtasks[currentTask.value.subtasks.length - 1];
});

async function resumeCaseInteraction() {
  await router.push({
    name: 'case-interaction-main',
    params: {
      caseInteractionId: props.caseInteraction.id,
    },
  });
}

const currentSubtaskInteraction = computed(() => {
  let currentTaskInteraction =
    props.caseInteraction.task_interactions[props.caseInteraction.current_task_interaction_index];
  if (!currentTaskInteraction) return null;
  const uncompletedSubtaskInteraction = currentTaskInteraction.subtask_interactions.find(
    (interaction) => interaction.completed_at === null,
  );
  if (!uncompletedSubtaskInteraction) return 'Alles erledigt! 🎉';
  let index = uncompletedSubtaskInteraction.index || 0;
  return truncate(currentTask.value.subtasks[index]?.details?.target, 60);
});
</script>

<template>
  <div
    @click="resumeCaseInteraction"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col h-[198px]"
  >
    <!-- Header -->
    <div class="flex-1">
      <div class="flex items-start justify-between">
        <div class="space-y-1">
          <h3 class="text-xl font-medium text-gray-900 dark:text-white">
            {{ currentTask?.title || props.caseInteraction.case.details.heading }}
          </h3>
          <p v-if="currentSubtaskInteraction" class="text-sm text-gray-600 dark:text-gray-400 flex items-center gap-1">
            <span class="material-symbols-outlined text-sm no-translate" translate="no">arrow_forward</span>
            {{ currentSubtaskInteraction }}
          </p>
        </div>
        <img
          v-if="titlePerson?.profile_image"
          :src="titlePerson.profile_image.image_urls.small"
          class="w-12 h-12 rounded-full object-cover"
          alt="Patient"
        />
      </div>
    </div>

    <!-- Resume Button -->
    <div class="flex items-center justify-between mt-auto">
      <button
        class="inline-flex items-center gap-2 px-4 py-2 bg-white text-gray-800 rounded-full hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
      >
        <span class="material-symbols-outlined">play_arrow</span>
        {{ $t('message.resume') }}
      </button>
      <div class="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
        <span>+{{ xpReward }} XP</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
