<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { nextTick } from 'vue';
import { debounce } from 'lodash';
import { updateMinTextareaHeight } from '@/helper';
import { v4 as uuidv4 } from 'uuid';
import VocabTestButton from '@/components/didactics/vocab/VocabTestButton.vue';
import { getApiClient } from '@/apiclient/client';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import VocabTestModalNew from '@/components/didactics/vocab/VocabTestModalNew.vue';
import VocabListNew from '@/components/didactics/vocab/VocabListNew.vue';
import { VocabItem, VocabItemUpdate } from '@/apiclient';
const { t } = useI18n();

const authStore = useAuthStore();
const alertStore = useAlertStore();
const courseStore = useCourseStore();
const courseInteractionStore = useCourseInteractionStore();

const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'generateAndAddMoreVocab', 'scrollBy']);
const container = ref(null);
const vocabListRefs = ref([] as Ref<HTMLElement | null>[]); // user's personal lists
const taskInstructionsTextarea = ref(null);

const isSubmitting = ref(false);
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const itemInteractionState = ref({});
const testFinished = ref(false);
const defineSpecificTask = ref(false);
const specificTaskText = ref('Bilde einen Beispielsatz im Perfekt mit dem Verb');
const title = ref('');
const taskInstructions = ref('');
const unsavedChanges = ref(false);
const isSavingChanges = ref(false);
const vocabTestModalIsOpen = ref(false);
const showVocabTestButton = ref(false);
const animationsFinished = ref(false);

const exerciseCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  showNative: {
    type: Boolean,
    default: false,
  },
  allowEditing: {
    type: Boolean,
    default: false,
  },
  vocabListLoading: {
    type: Boolean,
    default: false,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isDeleted: {
    type: Boolean,
    required: false,
    default: false,
  },
  isTransitioning: {
    type: Boolean,
    required: true,
  },
});
const vocabWithoutAudio = computed(() => {
  return props.contentItem.meaning_and_making_exercise.vocab_list.vocab_items.some(
    (vocabItem: VocabItem) => !vocabItem.audio_url,
  );
});

const specificTaskToUse = computed(() => {
  return defineSpecificTask.value
    ? { MAKE_SPECIFIC_SENTENCE: specificTaskText.value }
    : {
        EXPLAIN: '',
        FIND_TERM: '',
        MAKE_SENTENCE: '',
      };
});

watch(
  () => specificTaskToUse.value,
  (value) => {
    unsavedChanges.value = true;
    if (value) {
      updateMinTextareaHeight(taskInstructionsTextarea.value);
    }
  },
);

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  authStore.user.vocab_lists.forEach(() => {
    vocabListRefs.value.push(ref(null));
  });

  console.log(
    'props.contentItem.meaning_and_making_exercise.tasks=',
    props.contentItem.meaning_and_making_exercise.tasks,
  );
  if (!!props.contentItem.meaning_and_making_exercise.tasks.MAKE_SPECIFIC_SENTENCE) {
    defineSpecificTask.value = true;
    specificTaskText.value = props.contentItem.meaning_and_making_exercise.tasks.MAKE_SPECIFIC_SENTENCE;
  }

  title.value = props.contentItem.meaning_and_making_exercise.title;
  taskInstructions.value = props.contentItem.meaning_and_making_exercise.description;

  await nextTick();
  console.log('VocabExercise mounted');
  await fetchInteractionState();
});

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    await nextTick();
    await fetchInteractionState();
  },
  { immediate: true },
);

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveVocabExercise();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

onBeforeUnmount(async () => {
  if (!props.fixedMaximization) {
    document.removeEventListener('click', handleClickOutside);
  }

  if (props.isDeleted) return; // dont do anything if content item is deleted

  if (unsavedChanges.value) {
    await saveVocabExercise();
  }

  if (props.allowEditing && vocabWithoutAudio.value) {
    console.log(
      'Teacher finished editing list ',
      props.contentItem.meaning_and_making_exercise.vocab_list.id,
      ' => sending for audio generation ' +
        '(and extraction of new vocab for generated-from-context list, if any) or fetching audio (if already known)',
    );
    (await getApiClient()).vocabs
      .completeAndSyncVocabListAndGeneratedFromContextVocabList(
        props.contentItem.meaning_and_making_exercise.vocab_list.id,
      )
      .then(() => {})
      .catch((error) => {
        console.error('Error completing vocab items and adding to generated-from-context list', error);
        throw Error('Error completing vocab items and adding to generated-from-context list');
      });
  }
});

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      setTimeout(() => {
        saveVocabExercise();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

const saveVocabExercise = async () => {
  console.log('saveVocabExercise');
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  if (!props.allowEditing) return;

  await courseStore
    .updateMeaningAndMakingExercise(props.sectionId, props.contentItem.id, {
      title: title.value,
      description: taskInstructions.value,
      tasks: specificTaskToUse.value,
    })
    .then(() => {
      unsavedChanges.value = false;
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave vocab exercise', 'Error', error);
      throw new Error('Failed to autosave vocab exercise');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

const addVocabItem = async () => {
  isSubmitting.value = true;
  await courseStore
    .addVocabItemToContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, {
      term: 'Begriff',
      explanation: 'Gib hier eine Erläuterung ein',
      usage_examples: ['Dies ist ein Beispielsatz mit dem Begriff'],
      grammatical_category: 'NOUN',
      specific_article: 'der',
      plural: 'die Begriffe',
      uncountable_noun: false,
      plural_noun: false,
    })
    .then(() => {
      alertStore.success('Vokabel erfolgreich hinzugefügt');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht hinzugefügt werden', 'Fehler', error);
      console.error(error);
      isSubmitting.value = false;
      throw error;
    });
  isSubmitting.value = false;
};

const deleteVocabItem = async (vocabItemId: string) => {
  isSubmitting.value = true;
  await courseStore
    .deleteVocabItemFromContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, vocabItemId)
    .then(() => {
      alertStore.success('Vokabel erfolgreich gelöscht');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht gelöscht werden', 'Fehler', error);
      console.error(error);
      isSubmitting.value = false;
      throw error;
    });
  isSubmitting.value = false;
};

const updateVocabItem = async (vocabItemId: string, vocabItemUpdate: VocabItemUpdate) => {
  await courseStore
    .updateVocabItemInContentItemWithVocabList(props.sectionId, props.contentItem.id, vocabItemId, vocabItemUpdate)
    .then(() => {
      console.log('Vokabel erfolgreich aktualisiert');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht aktualisiert werden', 'Fehler', error);
      console.error(error);
      throw error;
    });
};

const handleCloseModal = async (finished: boolean) => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (finished) {
    testFinished.value = true;
    // if user doesnt do, set to completed after some secs
    setTimeout(() => {
      markComplete();
    }, 8000);
  }
  vocabTestModalIsOpen.value = false; // reenables minimize by click outside shortly after, not directly
};

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(props.contentItem.id);
  console.log('interactionState', interactionState, '.');
  // create local copy - we want to defer updates to wait for animations etc
  if (!!interactionState && interactionState !== {}) {
    itemInteractionState.value = { ...interactionState };
  }
  showVocabTestButton.value = exerciseCompleted.value;
};

const markComplete = async () => {
  let examinedVocabItems = props.contentItem.meaning_and_making_exercise.vocab_list.vocab_items;
  let usersActiveListId = authStore.user.vocab_lists[1].id;

  let errorWhenAdding = false;

  for (const vocab of examinedVocabItems) {
    await (await getApiClient()).vocabLists.copyVocabToPersonalVocabList(usersActiveListId, vocab.id).catch((error) => {
      alertStore.error('Failed to add vocab item to Wortschatzkiste', 'Error', error);
      errorWhenAdding = true;
    });
  }

  if (!errorWhenAdding) {
    alertStore.success(
      'Toll, ' + examinedVocabItems.length + ' neue Begriffe wurden in deine Wortschatzkiste übernommen!',
    );
  }

  courseInteractionStore
    .setSectionContentItemCompletedIfNotAlready(props.contentItem.id, props.sectionIndex, props.pageIndex)
    .then(async (response) => {
      if (!response) return;
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      itemInteractionState.value = response.section_content_item_interaction;
      await authStore.fetchUserXp();
    });
};

const takeTest = async () => {
  vocabTestModalIsOpen.value = true;
};
</script>

<template>
  <div
    ref="container"
    class="w-full bg-transparent h-fit px-2 border-none overflow-auto transition-1000 transition-all ease-in-out relative flex-col flex"
  >
    <!-- Original h2 heading moved after the new fields -->
    <h2 class="text font-semibold text-center pt-8 md:pt-2" v-if="props.allowEditing">
      {{ defineSpecificTask ? 'Bestimmte Satzstrukturen bilden' : 'Neue Begriffe' }}
    </h2>

    <!-- Add title -->
    <h3 v-if="props.allowEditing || title !== ''" class="text text-center px-6">
      <textarea
        v-if="props.allowEditing"
        v-model="title"
        class="w-full px-2 py-0 mb-2 bg-transparent resize-none border-gray-400 rounded-lg text-center"
        placeholder="Titel (optional)"
        rows="1"
        @change="unsavedChanges = true"
      />
      <span v-else class="text-gray-800 dark:text-neutral-200 font-semibold">
        {{ title }}
      </span>
    </h3>

    <!-- Add task instructions -->
    <h3 v-if="props.allowEditing || taskInstructions !== ''">
      <span v-if="!props.allowEditing" class="text-xs md:text-sm font-semibold">{{ taskInstructions }}</span>
      <textarea
        v-if="props.allowEditing"
        ref="taskInstructionsTextarea"
        v-model="taskInstructions"
        class="w-full text-xs bg-transparent md:text-sm px-2 py-0 mb-2 resize-none border-gray-400 rounded-lg text-start"
        :placeholder="
          defineSpecificTask
            ? 'Erläuterungen zur Aufgabenstellung (optional), z.B. kurze Wiederholung zu Regeln der Perfektbildung'
            : 'Erläuterungen zur Aufgabenstellung (optional)'
        "
        :rows="defineSpecificTask ? 2 : 1"
        @change="unsavedChanges = true"
        @input="
          (event) => {
            updateMinTextareaHeight(event.target);
            taskInstructions = event.target.value;
          }
        "
      />
    </h3>

    <div class="w-full h-full flex-col flex">
      <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4" v-show="props.allowEditing">
        <span translate="no" class="material-symbols-outlined pb-32 notranslate text-2xl select-none">info</span>
        <div class="w-full items-start flex-col">
          <h3 class="text-xs md:text-sh text-justify">
            Ergänze und überarbeite die Liste von Begriffen.
            <span>
              Standardmäßig wird die Liste als Vokabelliste angezeigt und mit einer automatisch erzeugten Übung zum
              Lernen der Begriffe versehen.
            </span>
          </h3>
          <span class="text-xs md:text-sh text-start flex py-2">
            Mit "Spezifische Satzbildungs- statt Vokabelübung" kannst du die Liste verwenden, um z.B. eine bestimmte
            grammatikatlische Konstruktion zu üben. Formuliere hierzu eine entsprechen Aufgabenstellung, z.B. "Bilde
            einen Beispielsatz *im Perfekt* mit dem Begriff..."
          </span>
          <span class="text-xs md:text-sh text-start flex pb-8">
            Aus allen Begriffen werden Audio-Dateien zum Anhören für deine Studierenden generiert.
          </span>
        </div>
      </div>
      <div class="text-justify text-xs md:text-sm">
        <!-- Vocab vs grammar exercise -->
        <div class="flex items-center w-full justify-center pb-4" v-if="props.allowEditing">
          <label class="text-sm me-3 dark:text-neutral-400 inline-flex items-center gap-x-2">
            <span class="flex flex-col">
              <span class="text-center">Vokabelübung</span>
            </span>
            <span translate="no" class="material-symbols-outlined no-translate"> dictionary </span>
          </label>
          <input
            type="checkbox"
            id="test-test-test"
            class="relative w-[3.25rem] h-7 p-px bg-gray border border-gray-400 checked:border-blue-400 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-gray-600 focus:checked:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-whitebe before:bg-gray-400 checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
            :checked="defineSpecificTask"
            @click="defineSpecificTask = !defineSpecificTask"
          />
          <label class="text-sm ms-3 dark:text-neutral-400 inline-flex items-center gap-x-2">
            <span translate="no" class="material-symbols-outlined no-translate"> engineering </span>
            <span class="flex flex-col">
              <span class="text-center">Spezifische Satzbildung</span>
              <span class="text-center">z.B. Grammatik</span>
            </span>
          </label>
        </div>

        <!-- Define the task -->
        <div v-if="defineSpecificTask && props.allowEditing" class="flex items-center w-full justify-center pb-4">
          <input
            v-model="specificTaskText"
            type="text"
            class="w-full max-w-xl px-4 py-2 text-sm border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200"
            placeholder="Aufgabenstellung bezogen auf einen einzelnen Begriff"
            @change="unsavedChanges = true"
          />
        </div>
      </div>

      <div>
        <VocabListNew
          :vocab-list="props.contentItem.meaning_and_making_exercise.vocab_list"
          :enable-seen-check="!props.allowEditing"
          :all-seen-before="exerciseCompleted"
          :show-searchbar="false"
          :reserve-space-for-button="true"
          :allow-remove-vocab="props.allowEditing"
          :show-trash-as-remove-icon="true"
          :is-editing-full="props.allowEditing"
          :enable-scroll="false"
          @allVocabsSeen="showVocabTestButton = true"
          @deleteItem="deleteVocabItem"
          @updateItem="updateVocabItem"
        />

        <!-- Vocab test button -->
        <div v-if="showVocabTestButton && !props.allowEditing && !props.isTransitioning">
          <VocabTestButton
            :testIsFinished="testFinished"
            :exerciseIsCompleted="exerciseCompleted"
            @startVocabTest="takeTest"
            @finishExercise="markComplete"
            @repeatVocabTest="takeTest"
          />
        </div>
        <!-- End of vocab test button -->
      </div>
      <div class="p-4 pt-8 gap-x-6 w-full flex items-center justify-center" v-show="allowEditing">
        <ProgressButton
          @click="addVocabItem"
          text="Vokabel hinzufügen"
          :show-progress="isSubmitting"
          :disabled="isSubmitting || vocabListLoading"
          icon="add"
          iconSize="text-2xl"
        />
        <ProgressButton
          @click="emit('generateAndAddMoreVocab')"
          text="Fünf weitere generieren"
          :show-progress="vocabListLoading"
          :disabled="isSubmitting || vocabListLoading"
          icon="ai_sparkle"
          iconSize="text-2xl"
        />
      </div>
    </div>
  </div>

  <VocabTestModalNew
    v-if="vocabTestModalIsOpen"
    :overlayId="`vocab-test-modal-${uuidv4()}`"
    :vocabItems="props.contentItem.meaning_and_making_exercise.vocab_list.vocab_items || []"
    :nItems="5"
    :mode="specificTaskToUse"
    @closed="handleCloseModal"
  />
</template>

<style scoped></style>
