<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';
import { Check, Search, Trash, X } from 'lucide-vue-next';
import { UserVocabListDetails, VocabItem } from '@/apiclient';
import AudioPlayerIcon from '@/components/various/AudioPlayerIcon.vue';
import { useAlertStore } from '@/stores';
import { updateMinTextareaHeight } from '@/helper';
import EditGrammar from './EditGrammar.vue';
import EditUsageExamples from './EditUsageExamples.vue';
import EditVocabIllustrationImage from './EditVocabIllustrationImage.vue';
import { displayGrammaticalCategory } from './helper';
import { useI18n } from 'vue-i18n';
import { getApiClient } from '@/apiclient/client';
import { cloneDeep, debounce } from 'lodash';
import { useAutosave } from '@/composables/useAutosave';
const { t } = useI18n();

const props = defineProps({
  vocabList: { type: Object as PropType<UserVocabListDetails>, required: true },
  enableSeenCheck: { type: Boolean, default: false },
  allSeenBefore: { type: Boolean, default: false },
  showSearchbar: { type: Boolean, default: false },
  reserveSpaceForButton: { type: Boolean, default: false },
  withBottomNav: { type: Boolean, default: false },
  allowRemoveVocab: { type: Boolean, default: true },
  showTrashAsRemoveIcon: { type: Boolean, default: false },
  isEditingFull: { type: Boolean, default: false },
  isEditingRemarks: { type: Boolean, default: false },
  enableScroll: { type: Boolean, default: true },
});

const emit = defineEmits(['allVocabsSeen', 'deleteItem', 'updateItem']);

const openVocabId = ref(!props.isEditingFull ? props.vocabList.vocab_items[0]?.id || '' : '');
const seenVocabIds = ref(new Set([props.vocabList.vocab_items[0]?.id || '']));
const searchTerm = ref('');
const alertStore = useAlertStore();
const isGeneratingUsageExampleAudio = ref(new Map<string, boolean>());
const showRemarksInput = ref(false);
// Add new refs for editing
const editingItem = ref<VocabItem | null>(null);
const isDraggingId = ref('');
const imageUploadInProgress = ref(false);

const explanationTextareas = ref<Map<string, HTMLTextAreaElement>>(new Map());
const remarksTextareas = ref<Map<string, HTMLTextAreaElement>>(new Map());

const filteredVocabItems = computed(() => {
  if (!searchTerm.value) {
    return props.vocabList.vocab_items;
  }

  return props.vocabList.vocab_items.filter((item) => item.term.toLowerCase().includes(searchTerm.value.toLowerCase()));
});

const allVocabsSeen = computed(() => {
  return seenVocabIds.value.size === props.vocabList.vocab_items.length;
});

const toggleVocab = (item: VocabItem) => {
  if (openVocabId.value === item.id) {
    closeVocab();
  } else {
    openVocab(item);
  }
};

const closeVocab = () => {
  openVocabId.value = '';
};

const openVocab = async (item: VocabItem) => {
  if (openVocabId.value === item.id) {
    return;
  }

  openVocabId.value = item.id;
  showRemarksInput.value = false;

  if (props.enableSeenCheck) {
    seenVocabIds.value.add(item.id);

    if (allVocabsSeen.value) {
      emit('allVocabsSeen');
    }
  }

  if (props.isEditingFull || props.isEditingRemarks) {
    await saveChanges();
    const fullItem = props.vocabList.vocab_items.find((i) => i.id === item.id);
    if (fullItem) {
      startEditing(fullItem);
    }
  }
};

const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    emit('updateItem', editingItem.value?.id, editingItem.value);
  },
  onError: (error) => {
    alertStore.error('Failed to autosave vocab item ' + editingItem.value?.term, 'Error', error);
  },
});

const generateExampleAudio = async (item: VocabItem, index: number) => {
  console.log('generateExampleAudio', item, index);
  alertStore.info(t('status.info.generatingAudio'));
  let key = `${item.id}-${index}`;
  isGeneratingUsageExampleAudio.value.set(key, true);

  await (
    await getApiClient()
  ).vocabs
    .generateUsageExampleAudio(item.id, index)
    .then((url) => {
      console.log('generated usage example audio under url: ', url);
    })
    .finally(() => {
      isGeneratingUsageExampleAudio.value.set(key, false);
    });
};

const isGeneratingAudioForExample = (item: VocabItem, index: number) => {
  let key = `${item.id}-${index}`;
  return isGeneratingUsageExampleAudio.value.get(key) || false;
};

const generateItemAudio = (item: VocabItem) => {
  console.log('generateItemAudio', item);
  alertStore.error('Missing audio - please contact us at hello@casuu.care', 'Error');
  // TODO generate audio for item
  // TODO add loading animation to audio player icon
};

const displayGenusAndArticle = (item: VocabItem) => {
  if (item.plural_noun) {
    return `, [${item.plural}, nur Plural]`;
  }
  let info = '';
  if (item.specific_article === 'der') {
    info += `, m [${item.specific_article} ${item.term}`;
  }
  if (item.specific_article === 'die') {
    info += `, f [${item.specific_article} ${item.term}`;
  }
  if (item.specific_article === 'das') {
    info += `, n [${item.specific_article} ${item.term}`;
  }
  if (item.uncountable_noun) {
    info += `, nur Singular]`;
  } else {
    info += `, ${item.plural}]`;
  }
  return info;
};

const displayGrammarInfo = (item: VocabItem) => {
  let info = '';
  if (item.grammatical_category) {
    info += displayGrammaticalCategory(item.grammatical_category);
  }
  if (item.grammatical_category === 'NOUN' && item.specific_article) {
    info += displayGenusAndArticle(item);
  }
  if (item.grammatical_category === 'VERB' && (item.simple_past_tense || item.present_perfect_tense)) {
    info += ` [${item.simple_past_tense || ''} | ${item.present_perfect_tense || ''}]`;
  }
  return info;
};

const startEditing = (item: VocabItem) => {
  editingItem.value = cloneDeep(item);

  setTimeout(() => {
    const explanationTextarea = explanationTextareas.value.get(item.id);
    if (explanationTextarea) {
      updateMinTextareaHeight(explanationTextarea);
    }
  }, 0);
};

const uploadImage = async (item: VocabItem, event: Event) => {
  console.log('editingItem', editingItem.value);
  if (!editingItem.value) return;
  imageUploadInProgress.value = true;
  console.log('uploadImage', item);

  const image = event.target.files || event.dataTransfer.files;

  if (!image || image.length === 0) {
    imageUploadInProgress.value = false;
    return;
  }
  if (image.length > 1) {
    alertStore.error('Please select only one image');
    imageUploadInProgress.value = false;
    return;
  }

  let returnUrl = await (await getApiClient()).vocabs.uploadVocabIllustration(item.id, { image: image[0] });
  editingItem.value.image_url = returnUrl;
  unsavedChanges.value = true;
  imageUploadInProgress.value = false;
};

const handleDrop = (item: VocabItem, event: DragEvent) => {
  event.preventDefault();
  isDraggingId.value = '';
  uploadImage(item, event);
};

const handleDragOver = (event: DragEvent, itemId: string) => {
  if (!props.isEditingFull || editingItem.value?.id !== itemId) return;
  console.log('handleDragOver', itemId);
  event.preventDefault();
  isDraggingId.value = itemId;
};

const handleDragLeave = (event: DragEvent) => {
  isDraggingId.value = '';
};

const debounceHandleDragOver = debounce(handleDragOver, 1);
const debounceHandleDragLeave = debounce(handleDragLeave, 1);

const isLargeScreen = computed(() => {
  return window.innerWidth > 768;
});
</script>

<template>
  <div class="flex flex-col min-h-0">
    <div
      class="flex overflow-hidden items-center gap-x-2 border-none bg-white rounded-full px-4 transition-height duration-300"
      :class="{
        'min-h-[48px] h-[48px] mt-0 mb-4 mx-0': showSearchbar,
        'min-h-0 h-0 mb-0 my-0 mx-12': !showSearchbar,
      }"
    >
      <Search :size="20" />
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Search"
        class="w-full h-12 focus:outline-none focus:ring-0 border-none"
      />
    </div>

    <div
      class="flex flex-col overflow-y-auto gap-y-2"
      :class="{
        'pb-40': reserveSpaceForButton && withBottomNav,
        'pb-24': reserveSpaceForButton && !withBottomNav,
        'pb-20': !reserveSpaceForButton && withBottomNav,
        'pb-4': !reserveSpaceForButton && !withBottomNav,
        'overflow-y-auto': enableScroll,
        'overflow-y-visible': !enableScroll,
      }"
    >
      <div v-for="(item, index) in filteredVocabItems" :key="item.id">
        <div
          @click="openVocab(item)"
          @drop.prevent="(event) => handleDrop(item, event)"
          @dragover.prevent="(event) => debounceHandleDragOver(event, item.id)"
          @dragleave.prevent="debounceHandleDragLeave"
          class="flex flex-col rounded-2xl gap-y-3 py-3 px-4 transition-colors duration-300"
          :class="{
            'bg-gray-bg':
              allSeenBefore ||
              isDraggingId === item.id ||
              (enableSeenCheck && seenVocabIds.has(item.id) && openVocabId !== item.id),
            'bg-white':
              !allSeenBefore &&
              isDraggingId !== item.id &&
              !(enableSeenCheck && seenVocabIds.has(item.id) && openVocabId !== item.id),
            'cursor-pointer': openVocabId !== item.id,
            'cursor-default': openVocabId === item.id,
            'border border-gray-200 rounded-xl':
              (!seenVocabIds.has(item.id) || openVocabId === item.id) && isLargeScreen,
          }"
        >
          <!-- card header -->
          <div
            class="flex items-center gap-x-3"
            @click="
              (event) => {
                event.stopPropagation();
                toggleVocab(item);
              }
            "
          >
            <AudioPlayerIcon :audio-url="item.audio_url ?? null" @missingAudio="generateItemAudio(item)" />

            <div
              v-if="isEditingFull && editingItem && editingItem.id === item.id && openVocabId === item.id"
              class="flex-1 mr-20"
              @click="
                (event) => {
                  if (isEditingFull) {
                    event.stopPropagation();
                  }
                }
              "
            >
              <input
                v-model="editingItem.term"
                class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors text-lg font-semibold"
                :placeholder="t('placeholder.term')"
                @change="unsavedChanges = true"
              />
            </div>
            <div v-else class="text-lg font-semibold select-none">
              {{ item.term }}
            </div>

            <div v-if="(enableSeenCheck && seenVocabIds.has(item.id)) || allSeenBefore" class="ml-auto">
              <Check :size="20" />
            </div>
            <div
              v-if="allowRemoveVocab && openVocabId === item.id"
              class="ml-auto opacity-50 hover:opacity-100 cursor-pointer"
              @click="emit('deleteItem', item.id)"
            >
              <Trash :size="20" v-if="props.showTrashAsRemoveIcon" />
              <X :size="20" v-else />
            </div>
          </div>
          <!-- end of card header -->

          <!-- TODO: opening and closing of other card does not run simultaneously -->
          <transition name="slide" class="overflow-hidden">
            <div v-if="openVocabId === item.id" class="flex flex-col gap-y-3 text-sm">
              <hr class="h-[1px] bg-gray-medium opacity-20 border-0" />

              <!-- Card body -->
              <div class="flex flex-col gap-y-3">
                <!-- Image -->
                <div v-if="item.image_url && !isEditingFull">
                  <img :src="item.image_url" alt="Vocab image" class="w-full h-auto rounded-xl" />
                  <!-- <img src="https://vocabimage-prd.data.casuu.health/161-Verleihung-IDEA-2024-260.jpg" :alt="`Image illustration of ${item.term}`" class="w-full h-auto rounded-xl" /> -->
                </div>
                <div v-else-if="isEditingFull" class="flex flex-col gap-y-2">
                  <img
                    :src="editingItem?.image_url"
                    v-if="editingItem?.image_url"
                    alt="Vocab image"
                    class="w-full h-auto rounded-xl"
                  />
                  <EditVocabIllustrationImage :upload-image="uploadImage" :item="editingItem" />
                </div>
                <!-- End image -->

                <!-- Grammar section -->
                <div v-if="isEditingFull && editingItem && editingItem.id === item.id">
                  <EditGrammar :editingItem="editingItem" @unsavedChanges="unsavedChanges = true" />
                </div>
                <div v-else class="font-medium">
                  {{ displayGrammarInfo(item) }}
                </div>
                <!-- end grammar section -->

                <!-- Explanation -->
                <div v-if="isEditingFull && editingItem && editingItem.id === item.id">
                  <textarea
                    :ref="
                      (el) => {
                        if (el) explanationTextareas.set(item.id, el as HTMLTextAreaElement);
                        else explanationTextareas.delete(item.id);
                      }
                    "
                    v-model="editingItem.explanation"
                    class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
                    :placeholder="t('placeholder.explanation')"
                    rows="2"
                    @input="
                      (e: Event) => {
                        const target = e.target as HTMLInputElement;
                        updateMinTextareaHeight(target);
                      }
                    "
                    @change="unsavedChanges = true"
                  ></textarea>
                </div>
                <div v-else>
                  {{ item.explanation }}
                </div>
                <!-- End explanation -->

                <!-- Usage examples -->
                <div class="flex flex-col gap-y-1">
                  <div
                    class="font-medium ml-12"
                    v-if="
                      item.usage_examples?.filter((example) => example !== '').length > 0 ||
                      (isEditingFull && editingItem?.usage_examples?.length > 0)
                    "
                  >
                    {{
                      $t('message.exampleSentences', {
                        count: item.usage_examples?.filter((example) => example !== '').length,
                      })
                    }}
                  </div>

                  <div
                    v-if="isEditingFull && editingItem && editingItem.id === item.id"
                    class="flex flex-col gap-y-2 ml-9"
                  >
                    <EditUsageExamples :editingItem="editingItem" @unsavedChanges="unsavedChanges = true" />
                  </div>
                  <div
                    v-else
                    class="inline-flex items-center gap-x-3 gap-y-1 relative"
                    v-for="(example, index) in item.usage_examples"
                    :key="example"
                  >
                    <div class="absolute left-1.5" v-if="example">
                      <AudioPlayerIcon
                        in-circle
                        size="small"
                        :audio-url="item.usage_example_audio_urls?.[index] ?? null"
                        :is-generating="isGeneratingAudioForExample(item, index)"
                        @missingAudio="generateExampleAudio(item, index)"
                      />
                    </div>
                    <div class="ml-12">
                      {{ example }}
                    </div>
                  </div>
                </div>
                <!-- End of usage examples -->

                <!-- remarks -->
                <div class="flex flex-col gap-y-1">
                  <div v-if="item.remarks || showRemarksInput" class="ml-12 font-medium">
                    {{ $t('message.columnRemarks') }}
                  </div>
                  <div
                    v-if="
                      (isEditingFull || isEditingRemarks) &&
                      showRemarksInput &&
                      editingItem &&
                      editingItem.id === item.id
                    "
                    class="flex gap-x-2 items-center"
                  >
                    <textarea
                      :ref="
                        (el) => {
                          if (el) remarksTextareas.set(item.id, el as HTMLTextAreaElement);
                          else remarksTextareas.delete(item.id);
                        }
                      "
                      v-model="editingItem.remarks"
                      class="w-full ml-9 px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
                      :placeholder="t('placeholder.remarks')"
                      rows="1"
                      @input="
                        (e: Event) => {
                          const target = e.target as HTMLInputElement;
                          updateMinTextareaHeight(target);
                        }
                      "
                      @change="unsavedChanges = true"
                    />
                    <button @click="showRemarksInput = false" class="text-red-500">
                      <X :size="16" />
                    </button>
                  </div>
                  <div v-else class="ml-12">
                    {{ item.remarks }}
                  </div>
                  <button
                    v-if="!showRemarksInput && (isEditingFull || isEditingRemarks)"
                    @click="showRemarksInput = true"
                    class="text-blue-500 self-start mt-2 ml-9"
                  >
                    + {{ t('button.addRemark') }}
                  </button>
                </div>
                <!-- End of remarks -->
              </div>
              <!-- End of card body -->
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease-out;
  max-height: 500px;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}
</style>
