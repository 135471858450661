<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import CaseHighlightCard from '@/components/cases/CaseHighlightCard.vue';
import { formatDate, mapJobStatus, mapTitle, updateMinTextareaHeight, VocabMode } from '@/helper';
import ProfileImage from '@/components/ProfileImage.vue';
import DOMPurify from 'dompurify';
import TextEditor from '@/views/courses/TextEditor.vue';
import { useAlertStore, useCourseInteractionStore, useAuthStore, useLanguageStore, useCourseStore } from '@/stores';
import SectionMediaUpload from '@/views/courses/PageMediaUpload.vue';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import CaseVocabModal from '@/components/case_interaction/CaseVocabModal.vue';
import SectionVocabModal from '@/components/SectionVocabModal.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import SectionContent from '@/views/courses/PageContent.vue';
import { onBeforeMount } from 'vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import Card from '@/components/didactics/pair_of_terms/Card.vue';
import PageContent from '@/views/courses/PageContent.vue';

const { t } = useI18n();

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const languageStore = useLanguageStore();
const { userNativeLanguage, userIngameLanguage } = storeToRefs(authStore);
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const courseStore = useCourseStore();
const { currentChapter } = storeToRefs(courseStore);
const sectionContentComponent = ref<InstanceType<typeof SectionContent> | null>(null);

const props = defineProps([
  'pageContents',
  'sectionIndex',
  'sectionId',
  'pageIndex',
  'chapterId',
  'chapterIndex',
  'outerHeaderHeight',
  'fullWidth',
  'showNative',
  'pageLoaded',
  'fetchingSectionContentsCompleted',
  'pageIsVisible',
  'isTransitioning',
]);
const reactiveShowNative = toRef(props, 'showNative');

const emit = defineEmits(['showNativeToggled', 'scrollBy', 'undoLastScroll', 'pageLoaded']);
const cases = ref([]);
const media = ref([] as any[]);
const outerTextContainer = ref(null);
const vocabModal = ref(null);

const padding = ref(100);
const gap = ref(50);

const section = computed(() => {
  if (!currentChapter.value || !currentChapter.value.sections || props.sectionIndex == null) {
    return null;
  }
  return currentChapter.value.sections[props.sectionIndex];
});

const outerContentContainerWidth = computed(() => {
  return props.fullWidth - 2 * padding.value;
});

const outerTextContentContainerWidth = computed(() => {
  if (padding.value < 10) {
    return outerContentContainerWidth.value - 20;
  }
  return outerContentContainerWidth.value;
});

const allContentItemsCompleted = computed(() => {
  return props.pageContents.every((item) => item.completed_at !== null);
});

// function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
//   if (!sectionLearningObjectives) {
//     return;
//   }
//   if (!sectionContentComponent.value?.learningObjectives) {
//     console.warn('null ref in learningObjectives');
//     return;
//   }
//   if (!section.value) {
//     return;
//   }
//   // note: slice to avoid reactivity issues or in-place changes when field deleted
//   sectionContentComponent.value.learningObjectives.setLearningObjectives(sectionLearningObjectives.slice(), false);
// }

const adjustPadding = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 640) {
    padding.value = 0;
    return;
  } else if (screenWidth < 1024) {
    padding.value = 10;
    return;
  }
  padding.value = Math.min(100, Math.round(screenWidth * 0.05));
};

const debounceAdjustPadding = debounce(adjustPadding, 100);

onBeforeMount(async () => {
  emit('pageLoaded', props.pageIndex, false, false);
  await courseStore.settingCoursePromise;
  await courseStore.settingChapterPromise; // only mount after chapter is loaded to store
});

onMounted(async () => {
  await languageStore.loadLanguages(false);

  if (!section.value) {
    throw new Error('Section not found');
  }

  adjustPadding();
  window.addEventListener('resize', debounceAdjustPadding);

  if (props.pageContents.length === 0) {
    // we are done, no content to load
    emit('pageLoaded', props.pageIndex, true, false);
    return;
  }
});

const setEditorRefs = async () => {
  if (!section.value) {
    return;
  }
  if (!section.value.section_content_items) {
    return;
  }
  if (!sectionContentComponent.value) {
    return;
  }
  await sectionContentComponent.value.setEditorRefs();
  await nextTick();
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceAdjustPadding);
});

const pageCompleted = computed(() => {
  if (
    !courseInteractionStore.pageCompletionStatus?.[props.sectionId] ||
    !courseInteractionStore.pageCompletionStatus[props.sectionId]?.[props.pageIndex]
  ) {
    return false;
  }
  return courseInteractionStore.pageCompletionStatus[props.sectionId][props.pageIndex];
});

const pageStarted = computed(() => {
  if (
    !courseInteractionStore.pageStartedStatus?.[props.sectionId] ||
    !courseInteractionStore.pageStartedStatus[props.sectionId]?.[props.pageIndex]
  ) {
    return false;
  }
  return courseInteractionStore.pageStartedStatus[props.sectionId][props.pageIndex];
});

const firstCaseStarted = computed(() => {
  if (!section.value) {
    return false;
  }
  return courseInteractionStore.sectionFirstCaseStarted(section.value.id);
});

const translationAvailable = computed(() => {
  return !!section.value?.content_translations[userNativeLanguage.value];
});

async function onExplainVocab() {
  if (!vocabModal.value) {
    console.error('vocabModal not set');
    return;
  }
  await vocabModal.value.pauseForMetaChat();
}

// TODO language icon => user language flag icon ?

// watch for page visibility and force reflow of all textareas
watch(
  () => props.pageIsVisible,
  async (isVisible) => {
    if (isVisible) {
      // Page became visible, wait for transition
      await nextTick();
      // Force reflow of all textareas
      document.querySelectorAll('textarea').forEach((textarea) => {
        if (textarea.value) {
          updateMinTextareaHeight(textarea, 1);
        }
      });
    }
  },
);

const isLargeScreen = computed(() => {
  return window.innerWidth > 768;
});
</script>

<template>
  <!-- Blog Article -->
  <div class="flex w-full justify-center items-center mx-auto" v-if="!props.fetchingSectionContentsCompleted">
    <div :style="{ width: props.fullWidth + 'px' }">
      <LoadingSpinnerLarge />
    </div>
  </div>
  <div
    v-show="props.fetchingSectionContentsCompleted"
    class="mx-auto transition-all duration-500 ease-in-out flex justify-center"
    :style="{ width: props.fullWidth + 'px' }"
    :class="{
      'bg-white rounded-xl shadow-sm pt-4': isLargeScreen,
    }"
  >
    <div class="grow transition-all duration-500 ease-in-out">
      <!-- Content -->
      <div
        class="mx-auto flex space-y-5 transition-all duration-500 ease-in-out md:space-y-8"
        :style="{ width: outerContentContainerWidth + 'px' }"
        :class="{
          'bg-white rounded-xl shadow-sm': isLargeScreen,
        }"
        ref="outerTextContainer"
      >
        <div
          class="overflow-hidden min-w-full transition-all duration-500 ease-in-out"
          :style="{
            gap: `${gap}px`,
          }"
        >
          <div>
            <div class="grid items-center min-w-full w-full relative">
              <!--              <h2-->
              <!--                class="text-2xl flex-col font-bold md:text-3xl dark:text-white text-center row-start-1 col-start-1 flex justify-center items-center"-->
              <!--              >-->
              <!--                <span class="z-10 flex justify-center items-center md:px-48 text-gray-300">-->
              <!--                  <span class="text-gray-300">{{ chapterIndex + 1 }}</span-->
              <!--                  ><span class="whitespace-pre text-gray-300 px-0.5">.</span>{{ section.index + 1 }}-->
              <!--                </span>-->
              <!--                <span class="z-10 flex justify-center items-center md:px-48">-->
              <!--                  {{ section.title }}-->
              <!--                </span>-->
              <!--              </h2>-->
              <!-- TODO title needs to be re-implemented for course pages. Title of first item?! -->
              <div v-if="false" class="flex row-start-1 -mt-8 md:mt-0 col-start-1 justify-end items-center gap-x-3">
                <div class="z-10 flex justify-center items-center">
                  <input
                    type="checkbox"
                    id="hs-basic-with-description-checked"
                    class="hidden md:block relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                    @change="emit('showNativeToggled', !props.showNative)"
                    :checked="props.showNative"
                  />
                  <input
                    type="checkbox"
                    id="hs-xs-switch"
                    @change="emit('showNativeToggled', !props.showNative)"
                    :checked="props.showNative"
                    class="block md:hidden relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                  />
                  <label
                    for="hs-basic-with-description-checked"
                    class="text-sm text-gray-800 ms-3 dark:text-neutral-400"
                  >
                    <span class="hidden md:block">Show in</span>
                    {{ languageStore.getLanguageNameFromCode(userNativeLanguage) }}
                  </label>
                </div>
              </div>
              <h3
                class="text-lg font-bold md:text-xl dark:text-white text-center text-gray-400"
                v-show="props.showNative"
              >
                {{
                  !!section.title_translations[userNativeLanguage] ? section.title_translations[userNativeLanguage] : ''
                }}
              </h3>
            </div>
          </div>

          <PageContent
            ref="sectionContentComponent"
            :allow-editing="false"
            :content-items="props.pageContents"
            :chapter-id="props.chapterId"
            :section-id="props.sectionId"
            :page-index="props.pageIndex"
            :section-index="props.sectionIndex"
            :external-fetch-completed="props.fetchingSectionContentsCompleted"
            :outer-header-height="props.outerHeaderHeight"
            :outer-width="outerTextContentContainerWidth"
            :show-native="props.showNative"
            :is-transitioning="props.isTransitioning"
            @scrollBy="(distance) => emit('scrollBy', distance)"
            @undoLastScroll="emit('undoLastScroll')"
          />
        </div>
      </div>
      <!-- End content -->
    </div>
  </div>

  <!-- End Blog Article -->

  <div class="inset-x-0 text-center justify-center flex gap-x-2">
    <!-- <button
      v-if="false"
      v-show="sectionStarted && !sectionCompleted"
      type="button"
      class="flex items-center shadow-md rounded-full py-3 pl-4 pr-6 text-xs md:text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      :class="{
        'text-white bg-blue-600 hover:bg-blue-700': !sectionCompleted,
        'text-gray-800 bg-white hover:bg-gray-50': sectionCompleted,
      }"
    >
      <span v-show="!sectionCompleted" translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
      {{ sectionCompleted ? 'Nochmal bearbeiten' : 'Abschließen' }}
      <span v-show="props.showNative" class="text-gray-400 dark:text-gray-200">{{
        sectionCompleted ? $t('message.revokeMarkAsCompleted') : $t('message.markAsCompleted')
      }}</span>
    </button> -->

    <button
      v-show="false"
      type="button"
      @click.capture="alertStore.error('Noch nicht unterstützt')"
      class="flex items-center shadow-md rounded-full text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
    >
      <span translate="no" class="material-symbols-outlined notranslate"> expand_more </span>
      Notizen
      <span v-show="props.showNative" class="text-gray-400 dark:text-gray-200">{{ $t('message.notes') }}</span>
    </button>
  </div>
  <!-- End Bottom Button Group -->
</template>

<style scoped></style>
