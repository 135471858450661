<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { SetPhrase } from '@/apiclient';
import { useCourseStore } from '@/stores/course.store';
import { useAlertStore } from '@/stores/alert.store';
import { updateMinTextareaHeight } from '@/helper';

const props = defineProps<{
  setPhrase: SetPhrase;
  sectionId: string;
  contentItemId: string;
  setPhraseListId: string;
  isEditing: boolean;
}>();

const unsavedChanges = ref(false);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const isSavingChanges = ref(false);
const localSetPhrase = ref(props.setPhrase);
const showExplanation = ref(false);

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) return;
      setTimeout(() => {
        saveSetPhrase();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

onMounted(() => {
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveSetPhrase();
      return undefined;
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveSetPhrase();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

onBeforeUnmount(async () => {
  // onBeforeUnmount is not triggered on page reloads/hard refreshes
  // For that case, we need to use window.onbeforeunload
  console.log('onBeforeUnmount - unsavedChanges: ', unsavedChanges.value);
  await new Promise((resolve) => setTimeout(resolve, 3000));
  if (unsavedChanges.value) {
    await saveSetPhrase();
  }
});

const saveSetPhrase = async () => {
  if (!props.isEditing) return;
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;

  try {
    await courseStore.updateSetPhraseInContentItemWithSetPhraseList(
      props.sectionId,
      props.contentItemId,
      props.setPhraseListId,
      props.setPhrase.id,
      {
        phrase: localSetPhrase.value.phrase,
        explanation: localSetPhrase.value.explanation,
      },
    );
  } catch (error: any) {
    console.error('Failed to save:', error);
    alertStore.error('Fehler beim Speichern des Redemittels', 'Fehler', error);
    throw new Error('Failed to save set phrase');
  } finally {
    isSavingChanges.value = false;
  }
};

const emit = defineEmits(['deleteSetPhrase']);
</script>

<template>
  <div class="flex-col flex relative pl-7">
    <div class="inline-flex items-center">
      <textarea
        v-if="props.isEditing"
        v-model="localSetPhrase.phrase"
        class="px-2 py-0 bg-lime-100 text-sm resize-none border-lime-500 rounded-lg text-gray-800 my-auto w-full"
        placeholder="Redemittel"
        rows="1"
        @change="unsavedChanges = true"
        @input="
          (event) => {
            const target = event.target as HTMLInputElement;
            updateMinTextareaHeight(target);
          }
        "
      />
      <span v-else-if="localSetPhrase.phrase !== ''" class="text-gray-800 text-sm flex items-center">
        {{ localSetPhrase.phrase }}
      </span>

      <div class="inline-flex pl-4 items-center gap-1">
        <button
          v-if="!props.isEditing && localSetPhrase.explanation !== ''"
          @click="showExplanation = !showExplanation"
          class="flex items-center text-gray-500 hover:text-gray-600 text-xs"
        >
          Details
          <span
            translate="no"
            class="material-symbols-outlined no-translate transition-transform duration-300"
            :class="{ 'rotate-180': showExplanation }"
          >
            expand_more
          </span>
        </button>
        <button @click="emit('deleteSetPhrase', props.setPhrase.id)">
          <span
            v-if="props.isEditing"
            translate="no"
            class="material-symbols-outlined no-translate bg-red-500 hover:bg-red-600 rounded-md text-white"
            >delete</span
          >
        </button>
      </div>
    </div>

    <textarea
      v-if="props.isEditing"
      v-model="localSetPhrase.explanation"
      class="px-2 py-0 mt-1 bg-lime-100 resize-none border-lime-500 rounded-lg text-gray-500 my-auto w-full text-xs"
      placeholder="Erklärung (optional)"
      rows="1"
      @change="unsavedChanges = true"
    />
    <span
      v-else-if="localSetPhrase.explanation !== '' && showExplanation"
      class="text-xs pt-1 text-gray-500 flex items-center"
    >
      {{ localSetPhrase.explanation }}
    </span>
  </div>
</template>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}

div::before {
  content: '•';
  position: absolute;
  left: 0.75rem;
  top: 0rem; /* Adjust to align with text */
  font-size: 1.25rem; /* Make bullet point bigger */
  line-height: 1;
  color: #374151; /* text-gray-700 */
}
</style>
